import API from 'utils/api';

export async function getTenantService(page) {
    return await API.get(`list-vendor?page=${page}`).then(res => res.data);
}

export async function activateTenantService(fd) {
    return await API.post(`toogle-vendor`, fd).then(res => res.data);
}

export async function togleFormService(fd) {
    return await API.post(`toogle/form`, fd).then(res => res.data);
}

export async function downloadFormTenantService(id) {
    return await API.get(`form/download/${id}`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    }).then(res => res.data);
}
