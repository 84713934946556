import LeftAngle from "../components/icons/LeftAngle";
import LogoVinco from '../assets/images/vincoo-logo.svg';
import Input from "../components/Input";
import { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import { ModalReducer } from "../reducer/ModalReducer";
import Checklist from "../components/icons/Checklist";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";

function ForgotPassword() {
  const [email, setEmail] = useState(null);
  const [isModalOpen, dispatch] = useReducer(ModalReducer);
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async e => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData();

    formData.append('email', email);

    return API.post(`forgot-password`, formData)
      .then(ress => {
        if (ress.status === 200) {
          setIsLoading(false);
          dispatch({ type: 'delete_event' });
        }
      }).catch(err => {
        handleError(err.response);
      })
  }

  return ( 
    <>
      <header className="p-10">
        <Link
          to={ '/' }
        >
          <LeftAngle />
        </Link>
      </header>
      <main className="flex justify-center">
        <div className="w-1/3 p-10">
          <img src={ LogoVinco } alt="Logo Vinco" className="mx-auto" />
         <div className="text-center">
          <h6 className="text-center">Enter your email account, and we will send you a link to reset your password</h6>
          <form onSubmit={ handleForgotPassword }>
            <div className="mt-5 px-4">
              <label htmlFor="" className="float-left ">Email</label>
              <Input 
                type="email"
                isRequired={ true }
                  action={setEmail}
              />
              </div>
              {
                isLoading ? <button className="btn btn-cancel w-2/3 mt-16" disabled={true}>Loading ...</button> : <button className="btn w-2/3 mt-16">Send Link</button>
              }
          </form>
         </div>
        </div>
      </main>
      <Modal show={isModalOpen === 'delete_event'} size="lg" popup onClose={() => dispatch({ type: 'close_modal' })}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <div className="w-16 h-16 flex justify-center items-center mx-auto rounded-full bg-badge-success">
              <Checklist 
                width={45}
                height={45}
                color="#10B986"
              />
            </div>
            <h3 className="my-5 text-lg font-normal text-black dark:text-gray-400">
              Link has been send to your email
            </h3>
            <div className="flex justify-center gap-4">
              {/* <button className="btn w-20" onClick={() => handleSubmitForgotPassword}>Oke</button> */}
              <Link className="btn w-20" to={ '/' }>Oke</Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPassword;