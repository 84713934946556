import React from 'react'

export default function Arrow(props) {
    return (
        <svg width="8" height="13" viewBox="0 0 8 13" {...props}>
            <path d="M0.206748 0.184239C-0.0438557 0.40756 -0.0666378 0.75702 0.138402 1.00327L0.206748 1.07383L6.29553 6.5L0.206748 11.9262C-0.0438557 12.1495 -0.0666378 12.499 0.138402 12.7452L0.206748 12.8158C0.457352 13.0391 0.849506 13.0594 1.12585 12.8767L1.20502 12.8158L7.79325 6.94479C8.04386 6.72147 8.06664 6.37201 7.8616 6.12576L7.79325 6.05521L1.20502 0.184239C0.929352 -0.0614131 0.482412 -0.0614131 0.206748 0.184239Z" />
        </svg>
    )
}

Arrow.defaultProps = {
    className: 'fill-app-black'
}