import { handleError } from "helpers/HandleRequestApi"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify";
import API from "utils/api"

const PermissionTable = ({ data, setPermissions, userId, hasPermission = true }) => {
    const handleSetPermission = async data => {
        const FD = new FormData();

        FD.append(`user_id`, userId);
        FD.append('permission_id', data.id);

        return API.post(`assign/permission`, FD).then(ress => {
            if (ress.status === 200) {
                const response = ress.data;
                let isAssign = 1;

                if (response.status === 'unassign') {
                    isAssign = 0;
                }

                toast.success(response.success);
                setPermissions(currData => {
                    return currData.map(item => {
                        if (item.id === data.id) {
                            item.is_assigned = isAssign;

                            return item;
                        }

                        return item;
                    })
                })
            }
        }).catch(err => {
            handleError(err.response);
        })
    }

    const groupedData = data.reduce((result, item) => {
        const key = item.group;
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    }, []);

    const handleSelectAllPermission = async (event, group) => {
        let url = `assign/permission/all`;
        let isAssign = 1;

        if (!event.target.checked) {
            isAssign = 0;
            url = `unassign/permission/all`;
        }

        const FD = new FormData();

        FD.append(`user_id`, userId);

        groupedData[group].map((item, index) => {
            FD.append(`permission_id[${index}]`, item.id);
        })

        return API.post(url, FD).then(ress => {
            if (ress.status === 200) {
                toast.success(ress.data.success);
                setPermissions(currData => {
                    return currData.map(item => {
                        if (item.group === group) {
                            item.is_assigned = isAssign;
                        }

                        return item;
                    })
                })
            }
        }).catch(err => {
            handleError(err.response);
        })
    }

    return (
        <>
            <div className=" overflow-x-scroll">
                <table className="responsive-table">
                    <thead>
                        <th>No</th>
                        <th>Name</th>
                        <th>Detail</th>
                    </thead>
                    <tbody>
                        {
                            Object.keys(groupedData).map((item, index) => {
                                return (
                                    <tr>
                                        <td className="td-class cursor-pointer">
                                            {index + 1}
                                        </td>
                                        <td className="td-class cursor-pointer capitalize">
                                            {item.replace('_', ' ')}
                                        </td>
                                        <td className="td-class cursor-pointer">
                                            <ul>
                                                {
                                                    groupedData[item].length > 1 &&
                                                    <li className="mb-3">
                                                        <input
                                                            onChange={(e) => {
                                                                handleSelectAllPermission(e, item)
                                                            }}
                                                            id={item}
                                                            type="checkbox"
                                                            className="form-checkbox h-5 w-5"
                                                            checked={!Object.keys(groupedData[item]).some(itemPer => groupedData[item][itemPer].is_assigned === 0)}
                                                            disabled={!hasPermission}
                                                        />
                                                        <label htmlFor={item} className="cursor-pointer font-semibold capitalize">Check All Permissions on ({item.replace('_', ' ')})</label>
                                                    </li>
                                                }
                                                {
                                                    groupedData[item].map(item => {
                                                        return (
                                                            <li key={item.id}>
                                                                <input
                                                                    onChange={(e) => {
                                                                        handleSetPermission(item)
                                                                    }}
                                                                    id={item.id}
                                                                    type="checkbox"
                                                                    className="form-checkbox h-5 w-5"
                                                                    checked={item.is_assigned}
                                                                    disabled={!hasPermission}
                                                                />
                                                                <label htmlFor={item.id} className="cursor-pointer">{item.name}</label>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table >
            </div>
        </>
    )
}

export default PermissionTable;
