import { useInfiniteQuery } from "@tanstack/react-query";
import { handleError } from "helpers/HandleRequestApi";
import { MONTHS } from "helpers/Month";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Ilustration from "../assets/images/event-ilustration.svg";
import API from "utils/api";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";
import { useInView } from "react-intersection-observer";
import Loading from "components/Loading";

function EventInvoice() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { event_id } = useParams();
    const [invoices, setInvoice] = useState([]);
    const [page, setPage] = useState(1);

    const { ref, inView } = useInView();

    const reqGetInvoices = async () => {
        return API.get(`invoice/${event_id}?page=${page}`);
    }

    const {
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        isLoading,
    } = useInfiniteQuery(
        ['invoices'],
        async () => {
            const res = await reqGetInvoices();
            setInvoice((curData) => {
                return [...curData, ...res.data.data.items]
            });

            if (res.data.links.next !== '') {
                setPage(res.data.links.next.substring(
                    res.data.links.next.length - 1,
                    res.data.links.next.length
                ))
            }

            return res.data
        },
        {
            getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
            getNextPageParam: (lastPage) => lastPage?.links?.next !== "" ? lastPage.links.next.substring(lastPage.links.next.length - 1, lastPage.links.next) : undefined,

        },
    );

    useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
    }, [inView]);

    const handleDownloadInvoice = async invoice => {
        const FD = new FormData();
        FD.append('invoice_id', invoice.id)
        return API.post(`export/invoice`, FD, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },

        }).then(res => {
            let tempLink = document.createElement('a');
            var data = new Blob([res.data], { type: 'text/pdf' });
            var csvURL = window.URL.createObjectURL(data);
            tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', `${invoice.no_invoice}.pdf`);
            tempLink.click();
        }).catch(err => {
            handleError(err.response);
        })
    }

    return (
        <main className="px-5 py-5">
            {
                isLoading ? <Loading /> :
                    <>
                        {
                            invoices.length > 0 ?
                                <>
                                    <div className="grid grid-cols-3 gap-4">
                                        {
                                            invoices.map((invoice) => {
                                                const date = new Date(invoice.approve_date);
                                                return (
                                                    <div className="card app-shadow ring-1 ring-default px-3">
                                                        <h1 className="text-default font-semibold">#{invoice.no_invoice}</h1>
                                                        <div className="my-5">
                                                            <h1 className="text-base font-medium mb-3 capitalize">{invoice.vendor_name}</h1>
                                                            <p className="text-sm text-appblack">{invoice.event_name}</p>
                                                            <p className="text-sm text-appblack">Approve Date : {date.getDate() + ' ' + MONTHS[date.getMonth()] + ' ' + date.getFullYear()}</p>
                                                        </div>
                                                        {
                                                            profile?.permissions !== undefined ?
                                                                <>
                                                                    {
                                                                        checkPermission(profile?.permissions, 'download invoice') &&
                                                                        <div className="flex justify-center">
                                                                            <a className="btn btn-default cursor-pointer" onClick={() => handleDownloadInvoice(invoice)} download={true}>Download Invoice</a>
                                                                        </div>
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        isFetchingNextPage ?
                                            <div className="flex justify-center my-5">
                                                <button
                                                    className="btn"
                                                    ref={ref}
                                                    onClick={() => fetchNextPage()}
                                                    disabled={!hasNextPage || isFetchingNextPage}
                                                >
                                                    Loading More...
                                                </button>
                                            </div>
                                            :
                                            hasNextPage ?
                                                <div className="flex justify-center my-5">
                                                    <button
                                                        className="btn"
                                                        ref={ref}
                                                        onClick={() => fetchNextPage()}
                                                        disabled={!hasNextPage || isFetchingNextPage}
                                                    >
                                                        Load Newer
                                                    </button>
                                                </div>
                                                :
                                                <></>


                                    }
                                </>
                                :
                                <div className="w-full flex justify-center items-center flex-col">
                                    <img src={Ilustration} />
                                </div>
                        }
                    </>
            }
        </main>
    );
}

export default EventInvoice;