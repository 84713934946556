
function LeftAngle({ className }) {
  return (
    <div className={`w-10 h-10 flex justify-center items-center ring-2 ring-moregray rounded-full cursor-pointer ${className ?? ''}`}>
      <svg width="15" height="24" viewBox="0 0 15 24" className="!fill-none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2857 2.71436L3 12.0001L12.2857 21.2858" stroke="#231F20" strokeWidth="4" strokeLinecap="round" />
      </svg>
    </div>
  );
}

export default LeftAngle;