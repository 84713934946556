function Equipment() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path d="M11.6835 1.8328L10.1835 1.0415C8.8665 0.347418 8.208 0 7.5 0C6.792 0 6.1335 0.346664 4.8165 1.0415L4.57575 1.16886L11.268 5.01156L14.28 3.49678C13.7955 2.94514 13.014 2.53291 11.6835 1.83129V1.8328ZM14.811 4.49457L11.8125 6.00181V8.28979C11.8125 8.4397 11.7532 8.58346 11.6477 8.68946C11.5423 8.79545 11.3992 8.855 11.25 8.855C11.1008 8.855 10.9577 8.79545 10.8523 8.68946C10.7468 8.58346 10.6875 8.4397 10.6875 8.28979V6.56627L8.0625 7.8851V15C8.601 14.8651 9.21375 14.5426 10.1835 14.0308L11.6835 13.2396C13.2967 12.3887 14.1038 11.9637 14.5522 11.1988C15 10.4346 15 9.48277 15 7.58139V7.49322C15 6.06662 15 5.17434 14.811 4.49457ZM6.9375 15V7.88585L0.189 4.49457C6.70552e-08 5.17434 0 6.06662 0 7.49171V7.57988C0 9.48277 -8.9407e-08 10.4346 0.44775 11.1988C0.89625 11.9637 1.70325 12.3895 3.3165 13.2403L4.8165 14.0308C5.78625 14.5426 6.399 14.8651 6.9375 15ZM0.72 3.49754L7.5 6.90389L10.0583 5.61897L3.39375 1.7921L3.3165 1.8328C1.98675 2.53366 1.2045 2.94589 0.72 3.49829V3.49754Z" />
            </svg>
        </>
    );
}

export default Equipment;