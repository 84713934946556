import React from 'react'

export default function SelectInputLabel({ classLabel, className, label, classInput, options, ...props }) {
    return (
        <div className={`flex flex-col gap-2 text-appblack ${className ?? ''}`}>
            <label className={`text-base tracking-[0.48px] ${classLabel}`}>{label}</label>
            <select className={` form-control !mb-0 bg-semigray  text-xl !px-4 !pt-[11px] !pb-[10px] font-medium !tracking-[0.6px] !h-[45px] disabled:!border-moregray disabled:!bg-appgray disabled:!text-moregray  ${classInput}`} {...props}>
                <option value="">Select {label}</option>
                {
                    options?.map((item) => <option key={item.value} value={item.value}>{item.label}</option>)
                }
            </select>
        </div>

    )
}
