import InputWithLabel from "components/atoms/InputWithLabel";
import BoothContext from "context/BoothContext";
import ModalContext from "context/ModalContext";
import { useContext } from "react";
import Popup from "components/Modal/Popup";
import Checklist from "components/icons/Checklist";
import { toast } from "react-toastify";
import Image from "components/atoms/Image";

const FormCheckPayment = ({ dataPayment, element }) => {
    const { approvePayment, rejectpayment } = useContext(BoothContext);
    const { setModal, setPopup } = useContext(ModalContext);

    const proceedPayment = async () => {
        const FD = new FormData();

        FD.append('booking_has_booth_id', dataPayment.id);

        const reqApprovePayment = await approvePayment(FD);

        if (reqApprovePayment.success) {
            toast.success('Success approve payment');
            setModal(undefined);
            setPopup(undefined);
        }
    }

    return (
        <>
            <form onSubmit={(e) => {
                e.preventDefault();
                setPopup('approve_payment');
            }}>
                <div>
                    {
                        dataPayment.payment_image === null ?
                            <Image
                                path={''}
                                className="w-2/4 mx-auto mb-3 object-cover"
                            />
                            :
                            <Image
                                path={dataPayment.payment_image}
                                className="w-2/4 mx-auto mb-3 object-cover"
                            />
                    }
                    {element}
                </div>
                <label htmlFor="" className="mb-2">Bank Name</label>
                <div className="valid-form rounded-2xl px-4 py-3 mb-3 text-xl font-medium">
                    <h6>{dataPayment.bank_name}</h6>
                    <h6>{dataPayment.bank_owner_name}</h6>
                    <h6>{dataPayment.bank_number}</h6>
                </div>
                <InputWithLabel
                    classInput={'!mb-3'}
                    value={dataPayment.booth_price.substring(0, dataPayment.booth_price.length - 3)}
                    label={'Price'}
                    readOnly={true}
                />
                <InputWithLabel
                    classInput={'!mb-3'}
                    value={dataPayment.vendor_name}
                    label={'Tenant Name'}
                    readOnly={true}
                />
                <InputWithLabel
                    classInput={'!mb-3'}
                    value={dataPayment.booth_number}
                    label={'Booth Number'}
                    readOnly={true}
                />
                <InputWithLabel
                    classInput={'!mb-3'}
                    value={dataPayment.category_booth_name}
                    label={'Category'}
                    readOnly={true}
                />
                <InputWithLabel
                    classInput={'!mb-3'}
                    value={dataPayment.event_name}
                    label={'Event Name'}
                    readOnly={true}
                />
                <InputWithLabel
                    classInput={'!mb-3'}
                    value={dataPayment.location}
                    label={'Venue'}
                    readOnly={true}
                />
                <div className="columns-2">
                    <InputWithLabel
                        label={'Start Date'}
                        value={dataPayment.start_date}
                        readOnly={true}
                    />
                    <InputWithLabel
                        label={'End Date'}
                        value={dataPayment.end_date}
                        readOnly={true}
                    />
                </div>
                <button type="submit" id="btn_approve_payment" hidden></button>
            </form>
            <Popup
                action={proceedPayment}
                backgroundColor="bg-badge-success"
                icon={<Checklist color='#10B986' height={35} width={35} />}
                type="approve_payment"
                textBtn="Proceed"
            >
                Are you sure want to approve this payment?
            </Popup>
        </>
    )
}

export default FormCheckPayment;
