import React from 'react'

export default function CheckboxWithLabel({ className, classCheckbox, classLabel, label, ...props }) {
    return (
        <div className={`flex gap-2 items-center ${className}`}>
            <input type="checkbox" className={`checkbox h-[18px] w-[18px] checked:before:!text-xs checked:!pt-0 ${classCheckbox}`} {...props} />
            <label className={` text-base text-appblack2 !font-league-spartan ${classLabel}`}>{label}</label>
        </div>
    )
}
