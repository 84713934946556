import { useContext, useEffect, useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Dropdown, Badge } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import TableComponent from "components/Table";
import ModalContext from "context/ModalContext";
import ModalBanner from "components/Modal/ModalBanner";
import Popup from "components/Modal/Popup";
import { MONTHS } from "helpers/Month";
import { toast } from "react-toastify";
import Trash from "components/icons/Trash";
import PurchaseContext from "context/PurchaseContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { MainsStack } from "routes";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function EventPurchasingOut() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const columnHelper = createColumnHelper();
    const [purchaseOut, setPurchaseOut] = useState([]);
    const { setModal, setPopup } = useContext(ModalContext);
    const [purchaseId, setPurchaseId] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const [products, setProducts] = useState([]);
    const [totalPage, settotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const params = useParams();

    const purchaseColumns = useMemo(
        () => [
            columnHelper.accessor('taker', {
                header: 'Taken By',
                cell: info => {
                    return info.row.original.taker.fullname
                }
            }),
            columnHelper.accessor('taker', {
                header: 'Email',
                cell: info => {
                    return info.row.original.taker.email
                }
            }),
            columnHelper.accessor('taker', {
                header: 'Phone',
                cell: info => {
                    return info.row.original.taker.phone_number
                }
            }),
            columnHelper.accessor('created_at', {
                header: 'Taken at',
                cell: info => {
                    if (info.row.original.created_at !== null) {
                        const createdAt = new Date(info.row.original.created_at);
                        return (
                            <div>{createdAt.getDate()} {MONTHS[createdAt.getMonth()]} {createdAt.getFullYear()}</div>
                        )
                    } else {
                        return (
                            <div>-</div>
                        )
                    }
                }
            }),
            columnHelper.accessor('canceled_at', {
                header: 'Status',
                cell: info => {
                    if (info.row.original.canceled_at !== null) {
                        return (
                            <Badge
                                className="rounded-full px-2 w-fit text-[#B91010] bg-badge-error"
                            >
                                Cancelled
                            </Badge>
                        )
                    } else {
                        return (
                            <Badge
                                className="rounded-full px-2 w-fit text-[#10B986] bg-badge-success"
                            >
                                Success
                            </Badge>
                        )
                    }
                }
            }),
            columnHelper.accessor('canceled_by', {
                header: 'Cancelled By',
                cell: info => {
                    return (
                        <>
                            {info.row.original.canceled_by !== null ? info.row.original.canceled_by.fullname : <p className="text-moregray">Not Set</p>}
                        </>
                    )
                }
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: info => {
                    return (
                        <>
                            <div className="text-center">
                                <div className="text-center">
                                    <Dropdown
                                        renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                    >
                                        <Dropdown.Item
                                            onClick={() => {
                                                handleSeeProduct(info.row.original);
                                            }}
                                        >
                                            See Product
                                        </Dropdown.Item>
                                        {
                                            info.row.original.canceled_at === null &&
                                                profile?.permissions !== undefined ?
                                                <>
                                                    {
                                                        checkPermission(profile?.permissions, 'cancel stock out') &&
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setPurchaseId(info.row.original.id);
                                                                setInvoiceNumber(info.row.original.invoice_number);
                                                                setPopup('cancel_purchase')
                                                            }}
                                                        >
                                                            Cancel Purchase
                                                        </Dropdown.Item>
                                                    }
                                                </>
                                                :
                                                <></>
                                        }
                                    </Dropdown>
                                </div>
                            </div>
                        </>
                    )
                }
            })
        ], []
    )

    const handleGetDataPurchaseOut = async (id, page = 1) => {
        return API.get(`purchase-out?event_id=${id}&page=${page}`).then(ress => {
            setPurchaseOut(ress.data.data.items);
        }).catch(err => {
            handleError(err.response);
        })
    }

    const handleDeletePurchase = async id => {
        return API.delete(`purchase-out/${id}`);
    }

    const purchaseOutQuery = useQuery({
        queryFn: () => {
            handleGetDataPurchaseOut(params.event_id, currentPage);
        },
        queryKey: ['purchase-out', currentPage,],
        select(data) {
            return data.data
        }
    })

    const updateMutation = useMutation({
        mutationFn: async () => {
            const response = await handleDeletePurchase(purchaseId)
            return response.data
        },
        onSuccess: (data) => {
            setPurchaseOut(currData => {
                return currData.map(item => {
                    if (item.id === data.data.id) {
                        return data.data;
                    }

                    return item;
                })
            })
            toast.success('Success canceled purchase.');
        },
        onError: (error) => {
            handleError(error.response);
        }
    })

    const handleSeeProduct = data => {
        setProducts(data.inventories);
        setModal('list_product')
    }

    useEffect(() => {
        if (purchaseOutQuery.data !== undefined) {
            setPurchaseOut(purchaseOutQuery.data?.data?.items.map((purchase) => ({ ...purchase })) ?? [])
            settotalPage(purchaseOutQuery.data?.meta.total_page);
            setCurrentPage(purchaseOutQuery.data?.meta.current_page.slice(
                purchaseOutQuery.data?.meta.current_page.indexOf('page=') + 5
            ))
        }
    }, [purchaseOutQuery.data])

    return (
        <>
            <main className="py-5 px-5">
                {
                    profile?.permissions !== undefined ?
                        <>
                            {
                                checkPermission(profile?.permissions, 'add stock out') &&
                                <div className="flex justify-end">
                                        <Link className="btn btn-default" to={MainsStack.createPurchaseOut.replace(':event_id', params.event_id)}>Create Stock Out</Link>
                                </div>
                            }
                        </>
                        :
                        <></>
                }
                <div className="app-shadow mt-5 p-5">
                    <TableComponent
                        data={purchaseOut}
                        columns={purchaseColumns}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        showPagination={true}
                        actionLoadPage={setCurrentPage}
                    />
                </div>
            </main>
            <ModalBanner
                choosedModal={'list_product'}
                title={`List Product`}
                textButton={null}
                actionClearForm={() => { }}
                useModalFooter={false}
            >
                <ul className="space-y-3">
                    {
                        products.map(product => {
                            return (
                                <li className="">
                                    <div className="card app-shadow flex justify-between px-5 items-center">
                                        <div>
                                            <h1 className="font-medium">{product.name}</h1>
                                            <p>Total Items : {product.pivot.qty > 1 ? product.pivot.qty + ' Pcs' : product.pivot.qty + ' Pc'}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </ModalBanner>
            <Popup
                action={() => {
                    updateMutation.mutate();
                }}
                type="cancel_purchase"
                textBtn="Proceed"
                icon={<Trash width={35} height={35} />}
            >
                Are you sure you want to cancel purchase <strong>#{invoiceNumber}</strong>?
            </Popup>
        </>
    );
}

export default EventPurchasingOut;