import API from "utils/api";

export function getEventTncService(event_id) {
    if (event_id === undefined) {
        return API.get(`/public-tnc`)
    }
    return API.get(`/event-tnc/${event_id}`)
}

export function getEventPrivacyService(event_id) {
    if (event_id === undefined) {
        return API.get(`/public-privacy-policy`)
    }
    return API.get(`/event-privacy/${event_id}`)
}

export function postEventPrivacyService(data, global) {
    if (global) {
        return API.post(`/privacy-policy`, data)
    }
    return API.post(`/event-privacy`, data)
}

export function postEventTncService(data, global) {
    if (global) {
        return API.post(`/tnc`, data)
    }
    return API.post(`/event-tnc`, data)
}

export function getBankListService() {
    return API.get('/bank')
}

export function updateBankService(data) {
    return API.post('/event/bank', data)
}