import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { handleError } from 'helpers/HandleRequestApi'
import { useEffect, useState } from 'react'
import { getEventPrivacyService, postEventPrivacyService } from 'services/settings'
import Swal from 'sweetalert2'
import FormTnc from './FormTnc'

export default function PrivacyPolicy({ event_id, type }) {
    const privacyQuery = useQuery({
        queryFn: () => getEventPrivacyService(event_id),
        queryKey: ['event-privacy-policy', event_id],
        select: (data) => {
            return data.data
        }
    })

    const queryClient = useQueryClient()
    const [formValue, setFormValue] = useState({
        link: '',
        tnc: '',
        wantToWriteChecked: false
    })

    useEffect(() => {
        if (privacyQuery.data !== undefined) {
            setFormValue({
                link: privacyQuery.data?.data?.privacy_policy_url,
                tnc: privacyQuery.data?.data?.privacy_policy,
                wantToWriteChecked: privacyQuery.data?.data?.privacy_policy_url === ""
            })
        }
    }, [privacyQuery.data])


    const handleSubmit = (e) => {
        e.preventDefault()
        submitMutaion.mutate()
    }

    const submitMutaion = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            if (event_id !== undefined) {
                formData.append('event_id', event_id)
            }
            formData.append("privacy_policy", formValue.tnc)
            formData.append("privacy_policy_url", formValue.link)
            const response = await postEventPrivacyService(formData, true)
            return response.data
        },
        onSuccess(ress) {
            Swal.fire({
                icon: 'success',
                text: 'Update privacy policy success',
                timer: 3000
            })
            queryClient.invalidateQueries(['event-privacy-policy', event_id])
        },
        onError(data) {
            handleError(data.response)
        }
    })
    return (
        <FormTnc
            handleSubmit={handleSubmit}
            formValue={formValue}
            setFormValue={setFormValue}
            isLoading={submitMutaion.isLoading || privacyQuery.status === "loading"}
            type={type}
        />
    )
}
