import { useMutation, useQueryClient } from '@tanstack/react-query'
import ButtonPill from 'components/atoms/ButtonPill'
import InputWithLable from 'components/atoms/InputWithLabel'
import { useEffect, useState } from 'react'
import { updateProfileService } from 'services/user'
import Swal from 'sweetalert2'
import { normalizeInputPhone } from 'utils/normalize'

var initialProfile = {
    fullname: '',
    phone: ''
}

export default function FormProfile({ profile }) {
    const [profileForm, setProfileForm] = useState(initialProfile)
    const client = useQueryClient()
    useEffect(() => {
        setProfileForm({ fullname: profile.fullname, phone: profile.phone_number })
    }, [profile])


    const updateMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            formData.append('fullname', profileForm.fullname)
            formData.append('phone_number', profileForm.phone)
            const response = await updateProfileService(formData)
            return response.data
        },
        onSuccess(data, variables, context) {
            Swal.fire({
                icon: 'success',
                text: 'Successfully update profile',
                timer: 3000,
            }).then((ress) => {
                if (ress.isDismissed || ress.isConfirmed) {
                    client.invalidateQueries(['profile'])
                }
            })
        },
        onError(error, variables, context) {

        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        updateMutation.mutate()
    }

    return (
        <form className=' flex flex-col gap-6' onSubmit={handleSubmit}>
            <InputWithLable label="Full Name" value={profileForm.fullname} onChange={(e) => setProfileForm(p => ({ ...p, fullname: e.target.value }))} />
            <InputWithLable label="Email" disabled value={profile.email} />
            <InputWithLable minLength={11} label="Phone" value={profileForm.phone} onChange={(e) => setProfileForm(p => ({ ...p, phone: normalizeInputPhone(e.target.value) }))} />
            <div className=' flex justify-end mt-4'>
                <ButtonPill type="submit" label="Update Profile" disabled={updateMutation.isLoading} />
            </div>
        </form>
    )
}
