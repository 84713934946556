import {
    GET_BOOTH_CATEGORY,
    ADD_BOOTH_CATEGORY,
    EDIT_BOOTH_CATEGORY,
    DELETE_BOOTH_CATEGORY,
    GET_PROPOSE_BOOTH_BY_BOOTH_CATEGORY,
    ADD_BOOTH,
    EDIT_BOOTH,
    DELETE_BOOTH,
    APPROVE_VENDOR_BY_BOOTH,
    REJECT_VENDOR_BY_BOOTH,
    PROPOSE_BOOTH_TO_VENDOR,
    SET_ACTIVE_BOOTH,
    CHECK_PAYMENT,
    APPROVE_PAYMENT,
    REJECT_PAYMENT,
    CHANGE_ACTIVE_BOOTH,
    SET_WINNER_OF_WHEEL,
    CHANGE_BOOTH_CATEGORY,
    GET_BOOTH_BY_CATEGORY,
} from 'types/BoothTypes';

export default (state, { type, payload }) => {
    switch (type) {
        case GET_BOOTH_CATEGORY:
            let theFirstElementUpdated = false;
            return {
                ...state,
                categoryBooth: payload.map((cb, index) => {
                    if (index === 0) {
                        cb.is_active = true;
                    } else {
                        cb.is_active = false;
                    }

                    return cb;
                }),
                booth:
                    payload.length > 0
                        ? payload[0].list_booth.map((item, index) => {
                              if (
                                  !theFirstElementUpdated &&
                                  item.book_by === null
                              ) {
                                  theFirstElementUpdated = true;
                                  item.is_active = true;
                              } else {
                                  item.is_active = false;
                              }

                              return item;
                          })
                        : [],
                isLoading: true,
            };
        case EDIT_BOOTH_CATEGORY:
            return {
                ...state,
                categoryBooth: state.categoryBooth.map(item => {
                    if (item.id === payload.id) {
                        item.name = payload.name;
                        item.img_url = payload.img_url;
                    }

                    return item;
                }),
            };
        case DELETE_BOOTH_CATEGORY:
            let selected = state.categoryBooth.find(
                item => item.id === payload
            );
            let categoryAfterDelete = state.categoryBooth.filter(
                item => item.id !== payload
            );
            return {
                ...state,
                // categoryBooth: state.categoryBooth.filter(
                //     item => item.id !== payload
                // ),
                // categoryBooth: state.categoryBooth.map(item => {
                //     if (item.id === payload && item.is_active === true) {

                //     } else {

                //     }
                // })
                categoryBooth: selected.is_active
                    ? categoryAfterDelete.map((item, index) => {
                          if (index === 0) {
                              item.is_active = true;
                          } else {
                              item.is_active = false;
                          }

                          return item;
                      })
                    : categoryAfterDelete,
            };
        case GET_BOOTH_BY_CATEGORY:
            let firstActiveBooth = false;
            return {
                ...state,
                booth: payload.map(item => {
                    if (!firstActiveBooth && item.book_by === null) {
                        firstActiveBooth = true;
                        item.is_active = true;
                    } else {
                        item.is_active = false;
                    }

                    return item;
                }),
            };
        case CHANGE_ACTIVE_BOOTH:
            return {
                ...state,
                booth: state.booth.map(item => {
                    if (item.id === payload) {
                        item.is_active = true;
                        return item;
                    }

                    item.is_active = false;
                    return item;
                }),
            };
        case CHANGE_BOOTH_CATEGORY:
            return {
                ...state,
                categoryBooth: state.categoryBooth.map(cb => {
                    if (cb.id === payload) {
                        cb.is_active = true;
                    } else {
                        cb.is_active = false;
                    }

                    return cb;
                }),
                // booth: payload.data.data,
            };
        case ADD_BOOTH_CATEGORY:
            return {
                ...state,
                categoryBooth:
                    state.categoryBooth.length === 0
                        ? [{ ...payload, is_active: true }]
                        : [...state.categoryBooth, payload],
                // categoryBooth: [...state.categoryBooth, payload],
            };
        case ADD_BOOTH:
            return {
                ...state,
                booth: [...state.booth, payload.data],
            };
        case EDIT_BOOTH:
            return {
                ...state,
                booth: state.booth.map(item => {
                    if (item.id === payload.data.id) {
                        return payload.data;
                    }

                    return item;
                }),
            };
        case DELETE_BOOTH:
            return {
                ...state,
                booth: state.booth.filter(item => item.id !== payload),
            };
        case GET_PROPOSE_BOOTH_BY_BOOTH_CATEGORY:
            return {
                ...state,
                proposeBooth: payload,
            };
        case APPROVE_VENDOR_BY_BOOTH:
            return {
                ...state,
                booth: state.booth.map(booth => {
                    if (booth.id === payload.booth_id) {
                        booth.book_by = payload.status;
                        return booth;
                    }

                    return booth;
                }),
            };
        case REJECT_VENDOR_BY_BOOTH:
            return {
                ...state,
                booth: state.booth.map(item => {
                    if (item.id === payload.id) {
                        return payload;
                    }

                    return item;
                }),
            };
        case PROPOSE_BOOTH_TO_VENDOR:
            return {
                ...state,
            };
        case SET_ACTIVE_BOOTH:
            const choosedBooth = state.categoryBooth.find(
                item => item.id == payload.id
            );
            let theFirstActiveBooth = false;
            return {
                ...state,
                activeBooth: {
                    ...choosedBooth,
                    list_booth: choosedBooth.list_booth.map((item, index) => {
                        if (!theFirstActiveBooth && item.book_by === null) {
                            theFirstActiveBooth = true;
                            item.is_active = true;
                            return item;
                        } else {
                            item.is_active = false;
                            return item;
                        }
                    }),
                },
            };
        case CHECK_PAYMENT:
            return {
                ...state,
                boothPayment: payload,
            };
        case APPROVE_PAYMENT:
            return {
                ...state,
                booth: state.booth.map(booth => {
                    if (booth.id === payload.booth_id) {
                        booth.book_by = payload.vendor_name;
                    }

                    return booth;
                }),
            };
        case REJECT_PAYMENT:
            return {
                ...state,
                booth: state.booth.map(booth => {
                    if (booth.id === payload.booth_id) {
                        booth.book_by = payload.status;
                    }

                    return booth;
                }),
            };
        case SET_WINNER_OF_WHEEL:
            let firstElUpdate1 = false;
            return {
                ...state,
                booth: state.booth.map(booth => {
                    if (booth.id === payload.id) {
                        booth = payload;
                    }

                    if (!firstElUpdate1 && booth.book_by === null) {
                        booth.is_active = true;
                        firstElUpdate1 = true;
                    } else {
                        booth.is_active = false;
                    }

                    return booth;
                }),
            };
    }
};
