import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export function showToast(icon, title, message = '', showCancelButton = false) {
    return MySwal.fire({
        title: title,
        icon: icon,
        text: message,
        showCancelButton: showCancelButton,
    });
}
