import React from 'react'

export default function TextAreaWithLabel({ classLabel, className, label, classInput, ...props }) {
    return (
        <div className={`flex flex-col gap-2 text-appblack ${className}`}>
            <label className={`text-base tracking-[0.48px] ${classLabel}`}>{label}</label>
            <textarea rows={7} placeholder={`Enter ${label}`} className={` form-control !mb-0 bg-semigray !px-4 !pt-[11px] !pb-[10px] font-normal !tracking-[0.48px] !h-auto disabled:!ring-moregray disabled:!bg-appgray disabled:!text-moregray !rounded-[20px] text-base ${classInput}`} {...props} ></textarea>
        </div>
    )
}
