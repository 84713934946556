export const ADD_EVENT = 'ADD_EVENT';
export const GET_EVENT = 'GET_EVENT';
export const STERIL_EVENT = 'STERIL_EVENT';
export const GET_EVENT_HISTORY = 'GET_EVENT_HISTORY';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DUPLICATE_EVENT = 'DUPLICATE_EVENT';
export const RESET_EVENT = 'RESET_EVENT';
export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
export const GET_EVENT_PAGINATION = 'GET_EVENT_PAGINATION';
