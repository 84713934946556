import { lazy, useContext } from "react";
import { Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";
import { Avatar } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import { MainsStack } from "routes";
import ModalContext from "context/ModalContext";
import NewEventContext from "context/NewEventContext";
import Image from "./Image";
import { MONTHS } from "helpers/Month";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function EventItem({ event, showDropdown = true, seeEvent = true, permissions }) {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { setModal, setPopup } = useContext(ModalContext);
    const { setTitle, setStartDate, setEndDate, setDescription, setVenue, setTnc, setEventId, setSelectedEvent, setOldImages, setOldFiles, setReleaseDate, setIsUsingCountDown } = useContext(NewEventContext);

    const startDate = new Date(event.start_date);
    const endDate = new Date(event.end_date);

    return (
        <>
            <div className="flex flex-col card app-shadow min-h-[300px]">
                <div className="card-header border-b-2 border-moregray pb-5">
                    <div className='flex justify-between items-center'>
                        <h1>{event.name}</h1>
                        {
                            showDropdown &&
                                profile?.permissions !== undefined ?
                                <>
                                    {
                                        checkPermission(profile?.permissions, 'update event') ||
                                            checkPermission(profile?.permissions, 'delete event') ||
                                            checkPermission(profile?.permissions, 'duplicate event') ||
                                            checkPermission(profile?.permissions, 'reset event') ?
                                            <Dropdown
                                                renderTrigger={() => <button className='w-20 flex justify-end'><ThreeDots /></button>}
                                            >
                                                {
                                                    checkPermission(profile?.permissions, 'update event') &&
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setTitle(event.name);
                                                            setDescription(event.description);
                                                            setStartDate(event.start_date.substring(0, 10));
                                                            setEndDate(event.end_date.substring(0, 10));
                                                            setTnc(event.tnc);
                                                            setVenue(event.location)
                                                            setEventId(event.id);
                                                            setModal('edit_event');
                                                            setOldFiles(event.list_file)
                                                            setOldImages(event.list_img)
                                                            setReleaseDate(event.release_date.substring(0, 16))
                                                            setIsUsingCountDown(event.is_using_countdown)
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>
                                                }
                                                {
                                                    checkPermission(profile?.permissions, 'delete event') &&
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedEvent(event.name);
                                                            setEventId(event.id);
                                                            setPopup('delete_event');
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>
                                                }
                                                {
                                                    checkPermission(profile?.permissions, 'duplicate event') &&
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedEvent(event.name);
                                                            setEventId(event.id);
                                                            setPopup('duplicate_event');
                                                        }}
                                                    >
                                                        Duplicate
                                                    </Dropdown.Item>
                                                }
                                                {
                                                    checkPermission(profile?.permissions, 'reset event') &&
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedEvent(event.name);
                                                            setEventId(event.id);
                                                            setPopup('reset_event');
                                                        }}
                                                    >
                                                        Reset
                                                    </Dropdown.Item>
                                                }
                                            </Dropdown>
                                            :
                                            <></>
                                    }
                                </>
                                :
                                <>
                                </>
                        }
                    </div>
                </div>
                <div className="card-body px-5 grow flex flex-col">
                    <div className='space-y-3'>
                        <div>
                            <span>{startDate.getDate()} {MONTHS[startDate.getMonth()]} {startDate.getFullYear()}</span> - <span>{endDate.getDate()} {MONTHS[endDate.getMonth()]} {endDate.getFullYear()}</span>
                        </div>
                        <div>
                            Venue : <span className='capitalize'>{event.location}</span> <br />
                            Status : {event.is_publish ? 'Publish' : 'Not publish'}
                        </div>
                    </div>
                    <div className='flex justify-end items-end mt-auto'>
                        <Avatar.Group>
                            {
                                event.list_member.map(member => {
                                    return (
                                        <Image
                                            path={member.img_url}
                                            style={{
                                                width: 30,
                                                height: 30
                                            }}
                                            className="hover:scale-110 cursor-pointer rounded-full"
                                            loading={lazy}
                                        />
                                    )
                                })
                            }
                        </Avatar.Group>
                    </div>
                </div>
                {
                    profile?.permissions !== undefined ?
                        checkPermission(profile?.permissions, 'view event') &&
                        seeEvent &&
                        <div className="card-footer self-end items-end">
                            <Link className="btn-outline" to={MainsStack.eventHome.replace(':event_id', event.id)}>See Event</Link>
                        </div>
                        :
                        <></>
                }
            </div>
        </>
    );
}

export default EventItem;
