import { useContext, useState } from "react";
import SearchIcon from "components/icons/SearchIcon";
import InventoryContext from "context/InventoryContext";
import { useParams } from "react-router-dom";
import Cart from "components/icons/Cart";
import { toast } from "react-toastify";
import NewMemberContext from "context/NewMemberContext";
import Plus from "components/icons/Plus";
import ModalContext from "context/ModalContext";
import Ilustration from "../../assets/images/event-ilustration.svg";

function FormSearchMember({ setDetailMember }) {
    const { setModal } = useContext(ModalContext)
    const { getInventoryData, inventory } = useContext(InventoryContext);
    const { getMemberByEvent } = useContext(NewMemberContext);
    const [memberEmail, setMemberEmail] = useState('');
    const params = useParams();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearchProduct = async (event) => {
        event.preventDefault();
        setLoading(true);

        const reqGetInventoryData = await getMemberByEvent(params.event_id, 1, memberEmail);

        if (reqGetInventoryData.data.success) {
            setLoading(false);
            setMembers(reqGetInventoryData.data.data.items);
        }
    }

    return (
        <>
            <form>
                <div className={`rounded-full border border-appgray h-10 pl-3 overflow-hidden w-full mb-2 focus:outline-none focus:bg-[#FFFDFA] focus:ring-1 focus:ring-default focus-within:outline-none focus-within:ring-1 focus-within:ring-default focus-within:bg-[#FFFDFA] ${memberEmail.length > 0 ? `valid-form` : ``}`}>
                    <input type="text" className="w-4/5 px-0 ring-0 border-0 focus:outline-none focus:ring-0 focus:border-0" onChange={e => setMemberEmail(e.target.value)} />
                    {
                        loading ?
                            <button type="btn" className="bg-default float-right h-full w-1/5 rounded-full flex text-white justify-center items-center cursor-not-allowed">
                                Loading ...
                            </button>
                            :
                            <>
                                {
                                    memberEmail.length > 0 ?
                                        <button type="btn" onClick={handleSearchProduct} className="bg-default float-right h-full w-1/5 rounded-full flex justify-center items-center">
                                            <SearchIcon color={'#F77C32'} />
                                        </button>
                                        :
                                        <div type="btn" className="bg-appgray cursor-not-allowed float-right h-full w-1/5 rounded-full flex justify-center items-center">
                                            <SearchIcon color={'#E5E5E5'} />
                                        </div>
                                }
                            </>
                    }
                </div>
                <ul className="mt-5 space-y-3">
                    {
                        members.length > 0 ?
                            <>
                                {
                                    members.map(item => {
                                        return (
                                            <li>
                                                <div className="card app-shadow flex justify-between px-5 items-center">
                                                    <div>
                                                        <h1>{item.email}</h1>
                                                        <p>{item.fullname}</p>
                                                    </div>
                                                    <div className="cursor-pointer" onClick={() => {
                                                        setDetailMember({
                                                            id: item.id,
                                                            email: item.email,
                                                            fullname: item.fullname,
                                                            phone: item.phone_number
                                                        })
                                                        setModal(undefined);
                                                    }}>
                                                        <Plus color={'#F77C32'} width={100} height={100} />
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </>
                            :
                            <>
                                <div className="w-full flex justify-center items-center flex-col">
                                    <img src={Ilustration} width={250} />
                                </div>
                            </>

                    }
                </ul>
            </form>
        </>
    );
}

export default FormSearchMember;