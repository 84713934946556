import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { MONTHS } from 'helpers/Month';
import { Bar } from 'react-chartjs-2';
import { formatValueMoney } from 'utils/normalize';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Chart',

        },
    },
    scales: {
        x: {
            grid: { display: false },
        },
        y: {
            display: true,
            // min: -(getMinChart(values) / 2),
            border: {
                dash: [5, 5],
            },
            grid: {
                offset: true,
            },
            ticks: {
                callback: function (value, index, ticks) {
                    if (value < 0) {
                        const abs = Math.abs(value)
                        if (abs < 1000) {
                            return '-' + abs
                        } else if (abs < 1000000) {
                            return '-' + abs.toString().slice(0, 1) + ' rb'
                        } else if (abs < 1000000000) {
                            return '-' + abs.toString().slice(0, 1) + ' jt'
                        }
                        return '-' + abs.toString().slice(0, 1) + ' M';

                    }
                    return formatValueMoney(value)
                }
            }
        }
    }
};



export default function TotalIncome({ data }) {
    const labels = data.map((item) => {
        const dateLabel = new Date(item.payment_month)
        return MONTHS[dateLabel.getMonth()] + ' ' + dateLabel.getFullYear();
    });
    const dataValue = {
        labels,
        datasets: [
            {
                label: ' Total Income',
                data: data.map((item) => parseInt(item.income)),
                backgroundColor: '#28C784',
                borderColor: '#28C784',
                borderRadius: 10,
                borderSkipped: false,
                borderWidth: 2,
                maxBarThickness: 43
            },
        ],
    };

    return <Bar options={options} data={dataValue} />;
}
