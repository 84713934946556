function Home() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                <path d="M5.58718 16.896V14.144C5.58718 13.4416 6.12393 12.8721 6.78605 12.8721H9.20641C9.52437 12.8721 9.82931 13.0061 10.0541 13.2446C10.279 13.4832 10.4053 13.8067 10.4053 14.144V16.896C10.4033 17.1881 10.5112 17.4689 10.7052 17.6762C10.8991 17.8834 11.163 18 11.4383 18H13.0895C13.8607 18.0021 14.6011 17.6785 15.1471 17.1007C15.6931 16.5229 16 15.7383 16 14.92V7.08017C16 6.41921 15.7238 5.79225 15.246 5.3682L9.62865 0.608282C8.6515 -0.226294 7.25146 -0.199348 6.30349 0.672281L0.814326 5.3682C0.313887 5.77975 0.0147809 6.40857 0 7.08017V14.912C0 16.6175 1.30306 18 2.91046 18H4.52403C5.09577 18 5.56042 17.5106 5.56456 16.904L5.58718 16.896Z" />
            </svg>
        </>
    );
}

export default Home;