import axios from "axios";

const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export function getDataWithToken(url, token = null) {
    // API.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return API.get(url);
}

export function postDataWithToken(urlLink, body, token = null) {
    API.defaults.headers.common["Authorization"] = "Bearer " + token;
    return API.post(urlLink, body);
}

API.interceptors.response.use(
    function (response) {
        if (
            response.config.url === `/login` ||
            response.config.url === `/refresh-token`
        ) {
            const auth = response.data;
            API.defaults.headers.common["Authorization"] =
                "Bearer " + auth.token;
        }
        return response;
    },
    function (error) {
        const statusCode = error.response.status;
        if (statusCode === 401 || statusCode === 404) {
            // store.dispatch(logoutSlice())
        }
        return Promise.reject(error);
    }
);

export function setCredentialRequest({ token }) {
    return new Promise((resolve, reject) => {
        API.defaults.headers.common["Authorization"] =
            token !== null ? "Bearer " + token : null;
        resolve(true);
    });
}

export function deleteDataWithToken(urlLink, token = null) {
    API.defaults.headers.common["Authorization"] = "Bearer " + token;
    return API.delete(urlLink);
}

export default API;
