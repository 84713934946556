import Grafix from "components/Grafix";
import NewMemberContext from "context/NewMemberContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { Badge, Tooltip } from "flowbite-react";
import MemberList from "components/MemberList";
import { useQuery } from "@tanstack/react-query";
import API from "utils/api";
import Checklist from "components/icons/Checklist";
import { MainsStack } from "routes";

function EventDetail(className) {
    const params = useParams();
    const [eventStatus, setEventStatus] = useState({
        has_form: false,
        has_booth: false,
        has_bank: false,
    })
    const { getMemberByEvent, membersEvent } = useContext(NewMemberContext);

    const columnHelper = createColumnHelper();

    const memberColumns = useMemo(
        () => [
            columnHelper.accessor('fullname', {
                header: 'Member Name',
            }),
            columnHelper.accessor('email', {
                header: 'Email',
            }),
            columnHelper.accessor('phone_number', {
                header: 'Phone Number',
            }),
            columnHelper.accessor('is_active', {
                header: 'Status',
                cell: (info) => {
                    const view = info.renderValue() ?
                        <Badge
                            className="rounded-full px-2 w-fit text-[#10B986] bg-badge-success"
                        >
                            Activated
                        </Badge>
                        :
                        <Badge
                            className="rounded-full px-2 w-fit text-[#B91010] bg-badge-error"
                        >
                            Inactivated
                        </Badge>
                        ;

                    return view;
                }
            }),
        ],
        []);

    const getEventStatus = useQuery({
        queryKey: 'event-status',
        queryFn: () => {
            return API.get(`event/${params.event_id}/status`)
        },
        select(data) {
            return data.data
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0);
        getMemberByEvent(params.event_id);
        if (getEventStatus.data !== undefined) {
            setEventStatus({
                has_form: getEventStatus.data.has_form,
                has_booth: getEventStatus.data.has_booth,
                has_bank: getEventStatus.data.has_bank
            })
        }
    }, [getEventStatus.data]);

    return (
        <main className="px-5 mt-5">
            <div className="app-shadow px-10 pt-5 pb-12 mb-3">
                <div class="flex items-center justify-between">
                    {
                        eventStatus.has_form ?
                            <>
                                <Link to={MainsStack.eventForm.replace(':event_id', params.event_id)}>
                                    <div class="flex items-center text-white relative">
                                        <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-green bg-badge-success">
                                            <Checklist width={20} height={20} color={'#10B986'} />
                                        </div>
                                        <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-green ">
                                            Form Question
                                        </div>
                                    </div>
                                </Link>
                                <div class="flex-auto border-t-2 transition duration-500 ease-in-out border-app-green">
                                </div>
                            </>
                            :
                            <>
                                <Link to={MainsStack.eventForm.replace(':event_id', params.event_id)}>
                                    <div class="flex items-center text-white relative">
                                        <Tooltip content={'Need category booth to publish the event'}>
                                            <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-red bg-badge-error text-app-red text-4xl font-extrabold">
                                                !
                                            </div>
                                        </Tooltip>
                                        <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-red ">
                                            Form Question
                                        </div>
                                    </div>
                                </Link>
                                <div class="flex-auto border-t-2 transition duration-500 ease-in-out border-app-red">
                                </div>
                            </>
                    }
                    {
                        eventStatus.has_booth ?
                            <>
                                <Link to={MainsStack.eventBooth.replace(':event_id', params.event_id)}>
                                    <div class="flex items-center text-white relative">
                                        <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-green bg-badge-success">
                                            <Checklist width={20} height={20} color={'#10B986'} />
                                        </div>
                                        <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-green ">
                                            Booth
                                        </div>
                                    </div>
                                </Link>
                                <div class="flex-auto border-t-2 transition duration-500 ease-in-out border-app-green">
                                </div>
                            </>
                            :
                            <>
                                <Link to={MainsStack.eventBooth.replace(':event_id', params.event_id)} >
                                    <div class="flex items-center text-white relative">
                                        <Tooltip content={'Need booth to publish the event'}>
                                            <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-red bg-badge-error text-app-red text-4xl font-extrabold">
                                                !
                                            </div>
                                        </Tooltip>
                                        <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-red ">
                                            Booth
                                        </div>
                                    </div>
                                </Link>
                                <div class="flex-auto border-t-2 transition duration-500 ease-in-out border-app-red">
                                </div>
                            </>
                    }
                    {
                        eventStatus.has_bank ?
                            <>
                                <Link to={MainsStack.eventSettings.replace(':event_id', params.event_id)}>
                                    <div class="flex items-center text-white relative">
                                        <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-green bg-badge-success">
                                            <Checklist width={20} height={20} color={'#10B986'} />
                                        </div>
                                        <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-green ">
                                            Bank Account
                                        </div>
                                    </div>
                                </Link>
                                <div class="flex-auto border-t-2 transition duration-500 ease-in-out border-app-green">
                                </div>
                            </>
                            :
                            <>
                                <Link to={MainsStack.eventSettings.replace(':event_id', params.event_id)}>
                                    <div class="flex items-center text-white relative">
                                        <Tooltip content={'Need bank account to publish the event'}>
                                            <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-red bg-badge-error text-app-red text-4xl font-extrabold">
                                                !
                                            </div>
                                        </Tooltip>
                                        <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-red ">
                                            Bank Account
                                        </div>
                                    </div>
                                </Link>
                                <div class="flex-auto border-t-2 transition duration-500 ease-in-out border-app-red">
                                </div>
                            </>
                    }
                    {
                        eventStatus.has_form && eventStatus.has_booth && eventStatus.has_bank ?
                            <>
                                <div class="flex items-center text-white relative">
                                    <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-green bg-badge-success">
                                        <Checklist width={20} height={20} color={'#10B986'} />
                                    </div>
                                    <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-green ">
                                        Published
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div class="flex items-center text-white relative">
                                    <Tooltip content={'Need step 1, 2, and 3 to publish'}>
                                        <div class=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-red bg-badge-error text-app-red text-4xl font-extrabold">
                                            !
                                        </div>
                                    </Tooltip>
                                    <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-red ">
                                        Published
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            <Grafix />
            <MemberList
                dataMember={membersEvent}
                memberColumns={memberColumns}
                showMemberList={false}
                pageSize={5}
            />
        </main>
    );
}

export default EventDetail;