/* eslint-disable react-hooks/exhaustive-deps */
import IcUpload from 'components/icons/IcUpload'
import { useEffect, useRef } from 'react'
export default function CardFormRegister({ isRequired, typeInput, question, setQuestions, access_token }) {
    const handleChange = (key, value) => {
        setQuestions(p => p.map((item) => {
            if (item.id === question.id) {
                return {
                    ...item,
                    [key]: value
                }

            } else {
                return item
            }
        }))
    }
    return (
        <div className=' relative flex flex-col gap-6 bg-white rounded-[15px] px-4 py-6'>
            {isRequired && <span className=' absolute top-3 right-4 text-app-red '>*</span>}
            <label htmlFor={question.id} className=' tracking-[0.48px] font-medium'>{question.question}</label>
            {
                typeInput === "choice" ?
                    <TypeChoice required={isRequired} question={question} handleChange={(key, value) => handleChange(key, value)} />
                    : typeInput === "text" || typeInput === "email" || typeInput === "number" || typeInput === "essay" ?
                        <TypeInput question={question} handleChange={(key, value) => handleChange(key, value)} required={isRequired} type={typeInput} />
                        : typeInput === "file" ?
                            <TypeInputFile access_token={access_token} question={question} handleChange={(key, value) => handleChange(key, value)} required={isRequired} />
                            : typeInput === "date" ?
                                <TypeDate question={question} handleChange={(key, value) => handleChange(key, value)} required={isRequired} />
                                : typeInput === "textarea" ?
                                    <TypeTextArea isRequired={isRequired} handleChange={(key, value) => handleChange(key, value)} />
                                    : ''
            }
        </div>
    )
}

const TypeChoice = ({ required, handleChange, question }) => {
    return <ul>
        {
            question?.choices?.map((choice) => {
                return <li className=' flex items-center' key={choice.id}>
                    <label className=' tracking-[0.48px]'>
                        <input value={choice.id}
                            onChange={(e) => handleChange('choices', question.choices.map((item) => ({ ...item, is_selected: item.id === choice.id ? 1 : 0 })))}
                            checked={choice.is_selected === 1}
                            name={question.question}
                            required={required}
                            className='opacity-0 !m-0 peer'
                            type="radio" />
                        <span className='  peer-checked:border peer-checked:border-default peer-checked:after:opacity-100 -left-2 top-[6px] cursor-pointer w-[17px] h-[17px] border border-appblack inline-block rounded-[50%] relative after:content[""] after:w-[10px] after:h-[10px] after:bg-default after:absolute after:rounded-[50%] after:top-1/2 after:left-1/2 after:opacity-0 after:translate-x-[-50%] after:translate-y-[-50%]'></span>
                        {choice.choice}
                    </label>
                </li>
            })
        }

    </ul>
}

const TypeInput = ({ type, required, handleChange, question }) => {
    return <input
        id={question.id}
        value={question.essay_answer ?? ''}
        onChange={(e) => {
            handleChange('essay_answer', e.target.value)
        }} required={required} type={type}
        className=' border-x-0 border-t-0 border-b font-normal focus:ring-0 focus:border-appblack px-0 pt-0 text-base !outline-none border-b-appblack pb-2 placeholder:text-moregray tracking-[0.48px] text-appblack' placeholder='Your Answer' />
}

const TypeInputFile = ({ required, handleChange, question, access_token }) => {
    const ref = useRef(null)
    return <div>
        <div className='h-0 w-0 overflow-hidden'>
            <input ref={ref} type="file"
                required={required}
                onChange={(e) => {
                    if (e.target.files?.length > 0) {
                        handleChange('file_answer', e.target.files[0])
                    }
                }} />
        </div>
        {
            question.file_answer === null ?
                ''
                : question.file_answer?.name !== undefined && <div className=' my-3 relative'>
                    <button className=' absolute -top-1 -left-1 w-8 h-8 rounded-full border-2 border-app-green bg-white text-app-green text-center' onClick={() => {
                        if (ref.current) {
                            ref.current.value = ""
                            ref.current.files = null;
                            handleChange('file_answer', null)
                        }
                    }}>x</button>
                    <img src={URL.createObjectURL(question.file_answer)} alt="" className=' rounded-lg' />
                </div>
        }
        <button type="button"
            className='rounded-full max-w-[90%] border border-appblack py-[13px] px-[30px] tracking-[0.48px] font-medium text-base text-default flex items-center gap-2'
            onClick={() => {
                if (ref.current) {
                    ref.current.click()
                    ref.current.value = "";
                }
            }}>
            {
                question.file_answer === null || question.file_answer?.name === undefined ?
                    <>
                        <IcUpload /> Upload File
                    </>
                    : <span className=' line-clamp-1'>{question.file_answer?.name}</span>
            }
        </button>
    </div>
}

const TypeDate = ({ required, handleChange, question }) => {
    return <input value={question.date_answer ?? ''} onChange={(e) => handleChange('date_answer', e.target.value)} required={required} className='border-x-0 border-t-0 border-b font-normal focus:ring-0 focus:border-appblack px-0 pt-0 text-base !outline-none border-b-appblack pb-2 placeholder:text-moregray tracking-[0.48px] text-appblack' type='date' />
}

const TypeTextArea = ({ required, handleChange }) => {
    const textAreaRef = useRef(null);
    const resizeTextArea = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            if (textAreaRef.current.scrollHeight < 200) {
                textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
            } else {
                textAreaRef.current.style.height = "200px"
            }
        }
    };
    useEffect(resizeTextArea, []);

    return <textarea
        ref={textAreaRef}
        rows={1} onChange={(e) => {
            resizeTextArea()
            handleChange('essay_answer', e.target.value)
        }} placeholder='Your answer'
        required={required}
        className='border-x-0 border-t-0 border-b font-normal focus:ring-0 focus:border-appblack px-0 pt-0 text-base !outline-none border-b-appblack pb-2 placeholder:text-moregray tracking-[0.48px] text-appblack'
        type='date' />
}