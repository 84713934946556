import { useContext } from "react";
import { Modal } from "flowbite-react";
import EventForm from "components/form/EventForm";
import ModalContext from "context/ModalContext";
import NewEventContext from "context/NewEventContext";

function ModalEvent({ action, title, choosedModal, type, }) {
    const { modal, setModal, } = useContext(ModalContext);
    const { cleanUpEventForm } = useContext(NewEventContext);

    return (
        <>
            <Modal
                position={'top-center'}
                show={modal === choosedModal}
                onClose={() => {
                    setModal(undefined)
                    cleanUpEventForm();
                }}
                size={'lg'}
            >
                <div className="border-b-[1px] border-appgray">
                    <div className="px-6 py-5 flex justify-between items-center">
                        <h1 className="text-appblack text-xl font-semibold text-center" style={{ width: '-webkit-fill-available' }}>{title}</h1>
                        <button className=" text-4xl border-[1px] border-moregray w-[32px] h-[32px] flex items-center justify-center text-moregray rounded-full" onClick={() => setModal(undefined)}>
                            <p className="-mt-[4px]">
                                &times;
                            </p>
                        </button>
                    </div>
                </div>
                <Modal.Body>
                    <EventForm
                        type={type}
                        action={action}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center w-full space-x-3">
                        <button className="btn w-24" onClick={() => document.getElementById('btn_event').click()}>Proceed</button>
                        <button type="button"
                            onClick={() => {
                                cleanUpEventForm()
                                setModal(undefined);
                            }}
                            className="btn-cancel w-24"
                        >Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalEvent;