import { useReducer, useState } from 'react';
import {
    GET_INVENTORY,
    ADD_INVENTORY,
    EDIT_INVENTORY,
    DELETE_INVENTORY,
    SEARCH_INVENTORY,
} from 'types/InventoryTypes';
import InventoryContext from './InventoryContext';
import API from 'utils/api';
import { handleError } from 'helpers/HandleRequestApi';
import InventoryReducer from 'reducer/InventoryReducer';

const InventoryState = ({ children }) => {
    const initialState = {
        inventory: [],
        currentPage: 1,
        totalPage: 1,
    };
    const [state, dispatch] = useReducer(InventoryReducer, initialState);
    const [inventoryName, setInventoryName] = useState(null);
    const [inventoryId, setInventoryId] = useState(null);

    const getInventoryData = async (id, page = 1, name = '') => {
        return API.get(`inventory?event_id=${id}&page=${page}&name=${name}`)
            .then(ress => {
                dispatch({
                    type: GET_INVENTORY,
                    payload: ress.data,
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const resetFormInventory = () => {
        setInventoryId(null);
        setInventoryName(null);
    };

    const createInventory = async data => {
        return API.post(`inventory`, data)
            .then(ress => {
                dispatch({
                    type: ADD_INVENTORY,
                    payload: ress.data.data,
                });
                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const editInventory = async (data, id) => {
        const jsonObject = {};

        data.forEach((value, key) => {
            jsonObject[key] = value;
        });
        return API.patch(`inventory/${id}`, jsonObject)
            .then(ress => {
                dispatch({
                    type: EDIT_INVENTORY,
                    payload: ress.data.data,
                });
                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const deleteInventory = async id => {
        return API.delete(`inventory/${id}`)
            .then(ress => {
                dispatch({
                    type: DELETE_INVENTORY,
                    payload: id,
                });

                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const { inventory, currentPage, totalPage } = state;

    return (
        <InventoryContext.Provider
            value={{
                currentPage,
                totalPage,
                inventory,
                inventoryName,
                inventoryId,
                setInventoryName,
                setInventoryId,
                getInventoryData,
                createInventory,
                editInventory,
                deleteInventory,
                resetFormInventory,
            }}
        >
            {children}
        </InventoryContext.Provider>
    );
};

export default InventoryState;
