import SideBar from "components/SideBar";
import Header from "components/Header";

function EventVouchers() {
    return (
        <>
            Vendors
        </>
    );
}

export default EventVouchers;