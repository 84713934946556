import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ButtonPill from 'components/atoms/ButtonPill'
import Pencil from 'components/icons/Pencil'
import Trash from 'components/icons/Trash'
import DialogForm from 'components/organism/event/eventforms/DialogForm'
import ModalContext from 'context/ModalContext'
import { handleError } from 'helpers/HandleRequestApi'
import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MainsStack } from 'routes'
import { deleteFormEventService, getListFormEventService, setActiveFormEventService } from 'services/form_admin'
import Swal from 'sweetalert2'
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";
import Loading from 'components/Loading'
import ilustration from '../assets/images/event-ilustration.svg'

export default function EventForm() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { modal, setModal } = useContext(ModalContext);
    const [dialog, setDialog] = useState()
    const { event_id } = useParams()
    const [listForm, setListForm] = useState([])
    const queryclient = useQueryClient()

    const [formValue, setFormValue] = useState({
        title: '',
        id: ''
    })

    const eventFormQuery = useQuery({
        queryFn: () => getListFormEventService(event_id),
        queryKey: ['event-form', event_id],
        select(data) {
            return data.data
        }
    })

    useEffect(() => {
        if (eventFormQuery.data !== undefined) {
            setListForm(eventFormQuery.data?.data ?? [])
        }
    }, [eventFormQuery.data])


    const activeFormMutation = useMutation({
        mutationFn: async ({ form_event_id }) => {
            const response = await setActiveFormEventService({ form_event_id })
            return response.data
        },
        onSuccess: (data) => {
            queryclient.invalidateQueries(['event-form', event_id])
            toast.success(data?.message ?? "Update success")
        },
        onError: (err) => {
            handleError(err.response)
        }
    })

    const deleteMutation = useMutation({
        mutationFn: async ({ form_event_id }) => {
            const response = await deleteFormEventService(form_event_id)
            return response.data
        },
        onSuccess(data) {
            toast.success(data?.message ?? "Delete success")
            queryclient.invalidateQueries(['event-form', event_id])
        },
        onError(err) {
            handleError(err.response)
        }
    })

    const handleDelete = (form) => {
        Swal.fire({
            icon: 'question',
            html: `Are you sure for delete from <b>${form.title}?</b>`,
            showCancelButton: true,
            showConfirmButton: true,
        }).then((ress) => {
            if (ress.isConfirmed) {
                deleteMutation.mutate({ form_event_id: form.id })
            }
        })
    }

    const checkIsActiveBeforAction = (form, callback) => {
        if (form.is_active === 1) {
            Swal.fire({
                icon: 'error',
                text: 'Form Event is active, cant edit this form.',
                timer: 3000
            })
        } else {
            callback()
        }
    }

    return (
        <div className=' px-5 mt-8 mb-5'>
            {
                profile?.permissions !== undefined ?
                    <>
                        {
                            checkPermission(profile?.permissions, 'create form') &&
                            <div className=' flex justify-end mb-6'>
                                <ButtonPill label="Add New Form" className="px-10 h-10" onClick={() => setModal("event-form")} />
                            </div>

                        }
                    </>
                    :
                    <></>
            }
            {
                eventFormQuery.status === "loading" ?
                    <Loading />
                    : eventFormQuery.status === "success" ?
                        <>
                            {
                                listForm.length > 0 ?
                                    <>
                                        <div className=' grid grid-cols-3 gap-[16px]'>
                                            {
                                                listForm.map((form) => {
                                                    return <div className=' relative' key={form.id} >
                                                        <Link to={MainsStack.eventQuestions.replace(":event_id", event_id).replace(":form_id", form.id)} className={`border ${form.is_active === 1 ? 'border-default bg-appbgform' : 'border-moregray bg-white'} rounded-[15px] px-4 py-6 flex flex-col gap-4 h-[125px]`}>
                                                            <p className=' line-clamp-2 text-base font-semibold tracking-[0.48px]'>{form.title}</p>
                                                        </Link>
                                                        <div className=' flex justify-end gap-2 absolute right-4 bottom-4'>
                                                            {
                                                                profile?.permissions !== undefined ?
                                                                    <>
                                                                        {
                                                                            checkPermission(profile?.permissions, 'delete form') ?
                                                                                <button className=' outline-none bg-default w-6 h-6 flex justify-center items-center rounded-lg' onClick={() => checkIsActiveBeforAction(form, () => {
                                                                                    handleDelete(form)
                                                                                })}>
                                                                                    <Trash className="w-4 h-4 fill-white" />
                                                                                </button>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            checkPermission(profile?.permissions, 'update form') ?
                                                                                <button className=' outline-none bg-default w-6 h-6 flex justify-center items-center rounded-lg' onClick={() => checkIsActiveBeforAction(form, () => {
                                                                                    setFormValue({ id: form.id, title: form.title })
                                                                                    setDialog("event-form")
                                                                                })}>
                                                                                    <Pencil className="w-4 h-4 fill-white" />
                                                                                </button>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            checkPermission(profile?.permissions, 'activated form') ?
                                                                                <input type="checkbox" className='form-checkbox h-6 w-6 checked:before:!text-sm !mr-0n checked:before:!pt-0' checked={form.is_active === 1} onChange={(e) => {
                                                                                    activeFormMutation.mutate({ form_event_id: form.id })
                                                                                    // setListForm(p => p.map((listF) => listF.id === form.id ? ({ ...listF, is_active: e.target.checked === true ? 1 : 1 }) : ({ ...listF, is_active: 0 })))
                                                                                }} />
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                        </div>

                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                                    :

                                    <img
                                        className='w-2/6 mx-auto'
                                        src={ilustration}
                                        alt="ilustration"
                                    />
                            }
                        </>
                        : <>Empty</>

            }
            <DialogForm
                event_id={event_id}
                formValue={formValue}
                setFormValue={setFormValue}
                show={modal === "event-form"}
                onClose={() => {
                    setModal(undefined)
                    setFormValue({ id: '', title: '' })
                }}
            />

        </div>
    )
}
