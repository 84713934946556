import { Modal } from "flowbite-react";
import MemberForm from "components/form/MemberForm";
import { useEvent } from "context/EventContext";
import { useMember } from "context/MemberContext";
import { useContext } from "react";
import ModalContext from "context/ModalContext";
import NewMemberContext from "context/NewMemberContext";

function ModalMember({ action, type, choosedModal, getMembersQuery }) {
    const { modal, setModal } = useContext(ModalContext);
    const { cleanUpMemberForm } = useContext(NewMemberContext);

    return (
        <>
            <Modal
                position={'top-center'}
                show={modal === choosedModal}
                onClose={() => {
                    setModal(undefined);
                    cleanUpMemberForm();
                }}
                size={'lg'}
            >
                <div className="border-b-[1px] border-appgray">
                    <div className="px-6 py-5 flex justify-between items-center">
                        <h1 className="text-appblack text-xl font-semibold text-center" style={{ width: '-webkit-fill-available' }}>{type === 'add' ? 'Add Member' : 'Edit Member'}</h1>
                        <button className=" text-4xl border-[1px] border-moregray w-[30px] h-[30px] flex items-center justify-center text-moregray rounded-full" onClick={() => setModal(undefined)}>
                            <p className="-mt-[4px]">
                                &times;
                            </p>
                        </button>
                    </div>
                </div>
                <Modal.Body>
                    <MemberForm
                        action={action}
                        memberType={type}
                        getMembersQuery={getMembersQuery}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center w-full space-x-3">
                        <button className="btn w-24" onClick={() => document.getElementById('btn_add_member').click()}>Submit</button>
                        <button
                            onClick={() => {
                                setModal(undefined);
                                cleanUpMemberForm();
                            }}
                            className="btn-cancel w-24"
                        >Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalMember;