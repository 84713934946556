import { useContext, useEffect, useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Dropdown } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import InventoryContext from "context/InventoryContext";
import TableComponent from "components/Table";
import ModalContext from "context/ModalContext";
import ModalBanner from "components/Modal/ModalBanner";
import InputWithLabel from "components/atoms/InputWithLabel";
import Popup from "components/Modal/Popup";
import { MONTHS } from "helpers/Month";
import { toast } from "react-toastify";
import Trash from "components/icons/Trash";
import PurchaseContext from "context/PurchaseContext";
import { redirect, useNavigate, useParams } from "react-router-dom";
import TextAreaWithLabel from "components/atoms/TextAreaWithLabel";
import FormSearchProduct from "components/form/FormSearchProduct";
import Cart from "components/icons/Cart";
import ButtonForProduct from "components/atoms/ButtonForProduct";
import Minus from "components/icons/Minus";
import Plus from "components/icons/Plus";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";
import Checklist from "components/icons/Checklist";
import ilustration from '../assets/images/event-ilustration.svg';
import { formatterCurrency } from "utils/normalize";

function CreatePurchase() {
    const { setModal, setPopup } = useContext(ModalContext);
    const formDetailInit = {
        invoiceNumber: '',
        errorInvoiceNumber: '',
        suplierName: '',
        errorSuplierName: '',
        suplierPhone: '',
        errorSuplierPhone: '',
        date: '',
        errorDate: '',
        notes: '',
    }
    const LIST_PAGE = ['detail', 'products', 'summary'];
    const [formDetailCustomer, setFormDetailCustomer] = useState(formDetailInit);
    const [activePage, setActivePage] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [canAccessSummary, setCanAccessSummary] = useState(true);
    const navigate = useNavigate();

    const handleProceedPurchase = async () => {
        const FD = new FormData();

        FD.append('invoice_date', formDetailCustomer.date);
        FD.append('invoice_number', formDetailCustomer.invoiceNumber);
        FD.append('supplier_name', formDetailCustomer.suplierName);
        FD.append('supplier_phone', formDetailCustomer.suplierPhone);
        FD.append('note', formDetailCustomer.notes);

        selectedProduct.map((item, index) => {
            FD.append(`inventories[${index}][id]`, item.id);
            FD.append(`inventories[${index}][price]`, item.price);
            FD.append(`inventories[${index}][qty]`, item.count);
        })

        return API.post(`purchase`, FD).then(ress => {
            if (ress.status === 201) {
                return navigate(-1);
            }
        }).catch(err => handleError(err.response));
    }

    const handleChangeQuantity = (e, data) => {
        setSelectedProduct(currData => {
            return currData.map(prod => {
                if (prod.id === data.id) {
                    prod.count = parseInt(e.target.value);
                }

                return prod;
            })
        })
    }

    return (
        <>
            <main className="px-5 pb-5">
                <div className="app-shadow card">
                    <div className="card-header">Create Stock In</div>
                    <div className="card-body">
                        <div className="p-5 px-20 py-10">
                            <div className="mx-4">
                                <div className="flex items-center justify-between">
                                    {
                                        LIST_PAGE.map((page, index) => {
                                            if (index === activePage) {
                                                return (
                                                    <>
                                                        <div className="flex items-center text-white relative">
                                                            <div className=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-default bg-default">
                                                                {index + 1}
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-default ">{page}</div>
                                                        </div>
                                                        {
                                                            index !== 2 &&
                                                            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-moregray"></div>
                                                        }
                                                    </>
                                                )
                                            } else if (index < activePage) {
                                                return (
                                                    <>
                                                        <div className="flex items-center text-white relative">
                                                            <div className=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-app-green bg-badge-success">
                                                                <Checklist width={20} height={20} color={'#10B986'} />
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-app-green ">{page}</div>
                                                        </div>
                                                        {
                                                            index !== 2 &&
                                                            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-app-green"></div>
                                                        }
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        <div className="flex items-center text-white relative">
                                                            <div className=" flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-moregray text-moregray">
                                                                {index + 1}
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-moregray ">{page}</div>
                                                        </div>
                                                        {
                                                            index !== 2 &&
                                                            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-moregray"></div>
                                                        }
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="mt-8 p-4">
                                <div className={`${LIST_PAGE[activePage] === 'detail' ? `block` : `hidden`}`}>
                                    <InputWithLabel
                                        classInput={`${formDetailCustomer.errorInvoiceNumber !== '' ? `!ring-app-red bg-badge-red` : ``}`}
                                        label={'Invoice Number'}
                                        value={formDetailCustomer.invoiceNumber}
                                        onChange={e => setFormDetailCustomer(oldValue => {
                                            return { ...oldValue, invoiceNumber: e.target.value }
                                        })}
                                        type="tetx"
                                    />
                                    <p className="mb-3 text-app-red">{formDetailCustomer.errorInvoiceNumber !== '' ? formDetailCustomer.errorInvoiceNumber
                                        : ''}</p>
                                    <InputWithLabel
                                        classInput={`${formDetailCustomer.errorSuplierName !== '' ? `!ring-app-red bg-badge-red` : ``}`}
                                        label={'Supplier Name'}
                                        value={formDetailCustomer.suplierName}
                                        onChange={e => setFormDetailCustomer(oldValue => {
                                            return { ...oldValue, suplierName: e.target.value }
                                        })}
                                        type="text"
                                    />
                                    <p className="mb-3 text-app-red">{formDetailCustomer.errorSuplierName !== '' ? formDetailCustomer.errorSuplierName
                                        : ''}</p>
                                    <InputWithLabel
                                        classInput={`${formDetailCustomer.errorSuplierPhone !== '' ? `!ring-app-red bg-badge-red` : ``}`}
                                        label={'Supplier Phone'}
                                        value={formDetailCustomer.suplierPhone}
                                        onChange={e => setFormDetailCustomer(oldValue => {
                                            return { ...oldValue, suplierPhone: e.target.value.replace(/[^\d]/g, '') }
                                        })}
                                        type="text"
                                    />
                                    <p className="mb-3 text-app-red">{formDetailCustomer.errorSuplierPhone !== '' ? formDetailCustomer.errorSuplierPhone
                                        : ''}</p>
                                    <InputWithLabel
                                        classInput={`${formDetailCustomer.errorDate !== '' ? `!ring-app-red bg-badge-red` : ``}`}
                                        label={'Date'}
                                        value={formDetailCustomer.date}
                                        onChange={e => setFormDetailCustomer(oldValue => {
                                            return { ...oldValue, date: e.target.value }
                                        })}
                                        type="date"
                                    />
                                    <p className="mb-3 text-app-red">{formDetailCustomer.errorDate !== '' ? formDetailCustomer.errorDate
                                        : ''}</p>
                                    <TextAreaWithLabel
                                        classInput={'!mb-3'}
                                        label={'Notes'}
                                        value={formDetailCustomer.notes}
                                        onChange={e => setFormDetailCustomer(oldValue => {
                                            return { ...oldValue, notes: e.target.value }
                                        })}
                                    />
                                    <div className="flex justify-end mt-5">
                                        <button className={`btn btn-default`} onClick={() => {
                                            if (formDetailCustomer.invoiceNumber === '' || formDetailCustomer.suplierName === '' || formDetailCustomer.suplierPhone === '' || formDetailCustomer.date === '') {
                                                if (formDetailCustomer.invoiceNumber === '') {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorInvoiceNumber: 'Invoice number is required' }
                                                    })
                                                } else {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorInvoiceNumber: '' }
                                                    })
                                                }

                                                if (formDetailCustomer.suplierName === '') {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorSuplierName: 'Supplier name is required' }
                                                    })
                                                } else {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorSuplierName: '' }
                                                    })
                                                }

                                                if (formDetailCustomer.suplierPhone === '') {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorSuplierPhone: 'Supplier phone is required' }
                                                    })
                                                } else {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorSuplierPhone: '' }
                                                    })
                                                }

                                                if (formDetailCustomer.date === '') {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorDate: 'Date is required' }
                                                    })
                                                } else {
                                                    setFormDetailCustomer(currVal => {
                                                        return { ...currVal, errorDate: '' }
                                                    })
                                                }

                                                return false;
                                            } else {
                                                setActivePage(1)
                                            }
                                        }}>Next</button>
                                    </div>
                                </div>
                                <div className={`py-5 ${LIST_PAGE[activePage] === 'products' ? `block` : `hidden`}`}>
                                    <div className="flex justify-end">
                                        <button className="btn btn-default" onClick={() => setModal('modal_search_product')}>Search Product</button>
                                    </div>
                                    {
                                        selectedProduct.length > 0 ?
                                            <ul className="mt-5 py-3 space-y-3">
                                                {
                                                    selectedProduct.map(item => {
                                                        return (
                                                            <li>
                                                                <div className="card app-shadow flex justify-between px-5 items-center">
                                                                    <div className="w-5/6">
                                                                        <h1 className="font-semibold mb-2">{item.name}</h1>
                                                                        <div className="">
                                                                            <div className="w-[60px]">Price </div>
                                                                            <div className={`form-control !h-[44px] ${item?.error && item.error !== '' ? `!border-app-red` : `!border-default`}`}>
                                                                                <input type="text" className="ml-2 w-5/6 px-0 ring-0 border-0 focus:outline-none focus:ring-0 focus:border-0" value={formatterCurrency(item.price)} onChange={(e) => {
                                                                                    setSelectedProduct(currData => {
                                                                                        return currData.map(product => {
                                                                                            if (product.id === item.id) {
                                                                                                item.price = e.target.value.replace(/[^\d]/g, '')
                                                                                            }

                                                                                            return product;
                                                                                        })
                                                                                    })
                                                                                }} />
                                                                            </div>
                                                                            <div className="flex items-center space-x-2 mt-8">
                                                                                <button
                                                                                    onClick={() => setSelectedProduct(
                                                                                        currProd => {
                                                                                            return currProd.map(prod => {
                                                                                                if (prod.id === item.id) {
                                                                                                    if (prod.count <= 1) {
                                                                                                        toast.info('Minimum quantity added is 1');
                                                                                                        return prod;
                                                                                                    }
                                                                                                    prod.count -= 1;
                                                                                                }

                                                                                                return prod;
                                                                                            })
                                                                                        }
                                                                                    )}
                                                                                >
                                                                                    <Minus color={'#F77C32'} height={50} width={10} /></button>
                                                                                <input type="number" style={{ width: item.count.toString().length * 8 + 50 }} className="rounded-full px-3 text-center !ring-2 h-9 text-default valid-form ring-0 border-0 " onChange={e => handleChangeQuantity(e, item)} value={item.count} min={1} max={item.stock} />
                                                                                <button
                                                                                    onClick={() => setSelectedProduct(
                                                                                        currProd => {
                                                                                            return currProd.map(prod => {
                                                                                                if (prod.id === item.id) {
                                                                                                    prod.count += 1;
                                                                                                }

                                                                                                return prod;
                                                                                            })
                                                                                        }
                                                                                    )}
                                                                                >
                                                                                    <Plus color={'#F77C32'} width={100} height={100} />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button onClick={() => setSelectedProduct(currData => {
                                                                        return currData.filter(product => item.id !== product.id)
                                                                    })}>
                                                                        <Trash width={20} height={20} />
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            :
                                            <div className="flex justify-center items-center">
                                                <img src={ilustration} height={300} width={300} />
                                            </div>
                                    }
                                    <div className="flex justify-end mt-5 space-x-3">
                                        <button className="btn btn-cancel" onClick={() => setActivePage(0)}>Prev</button>
                                        <button className="btn btn-default" onClick={async () => {
                                            // const waitingCheckError = await checkErrorOnSelectedProduct();
                                            // if (waitingCheckError) {
                                            if (selectedProduct.length > 0) {
                                                if (!selectedProduct.some(item => item.hasOwnProperty('error'))) {
                                                    setActivePage(2)
                                                    let priceTotal = 0;
                                                    let itemTotal = 0;
                                                    selectedProduct.map(prod => {
                                                        itemTotal += prod.count;
                                                        setTotalItems(itemTotal);

                                                        priceTotal += parseInt(prod.price) * prod.count;

                                                        setTotalPrice(priceTotal);
                                                    })
                                                } else {
                                                    return;
                                                }
                                            } else {
                                                toast.info('Please select product first');
                                                return;
                                            }
                                            // }
                                        }}>Next</button>
                                    </div>
                                </div>
                                <div className={`${LIST_PAGE[activePage] === 'summary' ? `block` : `hidden`}`}>
                                    <div className="space-y-5">
                                        <div className="app-shadow overflow-hidden">
                                            <h1 className="bg-default py-3 px-5 text-white font-semibold">Supplier Information</h1>
                                            <div className="px-5 py-3">
                                                <ul>
                                                    <li className="capitalize">Supplier Name : <strong>{formDetailCustomer.suplierName}</strong></li>
                                                    <li>Supplier Phone : <strong>{formDetailCustomer.suplierPhone}</strong></li>
                                                    <li>Invoice Number : <strong>{formDetailCustomer.invoiceNumber}</strong></li>
                                                    <li>Invoice Date : <strong>{formDetailCustomer.date}</strong> </li>
                                                    <li>Note : <strong>{formDetailCustomer.notes}</strong></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="app-shadow overflow-hidden">
                                            <h1 className="bg-default py-3 px-5 text-white font-semibold">Product List</h1>
                                            <ul className="px-5 py-3 space-y-3">
                                                {
                                                    selectedProduct.map(item => {
                                                        return (
                                                            <li className="">
                                                                <div className="card app-shadow flex justify-between px-5 items-center">
                                                                    <div>
                                                                        <h1 className="font-medium">{item.name}</h1>
                                                                        <p>Price : {formatterCurrency(item.price)}</p>
                                                                        <p>Total Items: {item.count > 1 ? item.count + ' Pcs' : item.count + ' Pc'}</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="app-shadow overflow-hidden">
                                            <h1 className="bg-default py-3 px-5 text-white font-semibold">Grand Total</h1>
                                            <div className="px-5 py-3">
                                                <ul>
                                                    <li>Total Item : <strong>{totalItems}</strong>
                                                    </li>
                                                    <li>Grand Total : <strong>{formatterCurrency(totalPrice)}</strong></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="flex justify-end mt-5 space-x-3">
                                            <button className="btn btn-cancel" onClick={() => setActivePage(1)}>Prev</button>
                                            <button className="btn btn-default" onClick={() => setPopup('create_purchase')}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ModalBanner
                choosedModal={'modal_search_product'}
                title={'Search Product'}
                textButton={null}
                actionClearForm={() => { }}
                idBtnSubmit={'btn_search_product'}
            >
                <FormSearchProduct
                    setSelectedProduct={setSelectedProduct}
                />
            </ModalBanner>
            <Popup
                action={handleProceedPurchase}
                icon={null}
                type="create_purchase"
                textBtn="Proceed"
            >
                Are you sure want to proceed this stock in?
            </Popup>
        </>
    );
}

export default CreatePurchase;