import { NavLink, useParams } from "react-router-dom";
import NavigationItem from "./atoms/NavigationItem";
import Booth from "./icons/Booth";
import Complain from "./icons/Complain";
import Equipment from "./icons/Equipment";
import Home from "./icons/Home";
import Invoice from "./icons/Invoice";
import Logo from "./icons/Logo";
import Members from "./icons/Members";
import Purchase from "./icons/Purchase";
import Setting from "./icons/Setting";
import Vendor from "./icons/Vendors";
import Vouchers from "./icons/Vouchers";
import Questios from "./icons/Questions";
import { MainsStack } from "routes";
import { useState } from "react";
import Angle from "./icons/Angle";
import { Sidebar } from "flowbite-react";

function SideBar() {
    const [openMenu, setOpenMenu] = useState(false)
    const params = useParams();
    return (
        <div className="h-screen pl-5 py-5 min-w-[280px] fixed z-[50] overflow-scroll hide-scrollbar">
            <div className=" app-shadow px-7 py-3">
                <NavLink to={`/admin`} className={`bg-transparent`}>
                    <Logo />
                </NavLink>
                <div className=" mt-10 relative">
                    {/* <ul>
                        <li className={`py-3 ${openMenu ? `max-h-auto` : `max-h-[30px]`}`}>
                            <div className="flex justify-between items-center cursor-pointer" onClick={() => setOpenMenu(curVal => !curVal)}>
                                <div className="font-medium flex items-center cursor-pointer">
                                    <Questios />
                                    <h1 className="ml-6 ">Forms</h1>
                                </div>
                                <div className={`${openMenu ? `rotate-180 transition-all duration-300` : `transition-all duration-300 rotate-0`}`}>
                                    <svg className={`!fill-white w-4 h-4`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <ul>
                                    <li>list 1</li>
                                    <li>list 2</li>
                                    <li>list 3</li>
                                </ul>
                            </div>
                        </li>
                        <li className="py-3">
                            <div className="flex justify-between items-center cursor-pointer" >
                                <div className="font-medium flex items-center cursor-pointer">
                                    <Questios />
                                    <h1 className="ml-6 ">Forms</h1>
                                </div>
                                <div>
                                    <svg className={`!fill-white w-4 h-4`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                            </div>
                        </li>
                        <li className="py-3">
                            <div className="flex justify-between items-center cursor-pointer" >
                                <div className="font-medium flex items-center cursor-pointer">
                                    <Questios />
                                    <h1 className="ml-6 ">Forms</h1>
                                </div>
                                <div>
                                    <svg className={`!fill-white w-4 h-4`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                            </div>
                        </li>
                    </ul> */}
                    <ul>
                        <NavLink
                            to={`/admin/event/${params.event_id}/home`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Home />}
                                title="Home"
                            />
                        </NavLink>
                        <NavLink
                            to={MainsStack.eventForm.replace(':event_id', params.event_id)}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Questios />}
                                title="Form Question"
                            />
                        </NavLink>
                        <NavLink
                            to={MainsStack.formSubmitted.replace(':event_id', params.event_id)}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Questios />}
                                title="Form Submitted"
                            />
                        </NavLink>
                        {/* <NavLink
                            to={MainsStack.eventForm.replace(':event_id', params.event_id)}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Questios />}
                                title="Questions"
                            />
                        </NavLink> */}
                        {/* <li className={`mx-5 my-4 cursor-pointer relative `}>
                            <div className="flex items-center justify-between" onClick={() => { setOpenMenu(cur => !cur) }}>
                                <div className="font-medium flex items-center cursor-pointer">
                                    <Questios />
                                    <h1 className="ml-6 ">Forms</h1>
                                </div>
                                <div className={`${openMenu ? `rotate-180 transition-all duration-300` : `transition-all duration-300 rotate-0`}`}>
                                    <svg className={`!fill-white w-4 h-4`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                            </div>
                            <div className={`space-y-3 flex flex-col ml-10 -z-20 ${openMenu ? `transition-all duration-200 max-h-[500px]` : `hidden transition-all duration-200 max-h-[0px] opacity-100`}`}>
                                <NavLink
                                    to={MainsStack.eventForm.replace(':event_id', params.event_id)}
                                    className={`mt-2 hover:bg-zinc-100 hover:rounded-xl p-2`}
                                >
                                    Questions
                                </NavLink>
                                <NavLink
                                    to={MainsStack.formSubmitted.replace(':event_id', params.event_id)}
                                    className={' hover:bg-zinc-100 hover:rounded-xl p-2'
                                    }
                                >
                                    Submitted
                                </NavLink>
                                <NavLink
                                    to={MainsStack.formEquipment.replace(':event_id', params.event_id)}
                                    className={`hover:bg-zinc-100 hover:rounded-xl p-2`}
                                >
                                    Equipment
                                </NavLink>
                            </div>
                        </li> */}
                        <NavLink
                            to={`/admin/event/${params.event_id}/booth`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Booth />}
                                title="Booth"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/vendors`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Vendor />}
                                title="Tenants"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/inventory`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Equipment />}
                                title="Inventory"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/purchase`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Purchase />}
                                title="Stock In"
                            />
                        </NavLink>
                        <NavLink
                            to={MainsStack.eventPurchaseOut.replace(':event_id', params.event_id)}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Purchase />}
                                title="Stock Out"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/invoice`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Invoice />}
                                title="Invoice"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/members`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Members />}
                                title="Members"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/complain`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Complain />}
                                title="Complain"
                            />
                        </NavLink>
                        <NavLink
                            to={`/admin/event/${params.event_id}/settings`}
                            className={({ isActive }) =>
                                isActive ? 'active' : 'text-appblack hover:block hover:bg-zinc-100 hover:rounded-xl'
                            }
                        >
                            <NavigationItem
                                icon={<Setting />}
                                title="Settings"
                            />
                        </NavLink>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SideBar;