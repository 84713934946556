import SelectInputLabel from "components/atoms/SelectInputLabel";
import ModalContext from "context/ModalContext";
import NewMemberContext from "context/NewMemberContext";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function InviteOrKickMember() {
    const { members, getMembers, setMemberId, memberId, assignMember, membersEvent, getNonMemberEvent, nonMember } = useContext(NewMemberContext);
    const { setModal } = useContext(ModalContext);
    const params = useParams();

    useEffect(() => {
        getNonMemberEvent(params.event_id)
    }, []);

    const handleInviteMember = async () => {
        const FD = new FormData();

        FD.append('user_id', memberId)
        FD.append('event_id', params.event_id);

        const reqAssignMember = await assignMember(FD);

        if (reqAssignMember === 'OK') {
            setModal(undefined);
        }
    }

    const changeDataMembers = nonMember.map(member => {
        member.label = member.email;
        member.value = member.id;
        return member;
    })

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleInviteMember()
        }}>
            <SelectInputLabel
                label={'Member'}
                options={changeDataMembers}
                classInput={` !text-base !font-medium capitalize !tracking-[0.48px] !p-0 !px-5 valid:!empty-form`}
                onChange={(e) => setMemberId(e.target.value)}
                required={true}
            />
            <button type="submit" hidden id="invite_member"></button>
        </form>
    );
}

export default InviteOrKickMember;