import { useMutation, useQueryClient } from '@tanstack/react-query'
import Image from 'components/atoms/Image'
import IconCamera from 'components/icons/IconCamera'
import { handleError } from 'helpers/HandleRequestApi'
import { memo, useRef } from 'react'
import { updateProfileImageService } from 'services/user'

export default memo(function Sidebar({ profile }) {
    const refInput = useRef(null)
    const queryClient = useQueryClient()
    const updateMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            formData.append('image', refInput.current.files[0])
            const response = await updateProfileImageService(formData)
            return response.data
        },
        onSuccess(data) {
            queryClient.invalidateQueries(['profile'])
        },
        onError({ response }) {
            handleError(response)
        }
    })
    return (
        <div className=' col-span-3'>
            <div className=' w-full bg-white rounded-[20px] pt-10 pb-[170px] px-5 flex flex-col items-center app-shadow2'>
                <div className=' w-[120px] h-[120px] relative mb-6'>
                    <Image path={profile.img_url} className=' w-full h-full object-cover rounded-full' />
                    <div className=' absolute -bottom-[7px] -right-[1px]'>
                        <button
                            disabled={updateMutation.isLoading}
                            onClick={() => {
                                if (refInput) {
                                    refInput.current?.click()
                                    refInput.current.value = "";
                                }
                            }}><IconCamera /></button>
                        <form className=' hidden'>
                            <input ref={refInput} type='file' onChange={(e) => {
                                if (e.target?.files[0] !== undefined && e.target.files.length > 0) {
                                    updateMutation.mutate()
                                }
                            }} />
                        </form>
                    </div>
                </div>

                <p className=' text-center font-semibold text-2xl capitalize'>{profile.fullname}</p>
                <p className=' text-base mt-1'>{profile.email}</p>
            </div>
        </div>
    )
})