function Members() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
                <path d="M1.3 15C1.3 15 0 15 0 13.75C0 12.5 1.3 8.75 6.5 8.75C11.7 8.75 13 12.5 13 13.75C13 15 11.7 15 11.7 15H1.3ZM6.5 7.5C7.53434 7.5 8.52633 7.10491 9.25772 6.40165C9.98911 5.69839 10.4 4.74456 10.4 3.75C10.4 2.75544 9.98911 1.80161 9.25772 1.09835C8.52633 0.395088 7.53434 0 6.5 0C5.46566 0 4.47367 0.395088 3.74228 1.09835C3.01089 1.80161 2.6 2.75544 2.6 3.75C2.6 4.74456 3.01089 5.69839 3.74228 6.40165C4.47367 7.10491 5.46566 7.5 6.5 7.5Z" />
            </svg>
        </>
    );
}

export default Members;