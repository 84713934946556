import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuthStore } from 'store/auth';
import { shallow } from 'zustand/shallow';
import ProfileImage from './ProfileImage';
import ArrowDown from './icons/ArrowDown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LeftAngle from './icons/LeftAngle';
import { MainsStack } from 'routes';
import { useQuery } from '@tanstack/react-query';
import API from 'utils/api';
import Loading from './Loading';
import IconNotif from '../assets/images/bell.svg';
import Logo2 from './icons/Logo2';
import Ilustration from "../assets/images/event-ilustration.svg";
import Notif from './icons/Notif';
import Xicon from './icons/Xicon';
import { useInView } from 'react-intersection-observer';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { checkPermission } from 'utils/checkPermission';

function Header({ showGoback, className, eventName = null }) {
    const [notif, setNotif] = useState([]);
    const [nextUrl, setNextUrl] = useState("");
    const [page, setPage] = useState(1);
    const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false);
    const { logout, profile } = useAuthStore((state) => ({ logout: state.logout, profile: state.profile }), shallow)
    const notificationView = useRef(null);

    const { ref, inView } = useInView();

    const handleOpenDropdownMenu = () => {
        setDropdownMenuOpen(
            !isDropdownMenuOpen
        );
    }

    const getNotification = useQuery({
        queryKey: ['get_notification'],
        queryFn: () => {
            return API.get(`notification?page=${page}`)
        },
        select: data => {
            return data.data;
        },
        enabled: false,
    });

    const closeAndOpenMenu = useCallback(handleOpenDropdownMenu, [isDropdownMenuOpen]);

    const clases = isDropdownMenuOpen ? ` ease-in-out dropdown-open top-[9.5px]` : ' ease-in-out dropdown-close';
    const navigate = useNavigate()

    // useEffect(() => {
    //     if ('Notification' in window && 'serviceWorker' in navigator) {
    //         const beamsClient = new PusherPushNotifications.Client({
    //             instanceId: process.env.REACT_APP_PUSHER_INSTANCE_ID,
    //         });

    // beamsClient.start()
    //     .then(() => beamsClient.addDeviceInterest(`user-${profile.id}`))
    //     .then(() => {
    //         console.log('Successfully')
    //         console.log(beamsClient);
    //     })
    //     .catch(err => {
    //         console.log('Error: ' + err)
    //         console.log(beamsClient);
    //     });
    //     }
    // }, [])

    useEffect(() => {
        if (getNotification.data !== undefined) {
            setNotif(currData => {
                return [...currData, ...getNotification.data?.data?.items]
            })
            // setPage(purchaseOutQuery.data?.meta.total_page);
            if (getNotification.data?.links.next !== "") {
                setNextUrl(getNotification.data?.links.next)
                setPage(getNotification.data?.links.next.slice(
                    getNotification.data?.meta.current_page.indexOf('page=') + 5
                ))
            }
        }
    }, [getNotification.data])

    useEffect(() => {
        if (inView && nextUrl !== "") {
            getNotification.refetch();
        }
    }, [inView])

    return (
        <>
            <section className={`sticky top-0 bg-background flex justify-between items-center max-h-28 z-50 ${className ?? ''}`} >
                <div className='flex items-center gap-[35px]'>
                    {
                        showGoback && <button onClick={() => navigate(-1)}>
                            <LeftAngle className={"bg-white !ring-0 app-shadow2 w-[57px] h-[57px]"} />
                        </button>
                    }

                    <div className=' flex items-center'>
                        <ProfileImage
                            img={profile.img_url}
                            width={60}
                            height={60}
                        />
                        <div className='ml-3'>
                            <p className='font-semibold'>Hello, <span className='font-bold capitalize'>{profile.fullname}</span></p>
                            {
                                eventName !== null ?
                                    <p>Welcome to <strong>{eventName}</strong></p>
                                    :
                                    <p>Welcome and have a nice day</p>
                            }
                        </div>
                    </div>
                </div>
                <div className='flex'>
                    <div className='relative flex items-center pr-2'>
                        <div className={`mr-3 cursor-pointer ${clases}`} onClick={closeAndOpenMenu}>
                            <div className='flex justify-between items-center rounded-full px-3 py-2 relative'>
                                <div className='flex items-center' style={{ width: '-webkit-fill-available' }}>
                                    <ProfileImage
                                        img={profile.img_url}
                                        width={40}
                                        height={40}
                                    />
                                    {
                                        profile?.fullname !== undefined ?
                                            <p className='ml-3 capitalize'>{profile?.fullname.length > 15 ? profile?.fullname.substring(0, 14) + '...' : profile?.fullname} </p>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className={`${isDropdownMenuOpen ? `rotate-180 transition-all duration-300` : `transition-all duration-300 rotate-0`}`}>
                                    <svg className={`!fill-white w-6 h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>

                                {/* <ArrowDown
                                    action={closeAndOpenMenu}
                                /> */}
                                {/* <ArrowDown
                                /> */}
                            </div>
                            <ul className='p-3'>
                                <Link to={MainsStack.profile}>
                                    <li className='cursor-pointer'>
                                        Profile
                                    </li>
                                </Link>
                                <Link to={MainsStack.history}>
                                    <li className='cursor-pointer'>History</li>
                                </Link>
                                {
                                    (checkPermission(profile?.permissions, 'edit global privacy policy') ||
                                        checkPermission(profile?.permissions, 'edit global tnc')) &&
                                    <Link to={MainsStack.settings}>
                                        <li className='cursor-pointer'>Settings</li>
                                    </Link>
                                }

                                {
                                    eventName !== null ?
                                        <Link to={MainsStack.admin}> <li className='cursor-pointer'>Global Event</li></Link>
                                        :
                                        <Link to={MainsStack.adminCustomNotification}>
                                            <li className='cursor-pointer'>Custom Notification</li>
                                        </Link>

                                }
                                <li className='cursor-pointer sign-out' onClick={() => {
                                    // if ('Notification' in window && 'serviceWorker' in navigator) {
                                    //     const beamsClient = new PusherPushNotifications.Client({
                                    //         instanceId: process.env.REACT_APP_PUSHER_INSTANCE_ID,
                                    //     });

                                    //     beamsClient.removeDeviceInterest(`user-${profile.id}`);
                                    // }

                                    logout();
                                }}>
                                    <button>Sign Out</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button onClick={() => {
                        getNotification.refetch()
                        notificationView.current.classList.remove('max-w-0');
                        notificationView.current.classList.add('max-w-[400px]');
                    }}>
                        <div className=' bg-white w-[57px] h-[57px] rounded-full app-shadow flex items-center justify-center'>
                            {/* <img src={IconNotif} width={25} height={25} /> */}
                            <Notif />
                        </div>
                    </button>
                </div>
            </section>
            <div
                ref={notificationView}
                className='fixed !rounded-none right-0 top-0 app-shadow h-screen bg-white z-[100] transition-[max-width] duration-1000 ease-in-out max-w-0 overflow-scroll'
            >
                <div className='w-[300px]'>
                    {
                        getNotification.isLoading &&
                        <Loading />
                    }
                    {
                        getNotification.isSuccess &&
                        <ul className='px-6 py-5 space-y-2 divide-y divide-slate-200 overflow-y-scroll z-[200]'>
                            <li className='py-5 px-3'>
                                <button className='flex items-center' onClick={() => {
                                    notificationView.current.classList.remove('max-w-[400px]');
                                    notificationView.current.classList.add('max-w-0');
                                }}>
                                    <Xicon color={'#000'} width={20} height={20} />
                                    <p className='text-xl ml-3'>List Notification</p>
                                </button>
                            </li>
                            {
                                notif.length > 0 ?
                                    <>
                                        {
                                            notif.map(item => {
                                                return (
                                                    <li key={item.id} className='flex items-center cursor-pointer'>
                                                        <div className='min-w-[30px] mr-2'>
                                                            <Logo2 />
                                                        </div>
                                                        <div className='ml-2'>
                                                            <h1 className='font-medium text-lg'>{item.title}</h1>
                                                            <h2 className='text-base text-moregray'>{item.body}</h2>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                        <div className="w-full flex justify-center items-center flex-col">
                                            <img src={Ilustration} width={250} />
                                        </div>
                                    </>
                            }
                            <button
                                ref={ref}
                            >

                            </button>
                        </ul>
                    }
                </div>
            </div>
        </>
    );
}

export default Header;