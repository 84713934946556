import React from 'react'

export default function Copy2() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
            <path d="M2.61548 2.5C2.61548 1.83696 2.89103 1.20107 3.38151 0.732233C3.87199 0.263392 4.53722 0 5.23086 0H9.92025C10.4403 0.00043763 10.9389 0.198269 11.3064 0.55L16.426 5.4425C16.7935 5.794 17 6.27057 17.0001 6.7675V15C17.0001 15.663 16.7245 16.2989 16.2341 16.7678C15.7436 17.2366 15.0784 17.5 14.3847 17.5H5.23086C4.53722 17.5 3.87199 17.2366 3.38151 16.7678C2.89103 16.2989 2.61548 15.663 2.61548 15V2.5ZM5.23086 1.25C4.88404 1.25 4.55143 1.3817 4.30619 1.61612C4.06095 1.85054 3.92317 2.16848 3.92317 2.5V15C3.92317 15.3315 4.06095 15.6495 4.30619 15.8839C4.55143 16.1183 4.88404 16.25 5.23086 16.25H14.3847C14.7315 16.25 15.0641 16.1183 15.3094 15.8839C15.5546 15.6495 15.6924 15.3315 15.6924 15V7.5H11.1155C10.5952 7.5 10.0963 7.30246 9.72846 6.95083C9.3606 6.59919 9.15394 6.12228 9.15394 5.625V1.25H5.23086ZM10.4616 1.50875V5.625C10.4616 5.79076 10.5305 5.94973 10.6531 6.06694C10.7758 6.18415 10.9421 6.25 11.1155 6.25H15.4217L10.4616 1.50875Z" fill="#F77C32" />
            <path d="M0 3.75C0 3.41848 0.137775 3.10054 0.383014 2.86612C0.628254 2.6317 0.960871 2.5 1.30769 2.5V15C1.30769 15.9946 1.72102 16.9484 2.45674 17.6517C3.19245 18.3549 4.1903 18.75 5.23077 18.75H14.3846C14.3846 19.0815 14.2468 19.3995 14.0016 19.6339C13.7564 19.8683 13.4237 20 13.0769 20H5.15231C3.78583 20 2.47532 19.4811 1.50908 18.5575C0.542831 17.6339 0 16.3812 0 15.075V3.75Z" fill="#F77C32" />
        </svg>
    )
}
