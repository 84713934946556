function ExlamationMark({ color, width, height }) {
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 14 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 53.5298C0.0511802 55.3987 0.810938 57.1735 2.11782 58.477C3.42469 59.7805 5.17567 60.51 6.99864 60.5103C8.82161 60.5107 10.5729 59.782 11.8803 58.479C13.1876 57.176 13.9481 55.4016 14 53.5327V7.51036C13.9485 5.64144 13.1883 3.8668 11.8812 2.56357C10.5741 1.26033 8.82297 0.53125 7 0.53125C5.17703 0.53125 3.42591 1.26033 2.11878 2.56357C0.811651 3.8668 0.0515459 5.64144 0 7.51036V53.5298ZM0 73.5974C0 77.546 3.15 80.7767 7 80.7767C10.85 80.7767 14 77.546 14 73.5974C14 69.6488 10.85 66.4181 7 66.4181C5.1453 66.4241 3.36826 67.1825 2.05678 68.5276C0.745302 69.8726 0.00590673 71.6952 0 73.5974Z" fill={color} />
            </svg>
        </>
    );
}

export default ExlamationMark;