import React from 'react'

export default function Trash2({ ...props }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0267 4.64151e-08C10.3998 8.70878e-05 10.7634 0.105828 11.0661 0.302246C11.3687 0.498664 11.595 0.7758 11.7129 1.0944L12.1956 2.4H15.1111C15.3469 2.4 15.573 2.48429 15.7397 2.63431C15.9064 2.78434 16 2.98783 16 3.2C16 3.41217 15.9064 3.61566 15.7397 3.76569C15.573 3.91571 15.3469 4 15.1111 4L15.1084 4.0568L14.3378 13.7712C14.2897 14.3765 13.9886 14.9429 13.4951 15.3564C13.0017 15.77 12.3525 15.9999 11.6782 16H4.32178C3.64752 15.9999 2.99832 15.77 2.50486 15.3564C2.0114 14.9429 1.71032 14.3765 1.66222 13.7712L0.891555 4.056C0.889947 4.03737 0.889058 4.01869 0.888889 4C0.653141 4 0.427048 3.91571 0.260349 3.76569C0.0936505 3.61566 0 3.41217 0 3.2C0 2.98783 0.0936505 2.78434 0.260349 2.63431C0.427048 2.48429 0.653141 2.4 0.888889 2.4H3.80444L4.28711 1.0944C4.40506 0.775671 4.6315 0.498442 4.93431 0.302012C5.23712 0.105583 5.60095 -8.0849e-05 5.97422 4.64151e-08H10.0267ZM5.33333 6.4C5.11561 6.40003 4.90548 6.47196 4.74278 6.60217C4.58008 6.73238 4.47614 6.9118 4.45067 7.1064L4.44444 7.2V12C4.4447 12.2039 4.53145 12.4 4.68698 12.5483C4.8425 12.6966 5.05507 12.7858 5.28124 12.7977C5.50741 12.8097 5.73011 12.7435 5.90385 12.6126C6.07759 12.4817 6.18924 12.2961 6.216 12.0936L6.22222 12V7.2C6.22222 6.98783 6.12857 6.78434 5.96187 6.63432C5.79517 6.48429 5.56908 6.4 5.33333 6.4ZM10.6667 6.4C10.4309 6.4 10.2048 6.48429 10.0381 6.63432C9.87143 6.78434 9.77778 6.98783 9.77778 7.2V12C9.77778 12.2122 9.87143 12.4157 10.0381 12.5657C10.2048 12.7157 10.4309 12.8 10.6667 12.8C10.9024 12.8 11.1285 12.7157 11.2952 12.5657C11.4619 12.4157 11.5556 12.2122 11.5556 12V7.2C11.5556 6.98783 11.4619 6.78434 11.2952 6.63432C11.1285 6.48429 10.9024 6.4 10.6667 6.4ZM10.0267 1.6H5.97333L5.67733 2.4H10.3227L10.0267 1.6Z" />
        </svg>
    )
}

Trash2.defaultProps = {
    className: 'fill-default'
}