import API from 'utils/api';

export function getQuestionFromService({ event_id }) {
    return API.get('/form/question/vendor/' + event_id);
}

export function setAnswerQuestionFromService({ data }) {
    return API.post('/form/answer', data);
}
export function pusherSubmitFormService() {
    return API.post('/form-registration-submit');
}

export async function getSubmittedFormService(eventId, page) {
    return API.get(`/user/form/list?event_id=${eventId}&page=${page}`).then(
        res => res.data
    );
}
