function NavigationItem({ icon, title }) {
    return (
        <>
            <li className="flex py-3 my-2 items-center px-5">
                <div className="w-8">
                    {icon}
                </div>
                <h1 className="ml-2 font-medium">{title}</h1>
            </li>
        </>
    );
}

export default NavigationItem;