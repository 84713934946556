import {
    GET_MEMBERS,
    GET_MEMBER_WITHOUT_PAGING,
    ADD_MEMBER,
    EDIT_MEMBER,
    DELETE_MEMBER,
    ACTIVATE_MEMBER,
    GET_MEMBER_BY_EVENT,
    ASSIGN_MEMBER_TO_EVENT,
    UNASSIGN_MEMBER_FROM_EVENT,
} from 'types/MemberTypes';

export default (state, { type, payload }) => {
    switch (type) {
        case GET_MEMBERS:
            return {
                ...state,
                loading: false,
                members: payload.data.items,
                totalPages: payload.meta.total_page,
                currentPage: payload.meta.current_page.slice(
                    payload.meta.current_page.indexOf('page=') + 5
                ),
            };
        case GET_MEMBER_BY_EVENT:
            return {
                ...state,
                loading: false,
                membersEvent: payload.data.items,
                totalPage: payload.meta.total_page,
                currentPage: payload.meta.current_page.slice(
                    payload.meta.current_page.indexOf('page=') + 5
                ),
            };
        case GET_MEMBER_WITHOUT_PAGING:
            return {
                ...state,
                loading: false,
                membersEvent: payload,
            };
        case ADD_MEMBER:
            return {
                ...state,
                members: [payload.data, ...state.members],
            };
        case EDIT_MEMBER:
            return {
                ...state,
                members: state.members.map(member => {
                    if (member.id === payload.data.id) {
                        return payload.data;
                    }

                    return member;
                }),
            };
        case DELETE_MEMBER:
            return {
                ...state,
                members: state.members.filter(member => member.id !== payload),
            };
        case ACTIVATE_MEMBER:
            return {
                ...state,
                members: state.members.map(member => {
                    if (member.id === payload.data.id) {
                        return payload.data;
                    }

                    return member;
                }),
            };
        case ASSIGN_MEMBER_TO_EVENT:
            return {
                ...state,
                membersEvent: [payload.data, ...state.membersEvent],
            };
        case UNASSIGN_MEMBER_FROM_EVENT:
            return {
                ...state,
                membersEvent: state.membersEvent.filter(
                    member => member.id !== payload
                ),
            };
    }
};
