import Input from "components/Input";
import InputPassword from "components/InputPassword";
import Popup from "components/Modal/Popup";
import ModalContext from "context/ModalContext";
import NewMemberContext from "context/NewMemberContext";
import VendorContext from "context/VendorContext";
import { useContext } from "react";
import { toast } from "react-toastify";
import { regexPassword } from 'utils/regex';

function MemberForm({ memberType, getMembersQuery }) {
    const { setModal, setPopup } = useContext(ModalContext);
    const { memberId, setMemberId, setType, type, setFullname, fullname, setEmail, email, setPhone, phone, setPassword, password, confirmPassword, setConfirmPassword, setShowPassword, showPassword, setShowConfirmPassword, cleanUpMemberForm, showConfirmPassword, createMember, editMember, } = useContext(NewMemberContext);
    let urlAdd = 'admin';
    let urlEdit = 'admin-update';

    if (type === 'member') {
        urlAdd = 'member';
        urlEdit = 'member-update';
    }

    const validatePassword = (password) => {
        return regexPassword.test(password)
    };

    const onSubmitAddMember = (e) => {
        e.preventDefault();

        setPopup('create_member');
    }

    const proceedAddMember = async () => {
        const FD = new FormData();

        FD.append('email', email);
        FD.append('fullname', fullname);
        FD.append('phone_number', phone);
        FD.append('password', password);
        FD.append('password_confirmation', confirmPassword);

        const reqCreateMember = await createMember(FD, urlAdd);

        if (reqCreateMember === 'OK') {
            setPopup(undefined);
            setModal(undefined);
            cleanUpMemberForm();
            toast.success('Success created new member');
            getMembersQuery.refetch()
        }
    }

    const proceedEditMember = async () => {
        const FD = new FormData();

        FD.append('user_id', memberId);
        FD.append('fullname', fullname);
        FD.append('email', email);
        FD.append('phone', phone);

        const reqEditMember = await editMember(FD, urlEdit);

        if (reqEditMember === 'OK') {
            setPopup(undefined);
            setModal(undefined);
            cleanUpMemberForm();
            toast.success('Success edited member');
            getMembersQuery.refetch()
        }
    }

    return (
        <form onSubmit={onSubmitAddMember} autoComplete="off">
            <div className="space-y-3">
                {
                    memberType === 'add' && <div className="columns-2">
                        <div>
                            <input
                                className="mr-3"
                                type="radio"
                                id="admin"
                                checked={type === 'admin'}
                                onClick={() => setType('admin')}
                                required={true}
                            />
                            <label className="cursor-pointer" htmlFor="admin">add as admin</label>
                        </div>
                        <div>
                            <input
                                className="mr-3"
                                type="radio"
                                id="member"
                                checked={type === 'member'}
                                onClick={() => setType('member')}
                                required={true}
                            />
                            <label className="cursor-pointer" htmlFor="member">add as member</label>
                        </div>
                    </div>
                }
                <div>
                    <label htmlFor="fullname_">Fullname</label>
                    <Input
                        type="text"
                        isRequired={true}
                        action={setFullname}
                        value={fullname}
                        id={'fullname_'}
                    />
                </div>
                <div>
                    <label htmlFor="email-member-admin">Email</label>
                    <Input
                        type="email"
                        isRequired={true}
                        action={setEmail}
                        value={email}
                        id={'email-member-admin'}
                        autoComplete={'new-email'}
                    />
                </div>
                <div>
                    <label htmlFor="phone">Phone Number</label>
                    <Input
                        type="text"
                        isRequired={true}
                        action={(e) => setPhone(e.replace(/[^\d]/g, ''))}
                        value={phone}
                        id={'phone'}
                    />
                </div>
                {
                    memberType === 'add' && <>
                        <div>
                            <label htmlFor="password-member-edit">Password</label>
                            <InputPassword
                                className={password !== '' ? validatePassword(password) ? 'success-form' : 'error-form' : ''}
                                showPassword={showPassword}
                                isRequired={true}
                                action={setPassword}
                                value={password}
                                id={'password-member-edit'}
                                autoComplete={'new-password'}
                            />
                        </div>
                        <div>
                            <label htmlFor="confirm_passowrd_">Confirm Password</label>
                            <InputPassword
                                className={confirmPassword !== '' ? confirmPassword === password ? 'success-form' : 'error-form' : ''}
                                showPassword={showConfirmPassword}
                                isRequired={true}
                                action={setConfirmPassword}
                                value={confirmPassword}
                                id={'confirm_passowrd_'}
                            />
                        </div>
                    </>
                }
                <button type="submit" id="btn_add_member" hidden></button>
            </div>
            <Popup
                action={memberType === 'add' ? proceedAddMember : proceedEditMember}
                icon={null}
                type="create_member"
                textBtn="Submit"
            >
                {
                    memberType === 'add' ? 'Are you sure you want to proceed create member?' : 'Are you sure you want to proceed edit member?'
                }
            </Popup>
        </form>
    );
}

export default MemberForm;