function Complain() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.0548 1.0255C-1.07288e-07 2.05012 0 3.70038 0 7C0 10.2996 -1.07288e-07 11.9499 1.0548 12.9745C2.1087 14 3.8061 14 7.2 14H10.8C14.1939 14 15.8913 14 16.9452 12.9745C18 11.9499 18 10.2996 18 7C18 3.70038 18 2.05012 16.9452 1.0255C15.8913 -1.04308e-07 14.1939 0 10.8 0H7.2C3.8061 0 2.1087 -1.04308e-07 1.0548 1.0255ZM14.9184 3.08C15.0329 3.21369 15.0882 3.38612 15.072 3.55939C15.0558 3.73266 14.9694 3.89258 14.832 4.004L12.8556 5.60525C12.0573 6.25275 11.4111 6.776 10.8396 7.133C10.2456 7.50488 9.6669 7.73938 9 7.73938C8.3331 7.73938 7.7544 7.504 7.1595 7.133C6.5889 6.776 5.9427 6.25187 5.1444 5.60612L3.168 4.00487C3.03039 3.89348 2.94393 3.73351 2.92765 3.56014C2.91136 3.38678 2.96658 3.21422 3.08115 3.08044C3.19572 2.94665 3.36027 2.8626 3.53859 2.84676C3.71691 2.83093 3.89439 2.88461 4.032 2.996L5.976 4.57012C6.8157 5.25 7.398 5.72075 7.8912 6.02875C8.3673 6.32625 8.6904 6.42688 9.0009 6.42688C9.3114 6.42688 9.6345 6.32713 10.1106 6.02875C10.6029 5.72075 11.1861 5.25 12.0258 4.57012L13.9689 2.99512C14.1065 2.88388 14.284 2.83033 14.4622 2.84625C14.6404 2.86217 14.8039 2.94625 14.9184 3.08Z" />
            </svg>
        </>
    );
}

export default Complain;