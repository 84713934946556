import Input from "components/Input";
import Popup from "components/Modal/Popup";
import BoothContext from "context/BoothContext";
import ModalContext from "context/ModalContext";
import { useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

function BoothCategoryForm({ type }) {
    const { categoryName, categoryBoothId, editBoothCategory, setCategoryName, categoryMap, setCategoryMap, createCategoryBooth, } = useContext(BoothContext);
    const { setPopup, setModal } = useContext(ModalContext);
    const params = useParams();

    const proceedAddBoothCategory = async () => {
        const FD = new FormData();

        FD.append('event_id', params.event_id);
        FD.append('name', categoryName);

        if (type === 'add') { 
            FD.append('image', categoryMap);
            const reqAddBoothCategory = await createCategoryBooth(FD);
            if (reqAddBoothCategory === 'OK') {
                setModal(undefined);
                toast.success('Success create category booth');
            }
        } else {
            if (categoryMap !== null) {
                FD.append('image', categoryMap);
            }
            FD.append('category_booth_id', categoryBoothId);
            const reqEditBoothCategory = await editBoothCategory(FD);

            if (reqEditBoothCategory.status === 200) {
                setModal(undefined);
                toast.success('Success edit category booth');
            }
        }
    }

    const onAddBoothCategory = e => {
        e.preventDefault();

        setPopup('add_booth_category');
    }

    return (
        <>
            <form onSubmit={onAddBoothCategory}>
                <label htmlFor="category_name" className="mb-2">Categories Name</label>
                <Input
                    type={'text'}
                    action={setCategoryName}
                    value={categoryName}
                    isRequired={true}
                    id={'category_name'}
                />
                <label htmlFor="upload_map">Upload Map</label>
                <Input
                    type={'file'}
                    action={setCategoryMap}
                    isMultiple={false}
                    value={categoryMap}
                    isRequired={type === 'add' ? true : false}
                    id={'upload_map'}
                />
                <button type="submit" id="btn_add_booth_category" hidden></button>
            </form>
            <Popup
                action={proceedAddBoothCategory}
                icon={null}
                textBtn={'Submit'}
                type={'add_booth_category'}
            >
                Are you sure want to {type} booth category?
            </Popup>
        </>
    );
}

export default BoothCategoryForm;