function Questios() {
    return (
        <>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0ZM9 12.6C8.7613 12.6 8.53239 12.6948 8.3636 12.8636C8.19482 13.0324 8.1 13.2613 8.1 13.5C8.1 13.7387 8.19482 13.9676 8.3636 14.1364C8.53239 14.3052 8.7613 14.4 9 14.4C9.23869 14.4 9.46761 14.3052 9.6364 14.1364C9.80518 13.9676 9.9 13.7387 9.9 13.5C9.9 13.2613 9.80518 13.0324 9.6364 12.8636C9.46761 12.6948 9.23869 12.6 9 12.6ZM9 4.05C8.13473 4.05 7.3049 4.39373 6.69306 5.00556C6.08123 5.6174 5.7375 6.44723 5.7375 7.3125C5.7375 7.55119 5.83232 7.78011 6.0011 7.9489C6.16989 8.11768 6.39881 8.2125 6.6375 8.2125C6.87619 8.2125 7.10511 8.11768 7.2739 7.9489C7.44268 7.78011 7.5375 7.55119 7.5375 7.3125C7.5378 7.04703 7.61035 6.78665 7.74737 6.55928C7.88438 6.33191 8.08071 6.14611 8.31528 6.02183C8.54986 5.89754 8.81384 5.83945 9.07892 5.85377C9.344 5.8681 9.60018 5.9543 9.82 6.10315C10.0398 6.25199 10.215 6.45786 10.3267 6.69867C10.4384 6.93949 10.4825 7.20617 10.4542 7.47012C10.4258 7.73408 10.3262 7.98535 10.166 8.19699C10.0057 8.40863 9.79088 8.57266 9.5445 8.6715C8.9361 8.9145 8.1 9.5373 8.1 10.575V10.8C8.1 11.0387 8.19482 11.2676 8.3636 11.4364C8.53239 11.6052 8.7613 11.7 9 11.7C9.23869 11.7 9.46761 11.6052 9.6364 11.4364C9.80518 11.2676 9.9 11.0387 9.9 10.8C9.9 10.5804 9.945 10.4706 10.1349 10.377L10.2132 10.341C10.9159 10.0583 11.4984 9.53996 11.8609 8.87483C12.2233 8.20969 12.343 7.4392 12.1996 6.69545C12.0562 5.95169 11.6585 5.28099 11.0747 4.79832C10.4909 4.31566 9.75746 4.0511 9 4.05Z" />
            </svg>

        </>
    );
}

export default Questios;