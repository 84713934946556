import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ck-style.css'
import ButtonPill from 'components/atoms/ButtonPill'
import CheckboxWithLabel from 'components/atoms/CheckboxWithLabel'
import InputWithLabel from 'components/atoms/InputWithLabel'
import Card from './Card'
import { useAuthStore } from 'store/auth'
import { shallow } from 'zustand/shallow'
import { checkPermission } from 'utils/checkPermission'


export default function FormTnc({ handleSubmit, formValue, setFormValue, isLoading, type }) {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)

    return (
        <Card >
            <form className=' flex gap-4 flex-col' onSubmit={handleSubmit}>
                <InputWithLabel
                    label="Insert Link"
                    required={!formValue.wantToWriteChecked}
                    disabled={formValue.wantToWriteChecked}
                    value={formValue.link}
                    onChange={(e) => setFormValue(p => ({ ...p, link: e.target.value }))}
                    classInput={` !text-base !font-medium !tracking-[0.48px] !pt-[13px] !pb-[13px] !px-5 valid:!empty-form ${formValue.link === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                />
                <CheckboxWithLabel
                    label="I want to write the privacy policy"
                    checked={formValue.wantToWriteChecked}
                    onChange={(e) => setFormValue(p => {
                        return ({ ...p, wantToWriteChecked: e.target.checked })
                    })}
                />

                {/* <TextAreaWithLabel label="Terms & Conditions"
                    value={formValue.tnc}
                    className={`max-h-0 transition-all duration-1000 p-[1px] ease-in-out delay-0 overflow-hidden ${formValue.wantToWriteChecked ? 'max-h-screen' : ' opacity-0'}`}
                    required={formValue.wantToWriteChecked}
                    disabled={!formValue.wantToWriteChecked}
                    onChange={(e) => setFormValue(p => ({ ...p, tnc: e.target.value }))}
                    classInput={` py-6 !px-5 ${formValue.tnc === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                /> */}
                <div className='flex flex-col gap-2 text-appblack '>
                    <p className='text-base tracking-[0.48px]'>{type}</p>
                    <CKEditor
                        required={formValue.wantToWriteChecked}
                        disabled={!formValue.wantToWriteChecked}
                        config={{
                            heading: {
                                options: [
                                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                                ]
                            },
                            toolbar: [
                                'undo',
                                'redo',
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                'link',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'outdent',
                                'indent',
                                '|',
                                'blockQuote',
                            ]
                        }}

                        editor={ClassicEditor}
                        onReady={editor => {
                            // console.log(formValue.tnc.replace(/<\/?[^>]+(>|$)/g, ''))
                            // if (formValue.tnc.replace(/<\/?[^>]+(>|$)/g, '') !== "") {
                            //     var toolbar = document.querySelector(".ck.ck-editor .ck-toolbar")
                            //     if (toolbar) {
                            //         toolbar.classList.add("ck-focused")
                            //     }
                            // }

                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormValue(p => ({ ...p, tnc: data }))
                        }}
                        data={formValue.tnc}
                        onBlur={(event, editor) => {
                            // console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            // console.log('Focus.', editor);

                        }}
                    />
                </div>


                {
                    profile?.permissions !== undefined ?
                        <>
                            {
                                (
                                    checkPermission(profile?.permissions, 'edit privacy event') ||
                                    checkPermission(profile?.permissions, 'edit tnc event') ||
                                    checkPermission(profile?.permissions, 'edit global privacy policy') ||
                                    checkPermission(profile?.permissions, 'edit global tnc')
                                ) &&
                                <div className=' flex justify-center mt-10 mb-3'>
                                    <ButtonPill disabled={isLoading} label={isLoading ? 'Loading...' : "Update " + type} className=" w-[290px]" type="submit" />
                                </div>
                            }
                        </>
                        :
                        <></>

                }
            </form>
        </Card>
    )
}
