import { Modal } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import ModalContext from "context/ModalContext";
import Loading from "components/Loading";
import VendorContext from "context/VendorContext";
import * as d3 from "d3";
import { gapText, fontSizeSpin, getLengtText } from "utils/spin";
import BoothContext from "context/BoothContext";
import Ilustration from "../../assets/images/event-ilustration.svg";
import Swal from "sweetalert2";
import { CustomFlowbiteTheme } from "flowbite-react";

function ModalLuckySpin({ isLoading, actionChangeLoading }) {
    const { modal, setModal } = useContext(ModalContext);
    const { vendor } = useContext(VendorContext);
    const { boothId, activeBooth, handleChangeBooth, handleWinnerOfBooth, booth, categoryBooth } = useContext(BoothContext);
    const { getVendorByBooth } = useContext(VendorContext);
    const refSpin = useRef(null);
    const [isSpin, setIsSpin] = useState(false);

    const jarak = gapText(vendor.length)
    const fontSize = fontSizeSpin(vendor.length);

    const createSpin = () => {
        if (refSpin.current && refSpin.current.children.length > 0) {
            refSpin.current.childNodes.forEach((item) => {
                refSpin.current.removeChild(item)
            })
        }

        const padding = { top: 20, right: 40, bottom: 0, left: 0 };
        const w = 400 - padding.left - padding.right;
        const h = 400 - padding.top - padding.bottom;
        const r = Math.min(w, h) / 2;
        let rotation = 0;
        let oldrotation = 0;
        let picked = 100000;
        const oldpick = [];
        const color = d3.scaleOrdinal(d3.schemeCategory10);


        const svg = d3.select('#chart')
            .append("svg")
            .data([vendor])
            .attr("width", w + padding.left + padding.right)
            .attr("height", h + padding.top + padding.bottom);

        const container = svg.append("g")
            .attr("class", "chartholder")
            .attr("transform", `translate(${w / 2 + padding.left},${h / 2 + padding.top})`);

        const vis = container.append("g");

        const pie = d3.pie().sort(null).value(1);

        const arc = d3.arc().outerRadius(r).innerRadius(0);
        const arcs = vis.selectAll("g.slice")
            .data(pie(vendor.map((item) => parseInt(item.vendor_name))))
            .enter()
            .append("g")
            .attr("class", "slice");

        arcs.append("path")
            .attr("fill", (i) => color(i))
            .attr("d", (d) => arc(d));

        arcs.append("text").attr("transform", (d) => {
            d.innerRadius = 0;
            d.outerRadius = r;
            d.angle = (d.startAngle + d.endAngle) / 2;
            return `rotate(${d.angle * 180 / Math.PI - jarak})translate(${d.outerRadius - 10})`;
        })
            .attr("style", `font-size:${fontSize}px; fill:white;`)
            .attr("text-anchor", "end")
            .text((d, i) => vendor[i].vendor_name.substring(0, getLengtText(fontSize)));

        container.on("click", spin);

        function spin(el, d) {
            container.on("click", null);

            if (oldpick.length === 1) {
                return;
            }

            const ps = 360 / vendor.length;
            const pieslice = Math.round(1440 / vendor.length);
            const rng = Math.floor((Math.random() * 1440) + 360);

            let indexVendor = null;

            vendor.find((element, index) => {
                if (element.is_priority === 1) {
                    indexVendor = index
                }
            });

            if (indexVendor !== null) {
                let picked = indexVendor;
                rotation = 360 * 3 + 360 - (ps * picked);
            } else {
                rotation = (Math.round(rng / ps) * ps);
            }

            picked = Math.round(vendor.length - (rotation % 360) / ps);
            picked = picked >= vendor.length ? (picked % vendor.length) : picked;

            if (oldpick.indexOf(picked) !== -1) {
                d3.select(this).call(spin.bind(this, d));
                return;
            } else {
                oldpick.push(picked);
            }

            rotation += 90 - Math.round(ps / 2);
            setIsSpin(true)
            vis.transition()
                .duration(10000)
                .attrTween("transform", rotTween)
                .on("end", async function () {
                    setIsSpin(false)
                    d3.select(".slice:nth-child(" + (picked + 1) + ") path")
                        .attr("fill", "#111");

                    const dataPicked = vendor[picked]
                    const currentBooth = booth.find(item => item.is_active === true);
                    const FD = new FormData();

                    FD.append('vendor_id', dataPicked.vendor_id);
                    FD.append('booth_id', currentBooth.id);

                    const reqSetWinner = await handleWinnerOfBooth(FD);
                    if (reqSetWinner.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Winner',
                            html: `
                            <p class="font-bold text-2xl">Name: ${dataPicked.vendor_name}</p>
                        `,
                        })
                    }
                    // winnerMutation.mutate(dataPicked)
                    // Swal.fire({
                    //     icon: 'success',
                    //     title: 'Winner',
                    //     html: `
                    //         <p class="font-bold text-2xl">Name: ${dataPicked.customer.fullname}</p>
                    //         <p class="font-bold text-2xl">Identity Number: ${blurNomorHp(dataPicked.customer.identity_number)}</p>
                    //         <p class="font-bold text-2xl">Phone: ${blurNomorHp(dataPicked.customer.phone_number)}</p>
                    //     `,

                    // })
                    // d3.select("#question h1")
                    //     .text(dataPicked.customer.fullname);
                    oldrotation = rotation;
                    container.on("click", spin);
                });
        }

        svg.append("g")
            .attr("transform", `translate(${w + padding.left + padding.right},${(h / 2) + padding.top})`)
            .append("path")
            .attr("d", `M-${r * 0.15},0L0,${r * 0.05}L0,-${r * 0.05}Z`)
            .style("fill", "black");

        container.append("circle")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("r", 40)
            .attr("style", "fill:white; cursor:pointer; ",)

        container.append("text")
            .attr("x", 0)
            .attr("y", 9)
            .attr("text-anchor", "middle")
            .text("SPIN")
            .attr("style", "font-weight:bold; font-size:20px; cursor:pointer;")

        function rotTween() {
            const i = d3.interpolate(oldrotation % 360, rotation);
            return function (t) {
                return `rotate(${i(t)})`;
            };
        }
    }

    useEffect(() => {
        if (vendor.length > 1) {
            createSpin();
        }
    }, [vendor]);

    let currentBooth = categoryBooth.length > 0 ? categoryBooth.find(item => item.is_active === true) : [];

    return (
        <>
            <Modal show={modal === 'lucky_spin'} size="5xl" onClose={() => setModal(undefined)}>
                <div className="border-b-[1px] border-appgray">
                    <div className="px-6 py-5 flex justify-between items-center">
                        <h1 className="text-appblack text-xl font-semibold text-center" style={{ width: '-webkit-fill-available' }}>Lucky Spin <strong>({currentBooth?.name})</strong></h1>
                        <button className=" text-4xl border-[1px] border-moregray w-[32px] h-[32px] flex items-center justify-center text-moregray rounded-full" onClick={() => setModal(undefined)}>
                            <p className="-mt-[4px]">
                                &times;
                            </p>
                        </button>
                    </div>
                </div>
                <Modal.Body className="min-h-[450px]">
                    {
                        <div className=" w-full overflow-scroll flex">
                            <div className="w-1/4 p-1">
                                <label htmlFor="" className="capitalize">List Booth</label>
                                <ul className="bg-background mt-3 py-2 px-4 rounded-2xl ring-1 ring-moregray divide-y divide-moregray">
                                    {
                                        Object.keys(booth).length > 0 &&
                                        booth.map((item, index) => {
                                            if (item.book_by !== null) {
                                                return (
                                                    <li
                                                        key={item.number}
                                                        className="py-2 text-moregray cursor-not-allowed"
                                                    >
                                                        Booth {item.number} <p className="font-semibold capitalize">({item.book_by})</p>
                                                    </li>
                                                )
                                            } else {
                                                if (item.is_active) {
                                                    return (
                                                        <li
                                                            key={item.number}
                                                            className="py-2 cursor-pointer text-default"
                                                        >
                                                            Booth {item.number}
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li
                                                            key={item.number}
                                                            className="py-2 cursor-pointer" onClick={async () => {
                                                                actionChangeLoading(true);
                                                                handleChangeBooth(item.id)
                                                                const reqGetVendor = await getVendorByBooth(item.id);
                                                                if (reqGetVendor.success) {
                                                                    actionChangeLoading(false);
                                                                }
                                                            }}>Booth {item.number}</li>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                            <div className=" w-3/4 flex justify-center items-center">
                                {
                                    isLoading ?
                                        <Loading />
                                        :
                                        vendor.length > 1 ?
                                            <div id="chart" className={` ${isSpin ? ' z-50 flex items-center justify-center w-full spin' : 'flex items-center justify-center'}`} ref={refSpin}></div>
                                            :
                                            vendor.length > 0 ?
                                                <div className="h-[300px] w-[300px]">
                                                    <img src={Ilustration} />
                                                </div>
                                                :
                                                <div className="h-[300px] w-[300px]">
                                                    <img src={Ilustration} />
                                                </div>
                                }
                            </div>
                        </div>

                    }
                </Modal.Body >
            </Modal >
        </>
    );
}

export default ModalLuckySpin;