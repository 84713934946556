import { useQuery } from "@tanstack/react-query";
import Header from "components/Header";
import TableComponent from "components/Table";
import PermissionTable from "components/moleculs/Table/PermissionTable";
import ModalContext from "context/ModalContext";
import { handleError } from "helpers/HandleRequestApi";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "utils/api";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";
import Loading from "components/Loading";

const AdminPermissionPage = () => {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { admin_id } = useParams();
    const { setModal } = useContext(ModalContext)
    const [permissions, setPermissions] = useState([]);
    const [username, setUsername] = useState('');

    const queryGetPermissionAdmin = useQuery({
        queryFn: () => {
            return API.get(`permission?type=admin&user_id=${admin_id}`);
        },
        queryKey: ['permission-admin'],
        select(data) {
            return data.data
        }
    })

    useEffect(() => {
        if (queryGetPermissionAdmin.data !== undefined) {
            const data = queryGetPermissionAdmin.data;
            console.log(data);
            setPermissions(data.data);
            setUsername(data.user_name);
        }
    }, [queryGetPermissionAdmin.data])

    return (
        <main className="bg-background min-h-screen">
            <Header showGoback={true} className="py-5 px-14" />
            {
                queryGetPermissionAdmin.isLoading ? <Loading /> : <section className="px-14 py-5">
                    <div className="card app-shadow">
                        <div className="card-header flex justify-between">
                            <h1>List Permission ({username})</h1>
                        </div>
                        <div className="card-body px-5">
                            <PermissionTable data={permissions} setPermissions={setPermissions} userId={admin_id} hasPermission={profile?.permissions !== undefined && checkPermission(profile.permissions, 'set permissions admin')} />
                        </div>
                    </div>
                </section>
            }
        </main>
    )
}

export default AdminPermissionPage;
