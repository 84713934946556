import { showToast } from "./ShowSweetAlert";

export const handleSuccess = (response) => {
    showToast();
}

export const handleError = (response) => {
    if (response?.status === 401) {
        showToast('Unauthorized.', 'error', response?.data?.message);
    } else if (response?.status === 403) {
        showToast('error', 'Forbidden', response?.data?.message);
    } else if (response?.status === 404) {
        showToast('error', 'Not Found', response?.data?.message);
    } else if (response?.status === 422) {
        showToast('error', 'Error', response?.data?.message);
    } else if (response?.status === 500) {
        showToast('error', 'Internal Server Error', response?.data?.message);
    } else {
        showToast(
            'error',
            'Something Went Wrong With The Request',
            response?.data?.message ?? ''
        );
    }
}
