import API from "utils/api"

// Form
export function getListFormEventService(id) {
    return API.get('/form/event/' + id)
}
export function createFormEventService(data) {
    return API.post('/form/event', data)
}
export function deleteFormEventService(id) {
    return API.delete('/form/event/' + id)
}

export function updateFormEventService(data) {
    return API.put('/form/event', data)
}

export function setActiveFormEventService(data) {
    return API.put('/form/event/active', data)
}

// Questions
export function getFormQuestionSelectedActiveService(id) {
    return API.get('/form/question/admin/' + id)
}

export function getFormQuestionByFormIdService(id) {
    return API.get('/form/event/detail/' + id)
}


export function createQuestionFormService(data) {
    return API.post('/form/question', data)
}

export function updateQuestionFormService(data) {
    return API.put('/form/question', data, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
}

export function deleteQuestionFormService(form_id) {
    return API.delete('/form/question/' + form_id)
}

export function dupicateQuestionFormService(data) {
    return API.post('/form/question/replicate', data)
}

// Choice
export function createChoiceQuestionService(data) {
    return API.post('/form/choice', data)
}

export function updateChoiceQuestionService(data) {
    return API.put('/form/choice', data)
}

export function deleteChoiceQuestionService(id) {
    return API.delete(`/form/choice/${id}`)
}