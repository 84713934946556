import { useEvent } from "context/EventContext";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "flowbite-react";
import NewEventContext from "context/NewEventContext";

function YTPreview({ data }) {
    const { ytLink, ytLoading, setYtLoading, errorYtLink, setErrorYtLink, } = useContext(NewEventContext);
    // const { value, setIsLoading, setErrorYtLink, } = useEvent();
    const [ytId, setytId] = useState(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [title, setTitle] = useState(null);
    const [author, setAuthor] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (ytLink !== null) {
            fetch(`https://www.youtube.com/oembed?url=${ytLink}`)
                .then(ress => {
                    return ress.json();
                })
                .then(data => {
                    const videoIdRegex = /embed\/([A-Za-z0-9_-]+)/;
                    const match = data.html.match(videoIdRegex);

                    setTimeout(() => {
                        setytId(match[1]);
                        setAuthor(data.author_name);
                        setHeight(data.height);
                        setWidth(data.width);
                        setTitle(data.title);
                        setIsSuccess(true);
                        setYtLoading(false);
                        setErrorYtLink(null);
                    }, 2000);
                })
                .catch(err => {
                    setTimeout(() => {
                        setytId(undefined);
                        setAuthor(null);
                        setHeight(null);
                        setWidth(null);
                        setTitle(null);
                        setIsSuccess(false);
                        setYtLoading(false);
                        setErrorYtLink('*This link is not valid, please check again your link');
                    }, 2000);
                })
        }
    }, [ytLink]);

    if (ytLoading) {
        return (
            <div className="text-center flex items-center justify-center">
                <Spinner aria-label="Default status example" />
                <p className="ml-3">Checking ...</p>
            </div>
        )
    }


    if (ytId !== null) {
        return YTView(ytId, title, height, width, author, isSuccess);
    }

    return <></>;
}

function YTView(id, title, height, width, author, isSuccess) {
    let view = isSuccess ?
        <div className="rounded mt-3 overflow-hidden flex mt-2">
            <div>
                <iframe
                    src={`https://www.youtube.com/embed/${id}?feature=oembed`}
                    width={width}
                    height={height}
                >
                </iframe>
            </div>
            <div className="p-2 w-full">
                <h1 className="text-sm">Author: <strong>{author}</strong></h1>
                <h1 className="text-sm">Title: <strong>{title}</strong></h1>
            </div>
        </div>
        :
        <></>

    return view;
}

export default YTPreview;