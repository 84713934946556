import API from 'utils/api';

export async function getMembersService(page) {
    return await API.get(`/member?page=${page}`).then(res => res.data);
}

export async function activatedMemberService(FD, status) {
    if (status === 'admin') {
        return await API.post(`activated-admin`, FD).then(res => res.data);
    }
    return await API.post(`activated-member`, FD).then(res => res.data);
}

export async function editMemberService(FD) {
    return await API.post(`member-update`, FD).then(res => res.data);
}

export async function deleteMemberService(memberId) {
    return await API.delete(`member/delete/${memberId}`).then(res => res.data);
}
