export const GET_BOOTH_CATEGORY = 'GET_BOOTH_CATEGORY';
export const ADD_BOOTH_CATEGORY = 'ADD_BOOTH_CATEGORY';
export const EDIT_BOOTH_CATEGORY = 'EDIT_BOOTH_CATEGORY';
export const DELETE_BOOTH_CATEGORY = 'DELETE_BOOTH_CATEGORY';
export const ADD_BOOTH = 'ADD_BOOTH';
export const EDIT_BOOTH = 'EDIT_BOOTH';
export const DELETE_BOOTH = 'DELETE_BOOTH';
export const GET_VENDOR_BY_BOOTH = 'GET_VENDOR_BY_BOOTH';
export const GET_PROPOSE_BOOTH_BY_BOOTH_CATEGORY =
    'GET_PROPOSE_BOOTH_BY_BOOTH_CATEGORY';
export const APPROVE_VENDOR_BY_BOOTH = 'APPROVE_VENDOR_BY_BOOTH';
export const REJECT_VENDOR_BY_BOOTH = 'REJECT_VENDOR_BY_BOOTH';
export const PROPOSE_BOOTH_TO_VENDOR = 'PROPOSE_BOOTH_TO_VENDOR';
export const SET_ACTIVE_BOOTH = 'SET_ACTIVE_BOOTH';
export const CHECK_PAYMENT = 'CHECK_PAYMENT';
export const APPROVE_PAYMENT = 'APPROVE_PAYMENT';
export const REJECT_PAYMENT = 'REJECT_PAYMENT';
export const CHANGE_ACTIVE_BOOTH = 'CHANGE_ACTIVE_BOOTH';
export const SET_WINNER_OF_WHEEL = 'SET_WINNER_OF_WHEEL';
export const CHANGE_BOOTH_CATEGORY = 'CHANGE_BOOTH_CATEGORY';
export const GET_BOOTH_BY_CATEGORY = 'GET_BOOTH_BY_CATEGORY';
