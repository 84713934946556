import { MONTHS } from "helpers/Month";
import Image from "components/atoms/Image";
import InputWithLabel from "components/atoms/InputWithLabel";
import TextAreaWithLabel from "components/atoms/TextAreaWithLabel";

function FormDetailNotif({ data }) {
    const publishAt = new Date(data.publish_at);
    const complainDate = publishAt.getDate() + ' ' + MONTHS[publishAt.getMonth()] + ' ' + publishAt.getFullYear() + ' ' + publishAt.getHours() + ':' + publishAt.getMinutes();
    return (
        <>
            {
                Object.keys(data).length > 0 &&
                <>
                    <Image
                        path={data.img_url}
                        className="w-2/4 mx-auto mb-3 object-cover"
                    />
                    <InputWithLabel
                        classInput={'!mb-3'}
                        value={data.title}
                        label={'Title'}
                        readOnly={true}
                    />
                    <TextAreaWithLabel
                        classInput={'!mb-3 valid-form !text-xl !font-medium'}
                        value={data.body}
                        label={'Body'}
                        readOnly={true}
                    />
                    <InputWithLabel
                        classInput={'!mb-3'}
                        value={data.status}
                        label={'Status'}
                        readOnly={true}
                    />
                    <InputWithLabel
                        classInput={'!mb-3'}
                        value={data.created_at}
                        label={'Created At'}
                        readOnly={true}
                    />
                    <InputWithLabel
                        classInput={'!mb-3'}
                        value={complainDate}
                        label={'Publish At'}
                        readOnly={true}
                    />
                </>
            }
        </>
    );
}

export default FormDetailNotif;