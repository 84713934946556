import { useMutation, useQueryClient } from '@tanstack/react-query'
import InputWithLabel from 'components/atoms/InputWithLabel'
import ModalContext from 'context/ModalContext'
import { Modal } from 'flowbite-react'
import { handleError } from 'helpers/HandleRequestApi'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { createFormEventService, updateFormEventService } from 'services/form_admin'

export default function DialogForm({ event_id, show, onClose, formValue, setFormValue }) {
    const { setModal } = useContext(ModalContext);
    const queryClient = useQueryClient()

    const handleClose = () => {
        queryClient.invalidateQueries(['event-form', event_id])
        setFormValue({ title: '', id: '' })
        onClose()
    }

    const addMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            formData.append('event_id', event_id)
            formData.append('title', formValue.title)
            const response = await createFormEventService(formData)
            return response.data
        },
        onSuccess: (data) => {
            toast.success("Add form success")
            handleClose()

        },
        onError: (err) => {
            handleError(err.response)
        }
    })

    const updateMutation = useMutation({
        mutationFn: async () => {
            const formData = { 'form_event_id': formValue.id, 'title': formValue.title }
            const response = await updateFormEventService(formData)
            return response.data
        },
        onSuccess: (data) => {
            toast.success("Update form success")
            handleClose()
        },
        onError: (err) => {
            handleError(err.response)
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (formValue.id === "") {
            addMutation.mutate()
        } else {
            updateMutation.mutate()
        }
    }

    return (
        <Modal
            show={show}
            onClose={onClose}
            size={'lg'}
        >
            <div className="border-b-[1px] border-appgray">
                <div className="px-6 py-5 flex justify-between items-center">
                    <h1 className="text-appblack text-xl font-semibold text-center" style={{ width: '-webkit-fill-available' }}>{formValue.id !== '' ? 'Edit Form' : 'Add New Form'}</h1>
                    <button className=" text-4xl border-[1px] border-moregray w-[32px] h-[32px] flex items-center justify-center text-moregray rounded-full" onClick={() => setModal(undefined)}>
                        <p className="-mt-[4px]">
                            &times;
                        </p>
                    </button>
                </div>
            </div>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <InputWithLabel label={"Title"} value={formValue.title} onChange={(e) => setFormValue(p => ({ ...p, title: e.target.value }))} classInput="h-[45px] !text-base " required />
                    <button className=' hidden' id='btn_event_form'></button>
                </form>
            </Modal.Body>
            <Modal.Footer className=' border-0 pb-[50px] pt-[60px]'>
                <div className="text-center w-full space-x-3">
                    <button className="btn px-[50px] h-[49px]" onClick={() => document.getElementById('btn_event_form').click()}>Proceed</button>
                    <button type="button"
                        onClick={onClose}
                        className="btn-cancel px-[50px] h-[49px]"
                    >Cancel</button>
                </div>
            </Modal.Footer>

        </Modal>
    )
}
