function Logo2() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 -25 103 176" fill="none">
                <path d="M2.83228 0H102.507L94.2948 12.4585H2.83228V0Z" fill="#231F20" />
                <path d="M102.507 124.31H0L8.44535 111.851H102.507V124.31Z" fill="#231F20" />
                <path d="M21.0496 25.4839H34.4514V74.7556L69.3756 25.4839H85.4216L61.4479 59.653L89.009 97.7859H72.3961L52.6687 70.9799L34.8301 97.7859H21.0496V25.4839Z" fill="#231F20" />
            </svg>
        </>
    );
}

export default Logo2;