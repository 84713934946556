import {
    GET_VENDOR_BY_EVENT,
    GET_VENDOR_BY_BOOTH,
    ACCEPT_VENDOR,
    SET_PRIORITY_VENDOR,
    REJECT_VENDOR,
    PROPOSE_VENDOR,
    RESET_PRIORITY,
    GET_DETAIL_VENDOR,
} from 'types/VendorType';

export default (state, { type, payload }) => {
    switch (type) {
        case GET_VENDOR_BY_EVENT:
            return {
                ...state,
                isLoading: false,
                vendor: payload.data.items,
                totalPage: payload.meta.total_page,
                currentPage: payload.meta.current_page.slice(
                    payload.meta.current_page.indexOf('page=') + 5
                ),
            };
        case GET_VENDOR_BY_BOOTH:
            return {
                ...state,
                vendor: payload,
            };
        case ACCEPT_VENDOR:
            return {
                ...state,
            };
        case REJECT_VENDOR:
            return {
                ...state,
            };
        case PROPOSE_VENDOR:
            return {
                ...state,
            };
        case SET_PRIORITY_VENDOR:
            return {
                ...state,
                vendor: state.vendor.map(vend => {
                    if (vend.vendor_id === payload) {
                        vend.is_priority = 1;
                    } else {
                        vend.is_priority = 0;
                    }

                    return vend;
                }),
            };
        case RESET_PRIORITY:
            return {
                ...state,
                vendor: state.vendor.map(vend => {
                    vend.is_priority = 0;

                    return vend;
                }),
            };
        case GET_DETAIL_VENDOR:
            return {
                ...state,
                isLoading: false,
                vendorDetail: payload,
            };
    }
};
