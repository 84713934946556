import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "store/auth";
import { shallow } from 'zustand/shallow';
import Input from "../components/Input";
import InputPassword from "../components/InputPassword";
import { showToast } from "../helpers/ShowSweetAlert";
import Authenticate from "../ui/authenticate/Authenticate";
import { setAuthData } from "../utils/DataStorage";
import API, { setCredentialRequest } from "../utils/api";
import { MainsStack } from "routes";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { updateAuth, auth } = useAuthStore((state) => ({ updateAuth: state.updateAuth, auth: state.auth }), shallow)

  const handleChangeEmail = (value) => {
    setEmail(value);
  }

  const meta = document.getElementsByName('viewport')

  const handleChangePassword = (value) => {
    setPassword(value);
  }

  const tooglePasswordVisibility = (e) => {
    setShowPassword(!showPassword);
  }

  const handleLogin = (e) => {
    e.preventDefault();
    API.post('/login', {
      email: email,
      password: password
    }).then(response => {
      if (response.status === 200) {
        var responsedata = response.data
        var expires_in = new Date();
        expires_in.setMinutes(expires_in.getMinutes() + parseInt(responsedata.expires_in.toString()));
        responsedata.expires_in = expires_in.getTime();
        setCredentialRequest({ token: responsedata.access_token })
        updateAuth({ ...responsedata, email: email })
        setAuthData(response.data);
        return navigate(MainsStack.admin);
      } else {
        showToast('Something went wrong with the request.', 'error');
      }
    }).catch(error => {
      if (error.response?.status === 401) {
        showToast('error', 'Failed proceed login', "Email or password does not match.");
      } else if (error.response?.status === 403) {
        showToast('error', 'Forbidden', error.response?.data?.message);
      } else if (error.response?.status === 404) {
        showToast('error', 'Not Found', error.response?.data?.message);
      } else if (error.response?.status === 422) {
        showToast('error', 'Unprocessable Entity', error.response?.data?.message);
      } else if (error.response?.status === 500) {
        showToast('error', 'Internal Server Error', error.response?.data?.message);
      } else {
        showToast(
          'error',
          'Something Went Wrong With The Request',
          error.response?.data?.message ?? ''
        );
      }
    })
  }

  useEffect(() => {
    meta[0].setAttribute('content', 'width=device-width, initial-scale=1')
  })

  useEffect(() => {
    if (auth.access_token !== '') {
      navigate(MainsStack.admin);
    }
  }, [auth])

  return (
    <div>
      <Authenticate>
        <form onSubmit={handleLogin} autoComplete={'off'}>
          <label htmlFor="" className="text-appblack mb-1">Email</label>
          <Input
            type="email"
            isRequired={true}
            action={handleChangeEmail}
          />
          <label htmlFor="" className="text-appblack mb-1">Password</label>
          <InputPassword
            showPassword={showPassword}
            isRequired={true}
            action={handleChangePassword}
            value={password}
            togglePasswordVisibility={tooglePasswordVisibility}
          />
          <div className="flex justify-end">
            <Link
              to={MainsStack.forgotPassword}
              className="underline text-default text-sm ml-auto cursor-pointer"
            >
              Forgot Password?
            </Link>
          </div>
          <div className="flex justify-center">
            <button type="submit" className="btn mt-20 w-1/2">Login</button>
          </div>
        </form>
      </Authenticate>
    </div>
  );
}

export default Login;