import React, { useState } from 'react';
import ModalContext from './ModalContext';

const ModalState = ({ children }) => {
    const [modal, setModal] = useState(undefined);
    const [popup, setPopup] = useState(undefined);

    return (
        <ModalContext.Provider
            value={{
                modal,
                popup,
                setModal,
                setPopup,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export default ModalState;
