function Add({ color }) {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1V13" stroke="#F77C32" strokeWidth="2" strokeLinecap="round" />
                <path d="M13 7L1 7" stroke="#F77C32" strokeWidth="2" strokeLinecap="round" />
            </svg>

        </>
    );
}

export default Add;