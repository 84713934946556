import {
    GET_PURCHASE,
    ADD_PURCHASE,
    EDIT_PURCHASE,
    DELETE_PURCHASE,
} from 'types/PurchaseType';

export default (state, { type, payload }) => {
    switch (type) {
        case GET_PURCHASE:
            return {
                ...state,
                purchase: payload.data.items,
                totalPage: payload.meta.total_page,
                currentPage: payload.meta.current_page.slice(
                    payload.meta.current_page.indexOf('page=') + 5
                ),
            };
        case ADD_PURCHASE:
            return {
                ...state,
                purchase: [payload, ...state.purchase],
            };
        case EDIT_PURCHASE:
            return {
                ...state,
                purchase: state.purchase.map(item => {
                    if (item.id === payload.id) {
                        return payload;
                    }

                    return item;
                }),
            };
        case DELETE_PURCHASE:
            return {
                ...state,
                purchase: state.purchase.map(item => {
                    if (item.id === payload.id) {
                        return payload;
                    }

                    return item;
                }),
            };
    }
};
