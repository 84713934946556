import React from 'react'

export default function ButtonPill({ label, type, className, ...props }) {
    var color = 'bg-default text-white'
    if (type === "outline") {
        color = 'bg-white border border-default text-default';
    }
    return (
        <button className={`rounded-full px-[50px] h-[49px] font-semibold text-base ${color} ${className}`} {...props}>{label}</button>
    )
}
