import { useMutation } from '@tanstack/react-query'
import ButtonPill from 'components/atoms/ButtonPill'
import SelectInputLabel from 'components/atoms/SelectInputLabel'
import TextAreaWithLabel from 'components/atoms/TextAreaWithLabel'
import Copy2 from 'components/icons/Copy2'
import Pencil from 'components/icons/Pencil'
import PlusCircle from 'components/icons/PlusCircle'
import Trash2 from 'components/icons/Trash2'
import { Tooltip } from 'flowbite-react'
import { handleError } from 'helpers/HandleRequestApi'
import { memo, useCallback, useRef } from 'react'
import { toast } from 'react-toastify'
import { createChoiceQuestionService, createQuestionFormService, deleteQuestionFormService, dupicateQuestionFormService, updateQuestionFormService } from 'services/form_admin'
import Swal from 'sweetalert2'
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";
import Choices from './Choices'

export default memo(function FormQuestion({ question, handleChange, handleEdit, form_id, question_length, setQuestions, disableWrite, formIsActive }) {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const refButtonSubmit = useRef(null)

    const addMutation = useMutation({
        mutationFn: async (data) => {
            const formData = new FormData()
            formData.append('form_event_id', form_id)
            formData.append('question', '-')
            formData.append('field_type', 'essay')
            const response = await createQuestionFormService(formData)
            return response.data
        },
        onSuccess: ({ data }) => {
            toast.success("Add question success")
            setQuestions(p => p.filter((item) => item.id !== "").concat([{ ...data, isEditable: false, choices: [] }]))
        },
        onError(error) {
            handleError(error.response)
        }
    })

    const handleAdd = () => {
        addMutation.mutate()
    }

    const duplicateMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            formData.append('form_question_id', question.id)
            const response = await dupicateQuestionFormService(formData)
            return response.data
        },
        onSuccess: ({ data }) => {
            // queryClient.invalidateQueries(keyQuestions)
            setQuestions(p => p.concat([{ ...data, isEditable: false }]))
            toast.success("Duplicate success")
        },
        onError(err) {
            handleError(err.response)
        }
    })

    const deleteMutation = useMutation({
        mutationFn: async () => {
            const response = await deleteQuestionFormService(question.id)
            return response.data
        },
        onSuccess: (data) => {
            setQuestions(p => p.filter((q) => q.id !== question.id))
            toast.success("Delete question success")
        },
        onError: (error) => {
            handleError(error.response)
        }
    })

    const handleDuplicate = () => {
        Swal.fire({
            icon: 'question',
            text: 'Do you want to duplicate "' + question.question + ', type ' + question.field_type + '"',
            showConfirmButton: true,
            showCancelButton: true,
        }).then((ress) => {
            if (ress.isConfirmed) {
                duplicateMutation.mutate()
            }
        })
    }


    const handleRemove = () => {
        if (question_length > 1) {
            Swal.fire({
                icon: 'question',
                html: `Are you sure want to delete question "<b> ${question.question} , type  ${question.field_type}</b>"'`,
                showConfirmButton: true,
                showCancelButton: true,
            }).then((ress) => {
                if (ress.isConfirmed) {
                    deleteMutation.mutate()
                }
            })
        }
    }


    const updateQuestionMutation = useMutation({
        mutationFn: async () => {
            const response = await updateQuestionFormService({ form_question_id: question.id, question: question.question, field_type: question.field_type })
            return response.data
        },
        onSuccess: (data) => {
            toast.success("Update question success")
        },
        onError: (err) => {
            if (err?.response?.status === 422) {
                Swal.fire({
                    icon: 'error',
                    text: 'Form Event is active, cant edit this Question.',
                    timer: 3000
                })
            } else {
                handleError(err.response)
            }
        }
    })

    const isLoading = addMutation.isLoading || duplicateMutation.isLoading || deleteMutation.isLoading || updateQuestionMutation.isLoading
    const handleSubmitQuestion = (e) => {
        e.preventDefault()
        if (!isLoading) {
            updateQuestionMutation.mutate()
        }

    }

    const handleDeleteChoiceState = useCallback(({ id_choice }) => {
        setQuestions(p => p.map((q) => q.id === question.id ? ({ ...q, choices: q.choices.filter((choice) => choice.id !== id_choice) }) : q))
    }, [])

    const handleAddChoiceState = useCallback((dataChoice) => {
        setQuestions(p => p.map((q) => q.id === question.id ? ({ ...q, choices: q.choices.concat([{ ...dataChoice }]) }) : q))
    }, [])


    const addChoiceMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            formData.append('form_question_id', question.id)
            formData.append('choice', "Option")
            const response = await createChoiceQuestionService(formData)
            return response.data
        },
        onSuccess: ({ data }) => {
            toast.success("Add option success")
            handleAddChoiceState(data)
        }, onError: (err) => {
            handleError(err.response)
        }
    })


    const checkIsActiveBeforAction = (callback) => {
        if (formIsActive === 1) {
            Swal.fire({
                icon: 'error',
                text: 'Form Event is active, cant edit this Question.',
                timer: 3000
            })
        } else {
            callback()
        }
    }
    return (
        <div className="app-shadow2 bg-white rounded-[20px] py-6 pl-6 pr-4">
            <div className=" flex gap-5">
                <div className='flex flex-col gap-8 grow'>
                    <form className=" flex flex-col gap-8" onSubmit={handleSubmitQuestion}>
                        <div className="grid grid-cols-11 gap-4">
                            <div className={`${disableWrite === true ? 'col-span-11' : 'col-span-6'} `}>
                                <TextAreaWithLabel
                                    rows={4}
                                    required
                                    label={"Question"}
                                    disabled={!question.enableEdit}
                                    onChange={(e) => handleChange({ id: question.id, key: 'question', value: e.target.value })}
                                    value={question.question}
                                    classInput={` ${question.enableEdit === '' ? '!empty-form' : '!valid-form'} !p-4 !text-base`}
                                />
                            </div>
                            {
                                disableWrite === false && <div className=" col-span-5">
                                    <SelectInputLabel
                                        required
                                        disabled={!question.enableEdit}
                                        options={[
                                            { value: 'essay', label: 'Essay' },
                                            { value: 'choice', label: 'Multiple Choice' },
                                            { value: 'file', label: 'File' },
                                            { value: 'date', label: 'Date' },
                                        ]}
                                        value={question.field_type}
                                        onChange={(e) => handleChange({ id: question.id, key: 'field_type', value: e.target.value })}
                                        label={"Question Type"}
                                        classInput={` !text-base !font-medium !tracking-[0.48px] !p-0 !px-5 ${question.enableEdit === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                                    />
                                </div>
                            }
                        </div>
                        <button type='submit' ref={refButtonSubmit} className=' hidden'> submit</button>
                    </form>
                    {
                        question.choices.length > 0 && question.field_type === "choice" &&
                        <Choices
                            choices={question.choices}
                            question_id={question.id}
                            enableEdit={question.enableEdit}
                            handleChange={handleChange}
                            handleDeleteChoiceState={handleDeleteChoiceState}
                        />
                    }

                    {
                        <div className={`flex justify-between max-h-0 overflow-hidden transition-all duration-1000 opacity-0 ease-linear ${question.enableEdit ? ' max-h-60 opacity-100' : ''}`}>
                            {
                                question.field_type === "choice" ? <button type='button' className=" text-default underline font-medium tracking-[0.48px]" onClick={() => addChoiceMutation.mutate()}>Add Option</button> : <div></div>
                            }

                            <ButtonPill label={"Post Question"}
                                disabled={updateQuestionMutation.isLoading}
                                className={""} onClick={() => {
                                    if (refButtonSubmit.current) {
                                        refButtonSubmit.current.click()
                                    }
                                }} />
                        </div>
                    }
                </div>
                <div>
                    {
                        checkPermission(profile?.permissions, 'create question') ||
                            checkPermission(profile?.permissions, 'update question') ||
                            checkPermission(profile?.permissions, 'delete question') ||
                            checkPermission(profile?.permissions, 'duplicate question') ?
                            <div className=" w-[50px] flex flex-col border-default border-2 rounded-md -mt-2">
                                {
                                    checkPermission(profile?.permissions, 'create question') &&
                                    <button className={`h-[46px] ${!disableWrite ? 'border-b-2 border-default ' : ''} flex justify-center items-center`} onClick={() => checkIsActiveBeforAction(() => !isLoading && handleAdd())}>
                                        <Tooltip content="Add Question" placement="left">
                                            <PlusCircle />
                                        </Tooltip>
                                    </button>
                                }
                                {
                                    checkPermission(profile?.permissions, 'update question') &&
                                    disableWrite === false && <button className="h-[46px] border-b-2 border-default flex justify-center items-center" onClick={() => checkIsActiveBeforAction(() => !isLoading && question.id !== "" && handleEdit({ question }))}>
                                        <Tooltip content="Edit Question" placement="left">
                                            <Pencil />
                                        </Tooltip>
                                    </button>
                                }


                                {
                                    checkPermission(profile?.permissions, 'duplicate question') &&
                                    disableWrite === false && <button className="h-[46px] border-b-2 border-default flex justify-center items-center" onClick={() => checkIsActiveBeforAction(() => !isLoading && question.id !== "" && handleDuplicate({ question }))}>
                                        <Tooltip content="Duplicate" placement="left">
                                            <Copy2 />
                                        </Tooltip>
                                    </button>
                                }

                                {
                                    checkPermission(profile?.permissions, 'duplicate question') &&
                                    disableWrite === false && <button className="h-[46px] flex justify-center items-center" onClick={() => checkIsActiveBeforAction(() => !isLoading && question.id !== "" && handleRemove())}>
                                        <Tooltip content="Delete" placement="left">
                                            <Trash2 />
                                        </Tooltip>
                                    </button>
                                }

                            </div>
                            :
                            <></>
                    }
                </div>
            </div>
        </div >
    )
})