import { Modal } from "flowbite-react";
import { useContext } from "react";
import ModalContext from "context/ModalContext";

function Popup({ isMutate = false, action, icon, type, children, textBtn, backgroundColor = 'bg-badge-error', idBtn = null }) {
    const { popup, setPopup } = useContext(ModalContext);

    return (
        <>
            <Modal show={popup === type} size="lg" popup onClose={() => setPopup(undefined)}>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        {
                            icon !== null && <div className={`w-16 h-16 flex justify-center items-center mx-auto rounded-full ${backgroundColor}`}>{icon}</div>
                        }
                        <h3 className="my-5 text-lg font-normal text-black dark:text-gray-400">
                            {
                                children
                            }
                        </h3>
                        <div className="flex justify-center gap-4">
                            {
                                idBtn !== null ?
                                    <button
                                        onClick={() => document.getElementById(idBtn).click()}
                                        className="btn w-24"
                                    >{textBtn}</button>
                                    :
                                    isMutate ? <button
                                        onClick={() => {
                                            action.mutate();
                                            setPopup(undefined);
                                        }}
                                        className="btn w-24"
                                    >{textBtn}</button>
                                        :
                                        <button
                                            onClick={() => {
                                                action();
                                                setPopup(undefined);
                                            }}
                                            className="btn w-24"
                                        >{textBtn}</button>    
                            }
                            <button
                                onClick={() => setPopup(undefined)}
                                className="btn-cancel w-24"
                            >
                                Cancel
                            </button>
                        </div >
                    </div >
                </Modal.Body >
            </Modal >
        </>
    );
}

export default Popup;