import API from "utils/api";

export function refreshTokenService({ refresh_token, email, access_token }) {
    return API.post('/refresh-token', { refresh_token, email }, access_token !== undefined ? {
        headers: {
            Authorization: access_token !== undefined ? 'Bearer' + access_token : null
        }
    } : null)
}

export function getProfileService() {
    return API.get('/profile')
}

export function updateProfileService(data) {
    return API.post('/update-profile', data)
}

export function updatePasswordService(data) {
    return API.post('/change-password', data)
}

export function updateProfileImageService(data) {
    return API.post('/update-image-profile', data)
}

export function pusherRefreshTokenService(data) {
    return API.post('/channel/refresh-token', data)
}