import { useEffect, useRef } from "react";
import { formatterCurrency } from "utils/normalize";
import UploadIcon from "./icons/UploadIcon";

function Input({ type, isRequired, action, isMultiple, value = null, id = null, isCurrency, ...props }) {
    const refInput = useRef(null)

    useEffect(() => {
        if (isCurrency === true) {
            refInput.current.addEventListener("input", (e) => {
                var valueRef = refInput.current.value
                refInput.current.value = formatterCurrency(valueRef.replace(/[^\d]/g, ''))
                action(valueRef)
            })
            return () => {
                refInput.current?.removeEventListener("input", (e) => { })
            }
        }
    }, [isCurrency, action, refInput])

    useEffect(() => {
        if ((value !== null && value !== "") && isCurrency === true) {
            refInput.current.value = formatterCurrency(value.toString().replace(/[^\d]/g, ''))
        }
    }, [])

    const inputView = type === 'file' ?
        <div className={`flex items-center justify-between form-control bg-semigray mt-2 cursor-pointer ${value instanceof File ? 'valid-form' : ''}`}>
            <input
                id={id}
                onChange={(e) => {
                    action(e.target.files[0])
                }}
                type='file'
                required={isRequired}
                // value={value instanceof File ? value.name : 'No file choosen'}
                multiple={isMultiple}
                className="outline-none border-none ml-0 h-7 p-0 w-11/12 bg-inherit focus:ring-0 peer"
                accept="image/*"
            />
            <label htmlFor={id}>
                <UploadIcon />
            </label>
        </div>
        :
        <input
            ref={refInput}
            onChange={(e) => {
                if (type === "datetime-local") {
                    action(e.target.value.replace('T', ' '));
                } else {
                    action(e.target.value)
                }
            }}
            type={type}
            required={isRequired}
            value={isCurrency ? undefined : value}
            className={`mt-2 form-control bg-semigray ${value != null ? `valid-form` : ``}`}
            id={id}
            {...props}
        />

    return inputView;
}

export default Input;