import Header from 'components/Header';
import ButtonPill from 'components/atoms/ButtonPill';
import ChangePassword from 'components/organism/profile/ChangePassword';
import FormProfile from 'components/organism/profile/FormProfile';
import Sidebar from 'components/organism/profile/Sidebar';
import { useState } from 'react';
import { useAuthStore } from 'store/auth';
import { shallow } from 'zustand/shallow';


export default function Profile() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const [formActive, setFormActive] = useState("form-profile")
    return (
        <div className=' min-h-screen bg-background'>
            <Header showGoback={true} className="py-5 px-14" />
            <section className=' px-[50px] py-5 grid grid-cols-12 gap-4'>
                <Sidebar profile={profile} />
                <div className=' col-span-9'>
                    <div className=' flex gap-4 mb-4'>
                        <ButtonPill label="Profile" type={formActive === "form-profile" ? "outline" : ''} onClick={() => setFormActive("form-profile")} />
                        <ButtonPill label="Change password" type={formActive === "form-change-password" ? "outline" : ''} onClick={() => setFormActive("form-change-password")} />
                    </div>
                    <div className=' w-full bg-white rounded-[20px] pt-10 pb-6 px-6 flex flex-col app-shadow2'>
                        {
                            formActive === "form-profile" ? <FormProfile profile={profile} /> : <ChangePassword />
                        }
                    </div>

                </div>
            </section>
        </div>
    )
}
