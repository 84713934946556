import React, { useReducer, useState } from 'react';
import BoothContext from './BoothContext';
import {
    GET_BOOTH_CATEGORY,
    ADD_BOOTH_CATEGORY,
    EDIT_BOOTH_CATEGORY,
    DELETE_BOOTH_CATEGORY,
    GET_PROPOSE_BOOTH_BY_BOOTH_CATEGORY,
    ADD_BOOTH,
    EDIT_BOOTH,
    DELETE_BOOTH,
    APPROVE_VENDOR_BY_BOOTH,
    REJECT_VENDOR_BY_BOOTH,
    PROPOSE_BOOTH_TO_VENDOR,
    SET_ACTIVE_BOOTH,
    CHECK_PAYMENT,
    APPROVE_PAYMENT,
    REJECT_PAYMENT,
    CHANGE_ACTIVE_BOOTH,
    SET_WINNER_OF_WHEEL,
    CHANGE_BOOTH_CATEGORY,
    GET_BOOTH_BY_CATEGORY,
} from 'types/BoothTypes';
import BoothReducer from 'reducer/BoothReducer';
import API from 'utils/api';
import { handleError } from 'helpers/HandleRequestApi';

const BoothState = ({ children }) => {
    const initialState = {
        categoryBooth: [],
        booth: [],
        proposeBooth: [],
        activeBooth: {},
        boothPayment: {},
        isLoading: false,
    };
    const [state, dispatch] = useReducer(BoothReducer, initialState);
    const [categoryBoothId, setCategoryBoothId] = useState(null);
    // const [activeBooth, setActiveBooth] = useState({});
    const [categoryName, setCategoryName] = useState(null);
    const [categoryMap, setCategoryMap] = useState(null);
    const [boothNumber, setBoothNumber] = useState(null);
    const [boothPrice, setBoothPrice] = useState(null);
    const [description, setDescription] = useState(null);
    const [boothImage, setBoothImage] = useState(null);
    const [boothId, setBoothId] = useState(null);
    const [proposeReason, setProposeReason] = useState(null);
    const [bookingBoothId, setBookingBoothId] = useState(null);
    const [proposeBoothId, setProposeBoothId] = useState(null);
    const [rejectedBoothId, setRejectedBoothId] = useState(null);
    const [reasonReject, setReasonReject] = useState(null);

    const {
        booth,
        categoryBooth,
        proposeBooth,
        activeBooth,
        isLoading,
        boothPayment,
    } = state;

    const getCategoryBooth = async id => {
        return API.get(`/booth-category/${id}`)
            .then(ress => {
                dispatch({
                    type: GET_BOOTH_CATEGORY,
                    payload: ress.data,
                });

                return ress;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const editBoothCategory = async data => {
        return API.post(`booth-category-update`, data)
            .then(res => {
                dispatch({
                    type: EDIT_BOOTH_CATEGORY,
                    payload: res.data.data,
                });

                return res;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const deleteBoothCategory = async id => {
        return API.delete(`category/booth/delete/${id}`)
            .then(res => {
                dispatch({
                    type: DELETE_BOOTH_CATEGORY,
                    payload: id,
                });

                return res;
            })
            .catch(err => {
                handleError(err.response);
                return err;
            });
    };

    const changeBoothCategory = idBoothCategory => {
        dispatch({
            type: CHANGE_BOOTH_CATEGORY,
            payload: idBoothCategory,
        });
    };

    const getBoothByCategory = async idBoothCategory => {
        return API.get(`booth/${idBoothCategory}`)
            .then(ress => {
                dispatch({
                    type: GET_BOOTH_BY_CATEGORY,
                    payload: ress.data.data,
                });

                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response;
            });
    };

    const createCategoryBooth = async data => {
        return API.post('booth-category', data)
            .then(ress => {
                dispatch({
                    type: ADD_BOOTH_CATEGORY,
                    payload: ress.data.data,
                });
                return 'OK';
                // setActiveBooth(ress.data);
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const editBooth = async data => {
        return API.post('booth-update', data)
            .then(ress => {
                dispatch({
                    type: EDIT_BOOTH,
                    payload: ress.data,
                });
                // setActiveBooth(prevBooth => {
                //     console.log(ress.data.data);
                //     let listBooth = prevBooth.list_booth.map(lb => {
                //         if (lb.id === ress.data.data.id) {
                //             return ress.data.data;
                //         }

                //         return lb;
                //     });

                //     listBooth.sort((a, b) => a.number - b.number);

                //     prevBooth.list_booth = listBooth;

                //     return prevBooth;
                // });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
                return 'Not OK';
            });
    };

    const createBooth = async data => {
        return API.post('booth', data)
            .then(ress => {
                dispatch({
                    type: ADD_BOOTH,
                    payload: ress.data,
                });
                // setActiveBooth(prevData => {
                //     const updatedData = [
                //         ...prevData.list_booth,
                //         ress.data.data,
                //     ];
                //     prevData.list_booth = updatedData.sort(
                //         (a, b) => a.number - b.number
                //     );

                //     return prevData;
                // });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
                return 'Not OK';
            });
    };

    const deleteBooth = async () => {
        return API.delete(`booth/delete/${boothId}`)
            .then(ress => {
                dispatch({
                    type: DELETE_BOOTH,
                    payload: boothId,
                });
                // setActiveBooth(prevBooth => {
                //     const updateBooth = prevBooth.list_booth.filter(
                //         lb => lb.id !== boothId
                //     );

                //     prevBooth.list_booth = updateBooth;

                //     return prevBooth;
                // });
                setBoothNumber(null);

                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
                return 'Not OK';
            });
    };

    const clearCategoryBoothForm = () => {
        setCategoryMap(null);
        setCategoryName(null);
    };

    const clearBoothForm = () => {
        setBoothImage(null);
        setBoothNumber(null);
        setBoothPrice(null);
        setDescription(null);
    };

    const getProposeBooth = async data => {
        return API.post(`booth/propose`, data)
            .then(ress => {
                const dataProposeBooth = ress.data.map(data => {
                    data.label = data.number;
                    data.value = data.id;
                    return data;
                });
                dispatch({
                    type: GET_PROPOSE_BOOTH_BY_BOOTH_CATEGORY,
                    payload: dataProposeBooth,
                });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const acceptVendor = async data => {
        return API.post(`booth-acception`, data)
            .then(ress => {
                dispatch({
                    type: APPROVE_VENDOR_BY_BOOTH,
                    payload: ress.data.data,
                });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const rejectVendor = async data => {
        return API.post(`booth-rejection`, data)
            .then(res => {
                dispatch({
                    type: REJECT_VENDOR_BY_BOOTH,
                    payload: res.data.data,
                });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const proposeBoothAction = async data => {
        return API.post(`booth-propose`, data)
            .then(res => {
                dispatch({
                    type: PROPOSE_BOOTH_TO_VENDOR,
                    payload: res.data,
                });
                return res;
            })
            .catch(err => {
                handleError(err.response);

                return err;
            });
    };

    const setActiveBooth = boothData => {
        dispatch({
            type: SET_ACTIVE_BOOTH,
            payload: boothData,
        });
    };

    const checkPayment = async boothId => {
        return API.get(`booking-booth/${boothId}`)
            .then(ress => {
                dispatch({
                    type: CHECK_PAYMENT,
                    payload: ress.data.data,
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const approvePayment = async data => {
        return API.post(`accept/payment`, data)
            .then(ress => {
                dispatch({
                    type: APPROVE_PAYMENT,
                    payload: ress.data.data,
                });
                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const rejectPayment = async data => {
        return API.post(`reject/payment`, data)
            .then(ress => {
                dispatch({
                    type: REJECT_PAYMENT,
                    payload: ress.data.data,
                });
                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const handleChangeBooth = id => {
        dispatch({
            type: CHANGE_ACTIVE_BOOTH,
            payload: id,
        });
    };

    const handleWinnerOfBooth = async data => {
        return API.post(`scramble`, data)
            .then(ress => {
                dispatch({
                    type: SET_WINNER_OF_WHEEL,
                    payload: ress.data.data,
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    return (
        <BoothContext.Provider
            value={{
                rejectedBoothId,
                booth,
                isLoading,
                proposeBooth,
                activeBooth,
                boothPayment,
                // activeBooth,
                categoryName,
                categoryMap,
                boothNumber,
                boothPrice,
                description,
                boothImage,
                boothId,
                categoryBoothId,
                proposeReason,
                bookingBoothId,
                proposeBoothId,
                categoryBooth,
                reasonReject,
                editBoothCategory,
                deleteBoothCategory,
                setReasonReject,
                approvePayment,
                rejectPayment,
                setRejectedBoothId,
                setProposeBoothId,
                setBookingBoothId,
                setProposeReason,
                setCategoryBoothId,
                setBoothId,
                editBooth,
                setBoothImage,
                setBoothNumber,
                setBoothPrice,
                setDescription,
                setCategoryName,
                setCategoryMap,
                setActiveBooth,
                getCategoryBooth,
                clearCategoryBoothForm,
                createCategoryBooth,
                createBooth,
                clearBoothForm,
                deleteBooth,
                getProposeBooth,
                acceptVendor,
                rejectVendor,
                proposeBoothAction,
                checkPayment,
                handleChangeBooth,
                handleWinnerOfBooth,
                changeBoothCategory,
                getBoothByCategory,
            }}
        >
            {children}
        </BoothContext.Provider>
    );
};

export default BoothState;
