export function fontSizeSpin(length) {
    if (length < 10) {
        return 20;
    } else if (length < 20) {
        return 20;
    } else if (length < 30) {
        return 15;
    } else if (length < 40) {
        return 14;
    } else if (length < 50) {
        return 13;
    } else if (length < 60) {
        return 12;
    } else if (length < 70) {
        return 11;
    } else if (length < 80) {
        return 10;
    } else if (length < 90) {
        return 9;
    } else if (length < 100) {
        return 8;
    } else if (length < 110) {
        return 7;
    } else if (length < 120) {
        return 6;
    } else if (length < 130) {
        return 5;
    } else if (length < 140) {
        return 5;
    } else if (length < 150) {
        return 5;
    } else if (length < 160) {
        return 5;
    } else if (length < 170) {
        return 5;
    } else if (length < 180) {
        return 5;
    } else if (length < 190) {
        return 4;
    } else if (length < 200) {
        return 4;
    } else if (length < 210) {
        return 4;
    } else if (length < 220) {
        return 4;
    } else if (length < 230) {
        return 3;
    } else if (length < 240) {
        return 3;
    } else if (length < 250) {
        return 3;
    } else if (length < 260) {
        return 3;
    } else if (length < 270) {
        return 3;
    } else if (length < 280) {
        return 2;
    } else if (length < 290) {
        return 2;
    } else if (length < 300) {
        return 2;
    } else if (length < 310) {
        return 3;
    } else if (length < 320) {
        return 2;
    }
    return 2;
}
export function gapText(length) {
    var jarak = 90;
    if (length <= 20) {
        jarak = 88.1;
    } else if (length <= 40) {
        jarak = 88.3;
    } else if (length <= 50) {
        jarak = 88.8;
    } else if (length <= 60) {
        jarak = 88.8;
    } else if (length <= 70) {
        jarak = 88.9;
    } else if (length <= 80) {
        jarak = 88.9;
    } else if (length <= 90) {
        jarak = 89.2;
    } else if (length <= 100) {
        jarak = 89.3;
    } else if (length <= 110) {
        jarak = 89.3;
    } else if (length <= 120) {
        jarak = 89.3;
    } else if (length <= 130) {
        jarak = 89.4;
    } else if (length <= 140) {
        jarak = 89.4;
    } else if (length <= 150) {
        jarak = 89.5;
    } else if (length <= 160) {
        jarak = 89.6;
    } else if (length <= 170) {
        jarak = 89.5;
    } else if (length <= 180) {
        jarak = 89.6;
    } else if (length <= 190) {
        jarak = 89.6;
    } else if (length <= 300) {
        jarak = 89.6;
    } else if (length <= 400) {
        jarak = 89.6;
    } else if (length <= 500) {
        jarak = 89.8;
    }
    return jarak;
}

export function getLengtText(fontsize) {
    var end = 20;
    if (fontsize === 10) {
        end = 90;
    } else if (fontsize === 20) {
        end = 15;
    } else if (fontsize === 15) {
        end = 18;
    } else if (fontsize === 14) {
        end = 18;
    } else if (fontsize === 13) {
        end = 18;
    } else if (fontsize === 12) {
        end = 18;
    } else if (fontsize === 11) {
        end = 18;
    } else if (fontsize === 10) {
        end = 18;
    } else if (fontsize === 9) {
        end = 20;
    } else if (fontsize === 8) {
        end = 20;
    } else if (fontsize === 7) {
        end = 25;
    } else if (fontsize === 6) {
        end = 25;
    } else if (fontsize === 5) {
        end = 25;
    } else if (fontsize === 4) {
        end = 25;
    } else if (fontsize === 3) {
        end = 25;
    } else if (fontsize === 2) {
        end = 25;
    }
    return end;
}
