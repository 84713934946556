import { useMutation } from '@tanstack/react-query'
import Trash2 from 'components/icons/Trash2'
import { handleError } from 'helpers/HandleRequestApi'
import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { createChoiceQuestionService, deleteChoiceQuestionService, updateChoiceQuestionService } from 'services/form_admin'
import Swal from 'sweetalert2'

export default function Choices({ choices, enableEdit, handleChange, question_id, handleDeleteChoiceState }) {
    const choiceSelectDelete = useRef()

    const deleteMutation = useMutation({
        mutationFn: async () => {
            const response = await deleteChoiceQuestionService(choiceSelectDelete.current.id)
            return response.data
        },
        onSuccess: (data) => {
            handleDeleteChoiceState({ id_choice: choiceSelectDelete.current.id })
            toast.success("Delete choice success")
        },
        onError: (err) => {
            handleError(err.response)
        }
    })

    const handleDeleteChoice = (choice) => {
        choiceSelectDelete.current = choice
        Swal.fire({
            icon: 'question',
            html: `Are you sure for delete choice ${choiceSelectDelete.current.choice}?`,
            showCancelButton: true,
            showConfirmButton: true,
        }).then((ress) => {
            if (ress.isConfirmed) {
                deleteMutation.mutate()
            }
        })
    }


    const updateMutation = useMutation({
        mutationFn: async () => {
            const formData = { 'form_choice_id': choiceSelectDelete.current.id, 'choice': choices.find((choice) => choice.id === choiceSelectDelete.current.id)?.choice ?? '' }
            const response = await updateChoiceQuestionService(formData)
            return response.data
        },
        onSuccess: (data) => {
            toast.success("Update choice success")
        },
        onError: (err) => {
            handleError(err.response)
        }

    })
    const handleSubmit = (e) => {
        e.preventDefault()
        updateMutation.mutate()

    }
    return (
        <div className=' flex flex-col gap-2'>
            {
                choices.map((choice) => {
                    return <form onSubmit={handleSubmit} className={`flex border ${enableEdit ? 'border-default' : 'border-moregray'}  rounded-[10px] overflow-hidden`} key={choice.id}>
                        <input
                            required
                            onKeyDown={() => {
                                choiceSelectDelete.current = choice
                            }}
                            disabled={!enableEdit} value={choice.choice} onChange={(e) => handleChange({ id: question_id, key: 'choices', value: choices.map((c) => c.id === choice.id ? ({ ...c, choice: e.target.value }) : c) })}
                            placeholder='Option' type="text" className=' grow focus:border-transparent focus:ring-0 border-0 text-base font-medium tracking-[0.48px]' />
                        <button className=' px-6 py-4' type='button' disabled={!enableEdit} onClick={() => handleDeleteChoice(choice)}>
                            <Trash2 className=" fill-appblack" />
                        </button>
                    </form>
                })
            }

        </div>
    )
}
