import useGetSourceWithCredential from 'hook/useGetSourceWithCredential';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from 'utils/api';

function DownloadFile({ path, token_param, url, ...props }) {
    const { invoice_id } = useParams();
    const { src, setSrc, isLoading } = useGetSourceWithCredential({
        path,
        token_param,
    });

    const downloadFile = () => {
        const url = url;
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Invoice.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const FD = new FormData();

        FD.append('invoice_id', invoice_id);

        return API.post(`export/invoice`, FD);
    }, []);

    return (
        <div>
            <button onClick={downloadFile}>Download File</button>
        </div>
    );
}

export default DownloadFile;
