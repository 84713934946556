import SettingItem from "components/atoms/SettingItem";
import BankAccount from "components/organism/event/settings/BankAccount";
import CustomNotif from "components/organism/event/settings/CustomNotif";
import PrivacyPolicy from "components/organism/event/settings/PrivacyPolicy";
import TermAndConditions from "components/organism/event/settings/TermAndConditions";
import { useState } from "react";
import { useParams } from "react-router-dom";

function EventSettings() {
    const { event_id } = useParams()
    const ListMenu = [
        'Bank Account',
        'Terms & Conditions',
        'Privacy Policy',
        'Custom Notification'
    ]
    const [menuActive, setMenuActive] = useState(ListMenu[0])

    return (
        <div className=" px-5 mt-10 flex gap-5">
            <div className=" flex min-w-[230px] flex-col gap-[10px]">
                {
                    ListMenu.map((item) => {
                        return <SettingItem label={item} isActive={item === menuActive} key={item} onClick={() => setMenuActive(item)} />
                    })
                }
            </div>
            <div className=" grow" >
                {
                    menuActive === ListMenu[0] ?
                        <BankAccount event_id={event_id} />
                        : menuActive === ListMenu[1] ?
                            <TermAndConditions event_id={event_id} type={ListMenu[1]} />
                            : menuActive === ListMenu[2] ?
                                <PrivacyPolicy event_id={event_id} type={ListMenu[2]} />
                                : menuActive === ListMenu[3] ?
                                    <CustomNotif event_id={event_id} />
                                    :
                                    null
                }
            </div>
        </div>
    );
}

export default EventSettings;