import Arrow from 'components/icons/Arrow';
import PropTypes from 'prop-types';

export default function SettingItem({ label, isActive, onClick }) {
    return (
        <button className=" flex w-full items-center justify-center py-[14px] border-b-2 border-appgray" onClick={onClick}>
            <span className={`grow text-left text-base font-medium ${isActive ? 'text-default' : ' text-appblack'}`}>{label}</span>
            <Arrow className={isActive ? "fill-default" : ''} />
        </button>
    )
}


SettingItem.propTypes = {
    label: PropTypes.string,
    isActive: PropTypes.bool
}