import React, { useReducer, useState } from 'react';
import NewEventContext from './NewEventContext';
import EventReducer from 'reducer/EventReducer';
import {
    GET_EVENT,
    ADD_EVENT,
    EDIT_EVENT,
    DELETE_EVENT,
    RESET_EVENT,
    DUPLICATE_EVENT,
    GET_EVENT_HISTORY,
    STERIL_EVENT,
} from 'types/EventTypes';
import API from 'utils/api';
import { handleError } from 'helpers/HandleRequestApi';

const EventState = ({ children }) => {
    const initialState = {
        events: [],
        historyEvents: [],
        loading: true,
    };
    const [isUsingCountDown, setIsUsingCountDown] = useState(false);
    const [releaseDate, setReleaseDate] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [venue, setVenue] = useState(null);
    const [tnc, setTnc] = useState(null);
    const [banner, setBanner] = useState(null);
    const [images, setImages] = useState([]);
    const [oldImages, setOldImages] = useState([]);
    const [guideLineFiles, setGuideLineFiles] = useState([]);
    const [oldFiles, setOldFiles] = useState([]);
    const [ytLink, setYtLink] = useState(null);
    const [ytLoading, setYtLoading] = useState(false);
    const [errorYtLink, setErrorYtLink] = useState(null);
    const [errorBanner, setErrorBanner] = useState(null);
    const [errorImages, setErrorImages] = useState(null);
    const [page, setPage] = useState(1);

    const [state, dispatch] = useReducer(EventReducer, initialState);

    const getEvents = async (status = 'cooming_soon') => {
        return API.get(`/event?status=${status}&page=${page}`)
            .then(ress => {
                const nextPage = ress.data.links.next.substring(
                    ress.data.links.next.length - 1,
                    ress.data.links.next.length
                );

                if (status === 'cooming_soon') {
                    dispatch({
                        type: GET_EVENT,
                        payload: ress.data.data.items,
                    });
                } else {
                    dispatch({
                        type: GET_EVENT_HISTORY,
                        payload: ress.data.data.items,
                    });
                }

                if (ress.data.links.next !== '') {
                    setPage(nextPage);
                } else {
                }

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const sterilEvent = () => {
        dispatch({ type: STERIL_EVENT, payload: initialState });
    };

    const createEvent = data => {
        API.post('/event', data)
            .then(ress => {
                dispatch({ type: ADD_EVENT, payload: ress.data });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const editEvent = data => {
        API.post('/event-update', data)
            .then(ress => {
                dispatch({ type: EDIT_EVENT, payload: ress.data });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const deleteEvent = data => {
        API.delete(`event/delete/${eventId}`)
            .then(ress => {
                dispatch({ type: DELETE_EVENT, payload: eventId });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const duplicateEvent = data => {
        const FD = new FormData();
        FD.append('event_id', eventId);
        API.post('/event/replicate', FD)
            .then(ress => {
                dispatch({ type: DUPLICATE_EVENT, payload: ress.data });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const resetEvent = data => {
        const FD = new FormData();
        FD.append('event_id', eventId);
        API.post('/event/reset', FD)
            .then(ress => {
                dispatch({ type: RESET_EVENT, payload: ress.data });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const { events, historyEvents, loading } = state;

    const cleanUpEventForm = () => {
        setTitle(null);
        setDescription(null);
        setStartDate(null);
        setEndDate(null);
        setVenue(null);
        setTnc(null);
        setBanner(null);
        setImages([]);
        setYtLink(null);
        setErrorYtLink(null);
        setErrorBanner(null);
        setErrorImages(null);
        setGuideLineFiles([]);
    };

    const handleChangeBanner = e => {
        if (e.target.files[0] !== undefined) {
            const image = new Image();
            image.onload = () => {
                if (image.width < 1200 && image.height < 450) {
                    setErrorBanner(
                        'Your image is under of our minimal dimension'
                    );
                    setBanner(null);
                } else {
                    setBanner(e.target.files[0]);
                    setErrorBanner(null);
                }
            };
            image.src = URL.createObjectURL(e.target.files[0]);
        } else {
            setBanner(null);
            setErrorBanner(null);
        }
    };

    const handleChangeImages = e => {
        const images = Array.from(e.target.files);

        if (images.length > 5) {
            setErrorImages('* Your inputed more than 5 images');
            setImages([]);
            return;
        }

        setErrorImages(null);
        setImages(images);
    };

    const handleChangeGuideLineFiles = e => {
        const images = Array.from(e.target.files);
        setGuideLineFiles(images);
    };

    const handleRemoveImagesItem = image => {
        setImages(prevFile => {
            return prevFile.filter(file => {
                return file.name !== image.name;
            });
        });
    };

    const handleRemoveFilesItem = image => {
        setGuideLineFiles(prevFile => {
            return prevFile.filter(file => {
                return file.name !== image.name;
            });
        });
    };

    const handleResetData = () => {
        setTitle(null);
        setDescription(null);
        setStartDate(null);
        setEndDate(null);
        setIsUsingCountDown(false);
        setReleaseDate(null);
        setTnc(null);
        setVenue(null);
        setBanner(null);
        setImages([]);
        setGuideLineFiles([]);
        setYtLink(null);
    };

    return (
        <NewEventContext.Provider
            value={{
                events,
                initialState,
                historyEvents,
                loading,
                title,
                startDate,
                endDate,
                venue,
                banner,
                images,
                description,
                tnc,
                ytLink,
                errorBanner,
                errorImages,
                ytLoading,
                errorYtLink,
                eventId,
                selectedEvent,
                guideLineFiles,
                page,
                oldImages,
                oldFiles,
                releaseDate,
                isUsingCountDown,
                setIsUsingCountDown,
                setReleaseDate,
                setOldImages,
                setOldFiles,
                setGuideLineFiles,
                setPage,
                setErrorYtLink,
                getEvents,
                createEvent,
                editEvent,
                setTitle,
                setDescription,
                setStartDate,
                setEndDate,
                setVenue,
                sterilEvent,
                setImages,
                setYtLink,
                setTnc,
                setBanner,
                setErrorBanner,
                setErrorImages,
                handleChangeBanner,
                handleChangeImages,
                handleRemoveImagesItem,
                setYtLoading,
                cleanUpEventForm,
                setEventId,
                deleteEvent,
                setSelectedEvent,
                duplicateEvent,
                resetEvent,
                handleRemoveFilesItem,
                handleChangeGuideLineFiles,
                handleResetData,
            }}
        >
            {children}
        </NewEventContext.Provider>
    );
};

export default EventState;
