import DownloadFile from "helpers/DownloadFile";

function DownloadInvoice() {
    return (
        <>
            <DownloadFile />
        </>
    );
}

export default DownloadInvoice;