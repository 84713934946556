export const normalizeInputPhone = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    return currentValue
    const cvLength = currentValue.length;

    if (cvLength <= 4) return currentValue;
    if (cvLength <= 8) return `${currentValue.slice(0, 4)}${currentValue.slice(4)}`;
    return `${currentValue.slice(0, 4)}${currentValue.slice(4, 8)}${currentValue.slice(8, 12)}`;
};

export function formatValueMoney(value) {
    if (value < 1000) {
        return value;
    } else if (value < 10000) {
        return (value / 1000).toFixed(0) + ' rb';
    } else if (value < 100000) {
        return (value / 1000).toFixed(0) + ' rb';
    } else if (value < 1000000) {
        return (value / 1000).toFixed(0) + ' rb';
    } else if (value < 10000000) {
        return (value / 1000000).toFixed(0) + ' jt';
    } else if (value < 100000000) {
        return (value / 1000000).toFixed(0) + ' jt';
    } else if (value < 1000000000) {
        return (value / 1000000).toFixed(0) + ' jt';
    } else if (value < 10000000000) {
        return (value / 1000000000).toFixed(0) + ' M';
    } else if (value < 100000000000) {
        return (value / 1000000000).toFixed(0) + ' M';
    } else if (value < 1000000000000) {
        return (value / 1000000000).toFixed(0) + ' M';
    }
    return value;
}

export function formatRupiah(amount) {
    return 'Rp. ' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ',-';
}

export function formatterCurrency(amount) {
    if (amount === "") {
        return ''
    }
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(amount)
}