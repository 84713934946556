const { create } = require("zustand");

const eventDetailInitial = {
    id: "",
    name: "",
    location: "",
    start_date: "",
    end_date: "",
    status: "",
    list_member: [],
    list_img: [],
    tnc: "",
    description: "",
    bank_name: "",
    bank_number: "",
    bank_owner_name: "",
}
const useEventStore = create((set) => ({
    eventDetail: eventDetailInitial,
    updateEventDetail: (data) => {
        return set((state) => ({ ...state, eventDetail: data }))
    }
}))

export default useEventStore