import { handleError } from 'helpers/HandleRequestApi';
import { useReducer, useState } from 'react';
import PurchaseReducer from 'reducer/PurchaseReducer';
import {
    GET_PURCHASE,
    ADD_PURCHASE,
    EDIT_PURCHASE,
    DELETE_PURCHASE,
    GET_PURCHASE_OUT,
    ADD_PURCHASE_OUT,
    CANCEL_PURCHASE,
    CANCEL_PURCHASE_OUT,
} from 'types/PurchaseType';
import API from 'utils/api';
import PurchaseContext from './PurchaseContext';

const PurchaseState = ({ children }) => {
    const initialState = {
        purchase: [],
        totalPage: 1,
        currentPage: 1,
    };
    const [state, dispatch] = useReducer(PurchaseReducer, initialState);

    const getPurchaseData = async (id, page = 1) => {
        return API.get(`purchase?event_id=${id}&page=${page}`)
            .then(ress => {
                dispatch({
                    type: GET_PURCHASE,
                    payload: ress.data,
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const addPurchase = async data => {
        return API.post(`purchase`, data)
            .then(ress => {
                dispatch({
                    type: ADD_PURCHASE,
                    payload: ress.data,
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const editPurchase = async (data, id) => {
        return API.post(`purchase/${id}`, data)
            .then(ress => {
                dispatch({
                    type: EDIT_PURCHASE,
                    payload: ress.data,
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
                return err.response;
            });
    };

    const deletePurchase = async id => {
        return API.delete(`purchase/${id}`)
            .then(ress => {
                dispatch({
                    type: DELETE_PURCHASE,
                    payload: ress.data.data,
                });

                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response;
            });
    };

    const cancelPurchaseOut = async id => {
        return API.delete(`purchase-out/${id}`)
            .then(ress => {
                dispatch({
                    type: CANCEL_PURCHASE_OUT,
                    payload: ress.data.data,
                });

                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response;
            });
    };

    const { purchase, totalPage, currentPage } = state;

    return (
        <PurchaseContext.Provider
            value={{
                purchase,
                totalPage,
                currentPage,
                getPurchaseData,
                addPurchase,
                editPurchase,
                deletePurchase,
            }}
        >
            {children}
        </PurchaseContext.Provider>
    );
};

export default PurchaseState;
