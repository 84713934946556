import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { handleError } from 'helpers/HandleRequestApi'
import { useEffect, useState } from 'react'
import { getEventTncService, postEventTncService } from 'services/settings'
import Swal from 'sweetalert2'
import FormTnc from './FormTnc'

export default function TermAndConditions({ event_id, type }) {
    const tncQuery = useQuery({
        queryFn: () => getEventTncService(event_id),
        queryKey: ['event-tnc', event_id],
        select: (data) => {
            return data.data
        }
    })

    const queryClient = useQueryClient()
    const [formValue, setFormValue] = useState({
        link: '',
        tnc: '',
        wantToWriteChecked: false
    })

    useEffect(() => {
        if (tncQuery.data !== undefined) {
            setFormValue({
                link: tncQuery.data?.data?.tnc_url,
                tnc: tncQuery.data?.data?.tnc,
                wantToWriteChecked: tncQuery.data?.data?.tnc_url === ""
            })
        }
    }, [tncQuery.data])


    const handleSubmit = (e) => {
        e.preventDefault()
        submitMutaion.mutate()
    }

    const submitMutaion = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            if (event_id !== undefined) {
                formData.append('event_id', event_id)
            }

            formData.append("tnc", formValue.tnc)
            formData.append("tnc_url", formValue.link)
            const response = await postEventTncService(formData, true)
            return response.data
        },
        onSuccess(ress) {
            Swal.fire({
                icon: 'success',
                text: 'Update tnc success',
                timer: 3000
            })
            queryClient.invalidateQueries(['event-tnc', event_id])
        },
        onError(data) {
            handleError(data.response)
        }
    })
    return (
        <FormTnc
            handleSubmit={handleSubmit}
            formValue={formValue}
            setFormValue={setFormValue}
            isLoading={submitMutaion.isLoading || tncQuery.status === "loading"}
            type={type}
        />
    )
}
