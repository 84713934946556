import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialAuth = {
    access_token: '',
    expires_in: null,
    refresh_token: '',
    status: '',
    email: '',
};
export const initialProfile = {
    email: '',
    fullname: '',
    id: '',
    img_url: '',
    phone_number: '',
    permissions: [],
};
export const useAuthStore = create()(
    persist(
        (set, get) => ({
            auth: initialAuth,
            profile: initialProfile,
            updateAuth: newAuth => set(prev => ({ ...prev, auth: newAuth })),
            updateProfile: newProfile =>
                set(prev => ({ ...prev, profile: newProfile })),
            logout: () =>
                set({
                    auth: initialAuth,
                    profile: initialAuth,
                }),
        }),
        {
            name: 'auth-zustand',
            serialize: state => btoa(JSON.stringify(state)),
            deserialize: str => JSON.parse(atob(str)),
        }
    )
);
