import { useQuery } from "@tanstack/react-query";
import FormQuestion from "components/organism/event/question/FormQuestion";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFormQuestionByFormIdService } from "services/form_admin";

function EventQuestions() {
    const { form_id } = useParams()
    // const initialQuestion = { question: '', questionType: '', id: new Date().getTime(), choices: [], field_type: '' }
    const [questions, setQuestions] = useState([])
    const formQuestionQuery = useQuery({
        queryFn: () => getFormQuestionByFormIdService(form_id),
        queryKey: ['form-question-detail', form_id],
        select(data) {
            return data.data
        }
    })

    useEffect(() => {
        if (formQuestionQuery.data !== undefined) {
            var response = formQuestionQuery.data?.data?.questions.map((question) => ({ ...question, enableEdit: false })) ?? []
            setQuestions(response)
        }
    }, [formQuestionQuery.data])


    const handleChange = useCallback(({ id, key, value }) => {
        setQuestions(p => p.map((question) => question.id === id ? ({ ...question, [key]: value }) : question))
    }, [])
    const handleUpdateQuestion = useCallback(({ new_question }) => {
        setQuestions(p => p.map((question) => question.id === new_question.id ? ({ ...question, ...new_question }) : p))
    }, [])

    const handleEdit = useCallback(({ question }) => {
        setQuestions(p => {
            return p.map((q) => q.id === question.id ? ({ ...q, enableEdit: !q.enableEdit }) : q)
        })
    }, [])

    return (
        <div className=" flex flex-col gap-5 mr-[20px] ml-2 pb-5">
            {
                formQuestionQuery.status === "loading" ?
                    <p>Loading...</p>
                    : formQuestionQuery.status === "success" && questions.length > 0 ?
                        questions.map((question, index) => {
                            return <FormQuestion
                                key={question.id}
                                question={question}
                                handleChange={(data) => handleChange(data)}
                                handleEdit={handleEdit}
                                question_length={questions.length}
                                form_id={formQuestionQuery.data?.data?.id ?? ''}
                                handleUpdateQuestion={handleUpdateQuestion}
                                setQuestions={setQuestions}
                                disableWrite={index === 0}
                                formIsActive={formQuestionQuery.data?.data?.is_active ?? 0}
                            />
                        })
                        : <div>
                            <p>Empty question</p>
                        </div>
            }

        </div>
    );
}

export default EventQuestions;