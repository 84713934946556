function Angle({ color, width, height, rotate }) {
    return (
        <>
            <div style={{
                rotate: rotate + 'deg',
            }}>
                <svg width={width} height={height} viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg" className="!fill-none">
                    <path d="M1 1L7.5 7L14 1" stroke={color} strokeWidth="2" strokeLinecap="round" />
                </svg>
            </div>
        </>
    );
}

export default Angle;