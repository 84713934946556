import React from "react";
import ilustration from "../../assets/images/ilustration.svg";
import vincologo from "../../assets/images/vincoo-logo.svg";
import Logo from "components/icons/Logo";

function Authenticate(props) {
  return ( 
    // <div className="flex">
    <div className="bg-default flex items-center justify-center h-screen md:flex lg:flex xl:flex">
      {/* <div className="bg-default w-1/2 h-screen flex justify-center items-center "> */}
      <div className="hidden md:bg-default md:w-1/2 md:h-screen md:flex md:justify-center lg:bg-default lg:items-center lg:w-1/2 lg:h-screen lg:flex lg:justify-center xl:bg-default xl:w-1/2 xl:h-screen xl:flex xl:justify-center xl:items-center">
        <img src={ ilustration } alt="Human colaboration" />
      </div>
      {/* <div className="w-1/2 h-screen flex flex-col items-center justify-center"> */}
      <div className="w-4/5 h-4/5 rounded bg-background px-6 py-28 md:w-1/2 md:h-screen md:flex md:flex-col md:items-center md:justify-center md:rounded-none lg:w-1/2 lg:h-screen lg:flex lg:flex-col lg:items-center lg:justify-center lg:rounded-none xl:w-1/2 xl:h-screen xl:flex xl:flex-col xl:items-center xl:justify-center xl:rounded-none">
        <div className=" flex items-center justify-center mb-16">
          {/* <img src={ vincologo } alt="Vinco logo" /> */}
          <Logo />
        </div>
        <div className=" mx-auto">
          { props.children }
        </div>
      </div>
    </div>
  );
}

export default Authenticate;