import { useContext, useReducer } from "react";
import { ModalReducer } from "reducer/ModalReducer";
import { Modal } from "flowbite-react";
import { useEvent } from "context/EventContext";
import BannerImage from "../BannerImage";
import ilustration from "../../assets/images/ilustration.svg";
import API from "utils/api";
import ModalContext from "context/ModalContext";
import FormCheckPayment from "components/form/FormCheckPayment";
import BoothContext from "context/BoothContext";
import Loading from "components/Loading";
import Checklist from "components/icons/Checklist";
import Popup from "./Popup";
import { toast } from 'react-toastify';
import Xicon from "components/icons/Xicon";
import TextAreaWithLabel from "components/atoms/TextAreaWithLabel";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function ModalDetailPayment({ loadingVendor }) {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { setModal, modal, setPopup } = useContext(ModalContext);
    const { boothPayment, boothNumber, rejectPayment, reasonReject, setReasonReject } = useContext(BoothContext);

    let btn = <></>;
    let msg = <></>;

    if (Object.keys(boothPayment).length > 0) {
        if (profile?.permissions !== undefined) {
            if (boothPayment.status === 'reject_payment') {
                btn = <>
                    <button className={`btn w-24 cursor-not-allowed`} disabled={true} onClick={() => {
                        document.getElementById('btn_approve_payment').click();
                    }}>Approve</button>
                    <button
                        className="bg-[#B91010] text-white rounded-full py-1 w-24 cursor-not-allowed"
                        disabled={true}
                        onClick={() => {
                            setPopup('reject_payment')
                        }}
                    >Reject</button>
                </>
                msg = <small className="text-moregray">* Waiting for approved payment from vendor</small>
            } else {
                if (boothPayment.payment_image !== null) {
                    btn = <>
                        {
                            checkPermission(profile?.permissions, 'approve payment booth') &&
                            <button className={`btn w-24`} onClick={() => {
                                document.getElementById('btn_approve_payment').click();
                            }}>Approve</button>
                        }
                        {
                            checkPermission(profile?.permissions, 'reject payment booth') &&
                            <button
                                className="bg-[#B91010] text-white rounded-full py-1 w-24"
                                onClick={() => {
                                    setPopup('reject_payment')
                                }}
                            >Reject</button>
                        }
                    </>
                } else {
                    btn = <>
                        {
                            checkPermission(profile?.permissions, 'approve payment booth') &&
                            <button className={`btn w-24 cursor-not-allowed`} disabled={true} onClick={() => {
                                document.getElementById('btn_approve_payment').click();
                            }}>Approve</button>
                        }
                        {
                            checkPermission(profile?.permissions, 'reject payment booth') &&
                            <button
                                className="bg-[#B91010] text-white rounded-full py-1 w-24 cursor-not-allowed"
                                disabled={true}
                                onClick={() => {
                                    setPopup('reject_payment')
                                }}
                            >Reject</button>
                        }
                    </>
                    msg = <small className="text-moregray">* Waiting for approved payment from vendor</small>
                }
            }
        }

    }

    return (
        <>
            <Modal
                position={'top-center'}
                show={modal === 'check_payment'}
                onClose={() => {
                    setModal(undefined)
                }}
                size={'lg'}
            >
                <div className="border-b-[1px] border-appgray">
                    <div className="px-6 py-5 flex justify-between items-center">
                        <h1 className="text-appblack text-xl font-semibold text-center" style={{ width: '-webkit-fill-available' }}>Check Payment ({boothNumber})</h1>
                        <button className=" text-4xl border-[1px] border-moregray w-[32px] h-[32px] flex items-center justify-center text-moregray rounded-full" onClick={() => setModal(undefined)}>
                            <p className="-mt-[4px]">
                                &times;
                            </p>
                        </button>
                    </div>
                </div>
                {
                    loadingVendor ?
                        <Loading />
                        :
                        <>
                            <Modal.Body className="space-y-2">
                                <FormCheckPayment
                                    dataPayment={boothPayment}
                                    element={msg}
                                />
                            </Modal.Body>
                            {
                                profile?.permissions !== undefined ?
                                    <>
                                        {
                                            checkPermission(profile?.permissions, 'approve payment booth') || checkPermission(profile?.permissions, 'reject payment booth') ?
                                                <>
                                                    {
                                                        boothPayment.status !== 'paid' &&
                                                        <Modal.Footer>
                                                            <div className="text-center w-full space-x-3">
                                                                {btn}
                                                            </div>
                                                        </Modal.Footer>
                                                    }
                                                </>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                        </>
                }
            </Modal>
            <Popup
                action={() => { }}
                idBtn={'btn-payment-reject'}
                icon={null}
                type="reject_payment"
                textBtn="Proceed"
            >
                <form onSubmit={async (e) => {
                    e.preventDefault();

                    const FD = new FormData();

                    FD.append('booking_has_booth_id', boothPayment.id);
                    FD.append('reason', reasonReject);

                    const reqApprovePayment = await rejectPayment(FD);

                    if (reqApprovePayment.success) {
                        toast.success('Success reject payment');
                        setModal(undefined);
                        setPopup(undefined);
                    }
                }}>
                    <h1 className="font-semibold text-2xl">Reject Payment</h1>
                    <TextAreaWithLabel
                        label={'Reason'}
                        classLabel={'text-left'}
                        className={'mb-2'}
                        onChange={(e) => setReasonReject(e.target.value)}
                        required={true}
                    />
                    <p className="my-5 text-base">After you reject this payment, make sure you tell the vendor to re-check payment or another thing</p>
                    <button type="submit" id="btn-payment-reject" hidden></button>
                </form>
            </Popup>
        </>
    );
}

export default ModalDetailPayment;