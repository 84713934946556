function ThreeDots({ rotate = 0 }) {
  return (
    <>
      <div style={{
        rotate: rotate + 'deg',
      }}>
      <svg width="17" height="5" viewBox="0 0 17 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7037 2.2037C10.7037 2.63955 10.5744 3.06561 10.3323 3.42801C10.0901 3.79041 9.74598 4.07286 9.3433 4.23965C8.94063 4.40645 8.49754 4.45009 8.07006 4.36506C7.64259 4.28003 7.24993 4.07014 6.94173 3.76195C6.63354 3.45376 6.42366 3.0611 6.33863 2.63362C6.2536 2.20614 6.29724 1.76305 6.46403 1.36038C6.63083 0.957707 6.91328 0.613536 7.27568 0.37139C7.63807 0.129245 8.06413 0 8.49999 0C9.08444 0 9.64496 0.232175 10.0582 0.645449C10.4715 1.05872 10.7037 1.61924 10.7037 2.2037ZM2.2037 0C1.76785 0 1.34179 0.129245 0.979391 0.37139C0.616994 0.613536 0.33454 0.957707 0.167748 1.36038C0.000954826 1.76305 -0.0426858 2.20614 0.0423445 2.63362C0.127375 3.0611 0.337257 3.45376 0.64545 3.76195C0.953643 4.07014 1.3463 4.28003 1.77378 4.36506C2.20126 4.45009 2.64435 4.40645 3.04702 4.23965C3.44969 4.07286 3.79386 3.79041 4.03601 3.42801C4.27816 3.06561 4.4074 2.63955 4.4074 2.2037C4.4074 1.61924 4.17523 1.05872 3.76195 0.645449C3.34868 0.232175 2.78816 0 2.2037 0ZM14.7963 0C14.3604 0 13.9344 0.129245 13.572 0.37139C13.2096 0.613536 12.9271 0.957707 12.7603 1.36038C12.5935 1.76305 12.5499 2.20614 12.6349 2.63362C12.7199 3.0611 12.9298 3.45376 13.238 3.76195C13.5462 4.07014 13.9389 4.28003 14.3664 4.36506C14.7938 4.45009 15.2369 4.40645 15.6396 4.23965C16.0423 4.07286 16.3864 3.79041 16.6286 3.42801C16.8707 3.06561 17 2.63955 17 2.2037C17 1.91431 16.943 1.62775 16.8322 1.36038C16.7215 1.09302 16.5592 0.850081 16.3545 0.645449C16.1499 0.440816 15.907 0.278493 15.6396 0.167746C15.3722 0.0570002 15.0857 0 14.7963 0Z" fill="#231F20"/>
        </svg>
      </div>
    </> 
  );
}

export default ThreeDots;