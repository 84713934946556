import Image from "./atoms/Image";

function ProfileImage({ img, width, height }) {
  return (
    <Image
      path={img}
      alt="user"
      style={
        {
          width: width,
          height: height,
        }
      }
      className={`img-profile object-cover`}
    />
  );
}

export default ProfileImage;