import React from 'react'

export default function IcUpload() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
            <path d="M2 13.9997C1.46957 13.9997 0.960859 13.7973 0.585786 13.4369C0.210714 13.0766 0 12.5878 0 12.0782V9.33305C3.38746e-09 9.11464 0.0903059 8.90517 0.251051 8.75073C0.411797 8.59629 0.629814 8.50952 0.857143 8.50952C1.08447 8.50952 1.30249 8.59629 1.46323 8.75073C1.62398 8.90517 1.71429 9.11464 1.71429 9.33305V12.0782C1.71429 12.2297 1.84229 12.3527 2 12.3527H14C14.0758 12.3527 14.1484 12.3237 14.202 12.2723C14.2556 12.2208 14.2857 12.151 14.2857 12.0782V9.33305C14.2857 9.11464 14.376 8.90517 14.5368 8.75073C14.6975 8.59629 14.9155 8.50952 15.1429 8.50952C15.3702 8.50952 15.5882 8.59629 15.7489 8.75073C15.9097 8.90517 16 9.11464 16 9.33305V12.0782C16 12.5878 15.7893 13.0766 15.4142 13.4369C15.0391 13.7973 14.5304 13.9997 14 13.9997H2Z" fill="#F77C32" />
            <path d="M12.32 3.80994C12.3996 3.88636 12.4627 3.97709 12.5057 4.07694C12.5488 4.1768 12.5709 4.28382 12.5709 4.3919C12.5709 4.49998 12.5488 4.607 12.5057 4.70685C12.4627 4.80671 12.3996 4.89744 12.32 4.97386C12.2405 5.05029 12.1461 5.11091 12.0421 5.15227C11.9382 5.19363 11.8268 5.21492 11.7143 5.21492C11.6018 5.21492 11.4904 5.19363 11.3865 5.15227C11.2826 5.11091 11.1881 5.05029 11.1086 4.97386L8.85718 2.81182V9.05858C8.85718 9.27699 8.76687 9.48646 8.60612 9.6409C8.44538 9.79535 8.22736 9.88211 8.00003 9.88211C7.7727 9.88211 7.55469 9.79535 7.39394 9.6409C7.2332 9.48646 7.14289 9.27699 7.14289 9.05858V2.81182L4.89146 4.97386C4.81192 5.05029 4.71749 5.11091 4.61356 5.15227C4.50963 5.19363 4.39824 5.21492 4.28575 5.21492C4.17326 5.21492 4.06187 5.19363 3.95794 5.15227C3.85401 5.11091 3.75958 5.05029 3.68003 4.97386C3.60049 4.89744 3.53739 4.80671 3.49434 4.70685C3.4513 4.607 3.42914 4.49998 3.42914 4.3919C3.42914 4.28382 3.4513 4.1768 3.49434 4.07694C3.53739 3.97709 3.60049 3.88636 3.68003 3.80994L7.39432 0.2413C7.47382 0.164805 7.56824 0.104121 7.67217 0.0627162C7.77611 0.0213118 7.88752 0 8.00003 0C8.11255 0 8.22396 0.0213118 8.32789 0.0627162C8.43183 0.104121 8.52624 0.164805 8.60575 0.2413L12.32 3.80994Z" fill="#F77C32" />
        </svg>
    )
}
