import Header from "../components/Header";
import { Dropdown } from "flowbite-react";
import { useEffect, useContext, useMemo, useState } from "react";
import TableComponent from "../components/Table";
import { createColumnHelper } from "@tanstack/react-table";
import ThreeDots from "components/icons/ThreeDots";
import Trash from "components/icons/Trash";
import ModalMember from "components/Modal/ModalMember";
import Popup from "components/Modal/Popup";
import ModalContext from "context/ModalContext";
import NewMemberContext from "context/NewMemberContext";
import { Link } from "react-router-dom";
import { MainsStack } from "routes";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { useMutation, useQuery } from "@tanstack/react-query";
import { activateTenantService, getTenantService } from "services/tenant";
import { handleError } from "helpers/HandleRequestApi";

function TenantGlobalPage() {
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1)
    const [dataTenant, setDataTenant] = useState([])
    const [tenant, setTenant] = useState({})

    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { setModal, setPopup } = useContext(ModalContext)

    const getTenantQuery = useQuery({
        queryKey: ['get-tenant', page],
        queryFn: () => {
            return getTenantService(page);
        }
    })

    const activateTenantMutation = useMutation({
        mutationKey: ['activate-tenant'],
        mutationFn: () => {
            const FD = new FormData();

            FD.append(`vendor_id`, tenant.id)

            return activateTenantService(FD)
        },
        onError: (error, variables, context) => {
            handleError(error)
        },
        onSuccess: (data, variables, context) => {
            getTenantQuery.refetch()
            toast.success(`Successfully ${tenant.is_active ? 'deactivated' : 'activated'} tenant`)
        },
    })

    useEffect(() => {
        console.log(getTenantQuery.data)
        if (getTenantQuery.data !== undefined) {
            setDataTenant(getTenantQuery.data.data.items)
            setTotalPage(getTenantQuery.data.meta.total_page)
        }
    }, [getTenantQuery])

    const columnHelper = createColumnHelper();

    const memberColumns = useMemo(
        () => [
            columnHelper.accessor('fullname', {
                header: 'Tenant Name',
            }),
            columnHelper.accessor('email', {
                header: 'Email',
            }),
            columnHelper.accessor('phone_number', {
                header: 'Whatsapp Number',
                cell: (info) => {
                    console.log(info.getValue())
                    if (info.renderValue() !== null) {
                        return info.renderValue()
                    }

                    return <p className="text-moregray">Not Set</p>
                }
            }),
            columnHelper.accessor('instagram', {
                header: 'Instagram Account',
                cell: (info) => {
                    if (info.getValue() !== null) {
                        return info.getValue()
                    }

                    return <p className="text-moregray">Not Set</p>
                }
            }),
            columnHelper.accessor('is_active', {
                header: 'Activate',
                cell: (info) => {
                    if (info.renderValue()) {
                        return (
                            <div className="flex items-center" onClick={() => {
                                handleActiveOrInActiveTenant(info.row.original)
                            }}>
                                <svg className="mr-2 fill-none" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.57143 0C1.15329 0 0 1.15329 0 2.57143V15.4286C0 16.8467 1.15329 18 2.57143 18H15.4286C16.8467 18 18 16.8467 18 15.4286V2.57143C18 1.15329 16.8467 0 15.4286 0H2.57143ZM2.57143 15.4286V2.57143H15.4286L15.4311 15.4286H2.57143Z" fill="#F77C32" />
                                    <path d="M6 9.46154L7.64706 11L13 6" stroke="#F77C32" stroke-width="2.5" stroke-linecap="round" />
                                </svg>
                                <label htmlFor={info.row.id} className="cursor-pointer">Actived</label>
                            </div>
                        )
                    }

                    return (
                        <div className="flex items-center" onClick={() => {
                            handleActiveOrInActiveTenant(info.row.original)
                        }}>
                            < svg className="mr-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M2.57143 0C1.15329 0 0 1.15329 0 2.57143V15.4286C0 16.8467 1.15329 18 2.57143 18H15.4286C16.8467 18 18 16.8467 18 15.4286V2.57143C18 1.15329 16.8467 0 15.4286 0H2.57143ZM2.57143 15.4286V2.57143H15.4286L15.4311 15.4286H2.57143Z" fill="#B5B5B5" />
                            </svg >
                            <label htmlFor={info.row.id} className="cursor-pointer">Deactivated</label>
                        </div>
                    )
                },
            }),
        ],
        []);

    const handleLoadMore = page => {
        setPage(page)
    }

    const handleActiveOrInActiveTenant = data => {
        setTenant(data)

        setPopup('activate_tenant')
    }

    return (
        <main className="bg-background min-h-screen">
            <Header showGoback={true} className="py-5 px-14" />
            <section className="px-14 py-5">
                <div className="card app-shadow">
                    <div className="card-header flex justify-between">
                        <h1>List Tenant</h1>
                    </div>
                    {
                        getTenantQuery.isLoading ?
                            <div className="flex justify-center h-60 items-center">
                                <Loading />
                            </div>
                            :
                            getTenantQuery.data !== undefined &&
                            <div className="card-body px-5">
                                <TableComponent
                                    data={dataTenant}
                                    columns={memberColumns}
                                    totalPage={totalPage}
                                    currentPage={page}
                                    showPagination={true}
                                    actionLoadPage={handleLoadMore}
                                />
                            </div>
                    }
                </div>
            </section>
            <Popup
                action={() => {
                    activateTenantMutation.mutate()
                }}
                icon={null}
                type="activate_tenant"
                textBtn="Proceed"
            >
                Are you sure you want to {tenant?.is_active === 1 ? 'deactivated' : 'activate'} <strong>#{tenant?.fullname}</strong>?
            </Popup>
        </main>
    );
}

export default TenantGlobalPage;