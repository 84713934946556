import React from 'react'

export default function IconCamera() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M5.28777 18.3464L12.9408 10.6934L9.30577 7.05829L1.65271 14.7114C1.54736 14.8168 1.47251 14.9489 1.43613 15.0935L0.587891 19.4112L4.90483 18.563C5.04977 18.5268 5.18236 18.4518 5.28777 18.3464ZM15.4114 8.22276C15.7202 7.91389 15.8937 7.49503 15.8937 7.05829C15.8937 6.62155 15.7202 6.20269 15.4114 5.89382L14.1053 4.58771C13.7964 4.27893 13.3776 4.10547 12.9408 4.10547C12.5041 4.10547 12.0852 4.27893 11.7764 4.58771L10.4702 5.89382L14.1053 9.52888L15.4114 8.22276Z" fill="#F77C32" />
            <circle cx="14" cy="14" r="13.5" fill="white" stroke="#231F20" />
            <path d="M19.7037 10.3654H17.5L16.975 9.05577C16.9387 8.96616 16.8723 8.88869 16.7847 8.83397C16.6971 8.77924 16.5927 8.74992 16.4856 8.75H11.5144C11.2956 8.75 11.0995 8.8726 11.0266 9.05577L10.5 10.3654H8.2963C7.58009 10.3654 7 10.8817 7 11.5192V18.0962C7 18.7337 7.58009 19.25 8.2963 19.25H19.7037C20.4199 19.25 21 18.7337 21 18.0962V11.5192C21 10.8817 20.4199 10.3654 19.7037 10.3654ZM14 16.9423C12.5676 16.9423 11.4074 15.9096 11.4074 14.6346C11.4074 13.3596 12.5676 12.3269 14 12.3269C15.4324 12.3269 16.5926 13.3596 16.5926 14.6346C16.5926 15.9096 15.4324 16.9423 14 16.9423ZM12.4444 14.6346C12.4444 15.0018 12.6083 15.354 12.9001 15.6137C13.1918 15.8734 13.5874 16.0192 14 16.0192C14.4126 16.0192 14.8082 15.8734 15.0999 15.6137C15.3917 15.354 15.5556 15.0018 15.5556 14.6346C15.5556 14.2674 15.3917 13.9152 15.0999 13.6555C14.8082 13.3959 14.4126 13.25 14 13.25C13.5874 13.25 13.1918 13.3959 12.9001 13.6555C12.6083 13.9152 12.4444 14.2674 12.4444 14.6346Z" fill="#F77C32" />
        </svg>
    )
}
