import Header from "../components/Header";
import { Dropdown } from "flowbite-react";
import { useEffect, useContext, useMemo, useState } from "react";
import TableComponent from "../components/Table";
import { createColumnHelper } from "@tanstack/react-table";
import ThreeDots from "components/icons/ThreeDots";
import Trash from "components/icons/Trash";
import ModalMember from "components/Modal/ModalMember";
import Popup from "components/Modal/Popup";
import ModalContext from "context/ModalContext";
import NewMemberContext from "context/NewMemberContext";
import { Link } from "react-router-dom";
import { MainsStack } from "routes";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { activatedMemberService, getMembersService } from "services/member";
import { handleError } from "helpers/HandleRequestApi";
import Loading from "components/Loading";

function MemberList() {
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { fullname, setEmail, setPhone, setFullname, setMemberId, setType, deleteMember,
    } = useContext(NewMemberContext);
    const { setModal, setPopup } = useContext(ModalContext)
    const [member, setMember] = useState({})
    const [dataMembers, setDataMembers] = useState([])

    const getMembersQuery = useQuery({
        queryKey: ['data-members', page],
        queryFn: () => {
            return getMembersService(page)
        }
    })

    const activatedMemberMutation = useMutation({
        mutationFn: () => {
            const FD = new FormData();
            FD.append('user_id', member.id)

            return activatedMemberService(FD, member.status)
        },
        onError: (error, variables, context) => {
            handleError(error)
        },
        onSuccess: (data, variables, context) => {
            getMembersQuery.refetch()
            toast.success(`Successfully ${member.is_active ? 'deactivated' : 'activated'} tenant`)
        },
    })

    const columnHelper = createColumnHelper();

    const handleActiveOrInActiveTenant = data => {
        setMember(data)

        setPopup('activate_tenant')
    }

    const memberColumns = useMemo(
        () => [
            columnHelper.accessor('fullname', {
                header: 'Member Name',
            }),
            columnHelper.accessor('status', {
                header: 'Status',
            }),
            columnHelper.accessor('email', {
                header: 'Email',
            }),
            columnHelper.accessor('phone_number', {
                header: 'Phone Number',
            }),
            columnHelper.accessor('is_active', {
                header: 'Activate',
                cell: (info) => {
                    if (info.renderValue()) {
                        return (
                            <div className="flex items-center" onClick={() => {
                                handleActiveOrInActiveTenant(info.row.original)
                            }}>
                                <svg className="mr-2 fill-none" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.57143 0C1.15329 0 0 1.15329 0 2.57143V15.4286C0 16.8467 1.15329 18 2.57143 18H15.4286C16.8467 18 18 16.8467 18 15.4286V2.57143C18 1.15329 16.8467 0 15.4286 0H2.57143ZM2.57143 15.4286V2.57143H15.4286L15.4311 15.4286H2.57143Z" fill="#F77C32" />
                                    <path d="M6 9.46154L7.64706 11L13 6" stroke="#F77C32" stroke-width="2.5" stroke-linecap="round" />
                                </svg>




                                <label htmlFor={info.row.id} className="cursor-pointer">Actived</label>
                            </div>
                        )
                    }

                    return (
                        <div className="flex items-center" onClick={() => {
                            handleActiveOrInActiveTenant(info.row.original)
                        }}>
                            < svg className="mr-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path d="M2.57143 0C1.15329 0 0 1.15329 0 2.57143V15.4286C0 16.8467 1.15329 18 2.57143 18H15.4286C16.8467 18 18 16.8467 18 15.4286V2.57143C18 1.15329 16.8467 0 15.4286 0H2.57143ZM2.57143 15.4286V2.57143H15.4286L15.4311 15.4286H2.57143Z" fill="#B5B5B5" />
                            </svg >
                            <label htmlFor={info.row.id} className="cursor-pointer">Deactivated</label>
                        </div>
                    )
                },
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: (info) => {
                    return <div className="text-left">
                        {
                            info.row.original.status === 'admin' ?
                                <>
                                    {
                                        profile?.permissions !== undefined &&
                                            (checkPermission(profile.permissions, 'set permissions admin') ||
                                                checkPermission(profile.permissions, 'update admin') ||
                                                checkPermission(profile.permissions, 'delete admin')) ?
                                            <Dropdown
                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                            >
                                                {
                                                    checkPermission(profile.permissions, 'set permissions admin') &&
                                                    <Dropdown.Item>
                                                        <Link to={MainsStack.adminPermissions.replace(':admin_id', info.row.original.id)}>Set Permission</Link>
                                                    </Dropdown.Item>
                                                }
                                                {
                                                    checkPermission(profile.permissions, 'update admin') &&
                                                    <Dropdown.Item onClick={() => {
                                                        setModal('edit_member')
                                                        setMember(info.row.original)
                                                    }
                                                    }>
                                                        Edit
                                                    </Dropdown.Item>

                                                }
                                                {
                                                    checkPermission(profile.permissions, 'delete admin') &&
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setPopup('delete_member');
                                                            setMember(info.row.original)
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>
                                                }
                                            </Dropdown>
                                            :
                                            <p className="text-moregray">No Action</p>
                                    }
                                </>
                                :
                                <>
                                    {
                                        profile?.permissions !== undefined &&
                                            (checkPermission(profile.permissions, 'update member') ||
                                                checkPermission(profile.permissions, 'delete member')) ?
                                            <Dropdown
                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                            >
                                                {
                                                    checkPermission(profile.permissions, 'update member') &&
                                                    <Dropdown.Item onClick={() => {
                                                        setModal('edit_member')
                                                        setFullname(info.row.original.fullname);
                                                        setEmail(info.row.original.email);
                                                        setPhone(info.row.original.phone_number);
                                                        setType(info.row.original.status);
                                                        setMemberId(info.row.original.id);
                                                    }
                                                    }>
                                                        Edit
                                                    </Dropdown.Item>

                                                }
                                                {
                                                    checkPermission(profile.permissions, 'delete member') &&
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setPopup('delete_member');
                                                            setFullname(info.row.original.fullname);
                                                            setMemberId(info.row.original.id);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>
                                                }
                                            </Dropdown>
                                            :
                                            <p className="text-moregray">No Action</p>
                                    }
                                </>
                        }
                    </div>
                },
            }),
        ],
        []);

    const handleLoadMore = page => {
        setPage(page);
    }

    useEffect(() => {
        if (getMembersQuery.data !== undefined) {
            setDataMembers(getMembersQuery.data?.data.items)
            setTotalPage(getMembersQuery.data.meta.total_page)
        }
    }, [getMembersQuery])

    return (
        <main className="bg-background min-h-screen">
            <Header showGoback={true} className="py-5 px-14" />
            <section className="px-14 py-5">
                <div className="card app-shadow">
                    <div className="card-header flex justify-between">
                        <h1>List Member</h1>
                        <div className="space-x-2">
                            {
                                profile?.permissions !== undefined &&
                                <>
                                    {
                                        checkPermission(profile.permissions, 'create admin') ||
                                            checkPermission(profile.permissions, 'create member') ?
                                            <button
                                                className="btn btn-default"
                                                onClick={() => setModal('add_member')}
                                            >Add Member</button>
                                            :
                                            <></>
                                    }
                                </>
                            }
                        </div>
                    </div>

                    {
                        getMembersQuery.isLoading ?
                            <div className="flex justify-center h-60 items-center">
                                <Loading />
                            </div>
                            :
                            getMembersQuery.data !== undefined &&
                            <div className="card-body px-5">
                                <TableComponent
                                    data={dataMembers}
                                    columns={memberColumns}
                                    totalPage={totalPage}
                                    currentPage={page}
                                    showPagination={true}
                                    actionLoadPage={handleLoadMore}
                                />
                            </div>
                    }
                </div>
            </section>
            <ModalMember
                header="Add Member"
                type="add"
                choosedModal="add_member"
                getMembersQuery={getMembersQuery}
            />
            <ModalMember
                header="Edit Member"
                type="edit"
                choosedModal="edit_member"
                getMembersQuery={getMembersQuery}
            />
            <Popup
                action={async () => {
                    const reqDelete = await deleteMember();
                    if (reqDelete.status === 200) {
                        toast.success('Success delete member');

                        getMembersQuery.refetch()
                    }
                }}
                icon={<Trash width={35} height={35} />}
                type="delete_member"
                textBtn="Delete"
            >
                Are you sure you want to delete member <strong>#{fullname}</strong>?
                <br />
                This member will permanently delete
            </Popup>
            <Popup
                action={() => {
                    activatedMemberMutation.mutate()
                }}
                icon={null}
                type="activate_tenant"
                textBtn="Proceed"
            >
                Are you sure you want to {member?.is_active === 1 ? 'deactivated' : 'activate'} <strong>#{member?.fullname}</strong>?
            </Popup>
        </main>
    );
}

export default MemberList;