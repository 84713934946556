import { useContext, useEffect, useMemo, useState } from "react";
import TableComponent from "components/Table";
import ComplainContext from "context/ComplainContext";
import { useParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { Dropdown } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import ModalBanner from "components/Modal/ModalBanner";
import ModalContext from "context/ModalContext";
import FormDetailComplain from "components/form/FormDetailComplain";
import FormAssignComplain from "components/form/FormAssignComplain";
import NewMemberContext from "context/NewMemberContext";
import Popup from "components/Modal/Popup";
import ExlamationMark from "components/icons/ExlamationMark";
import { toast } from "react-toastify";
import Trash from "components/icons/Trash";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function EventComplain() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { complain, currentPage, totalPages, getComplain, finishComplain, deleteComplain } = useContext(ComplainContext);
    const { getMemberWithoutPage } = useContext(NewMemberContext);
    const { setModal, setPopup } = useContext(ModalContext);
    const params = useParams();
    const columnHelper = createColumnHelper();
    const [detailComplain, setDetailComplain] = useState({});

    const complainColumns = useMemo(
        () => [
            columnHelper.accessor('brand_name', {
                header: 'Brand Name',
            }),
            columnHelper.accessor('vendor_name', {
                header: 'Vendor Name'
            }),
            columnHelper.accessor('title', {
                header: 'Title Complain',
            }),
            columnHelper.accessor('assign_to', {
                header: 'Assign To',
                cell: info => {
                    const member = info.row.original.assign_to !== null ? info.row.original.assign_to : <p className="text-moregray">Not set</p>

                    return member
                }
            }),
            columnHelper.accessor('status', {
                header: 'Status',
                cell: info => {
                    if (info.row.original.status === 'pending') {
                        return <p className="text-moregray">Pending</p>
                    } else if (info.row.original.status === 'on_progress' && info.row.original.assign_to !== null) {
                        return <p className="text-default ">In Progress</p>
                    } else {
                        return <p className="text-app-green">Finished</p>
                    }
                }
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: info => {
                    return (
                        <>
                            <div className="text-center">
                                {
                                    profile?.permissions !== undefined ?
                                        <>
                                            {checkPermission(profile?.permissions, 'assign complain') ||
                                                checkPermission(profile?.permissions, 'reject complain') ||
                                                checkPermission(profile?.permissions, 'finish complain') ?
                                                <Dropdown
                                                    renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                                >
                                                    <>
                                                        {
                                                            info.row.original.status === 'pending' &&
                                                            <>
                                                                {
                                                                    checkPermission(profile?.permissions, 'assign complain') &&
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            getMemberWithoutPage(params.event_id);
                                                                            setDetailComplain(info.row.original);
                                                                            setModal('modal_assign_complain');
                                                                        }}
                                                                    >
                                                                        Assign
                                                                    </Dropdown.Item>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            info.row.original.status === 'on_progress' &&
                                                            <>
                                                                {
                                                                    (info.row.original.status !== 'finished' &&
                                                                        checkPermission(profile?.permissions, 'finish complain')) &&
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setDetailComplain(info.row.original);
                                                                            setPopup('finish_complain');
                                                                        }}
                                                                    >
                                                                        Finish Complain
                                                                    </Dropdown.Item>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    {
                                                        checkPermission(profile?.permissions, 'reject complain') &&
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setDetailComplain(info.row.original);
                                                                setPopup('delete_complain');
                                                            }}
                                                        >
                                                            Delete
                                                        </Dropdown.Item>
                                                    }
                                                </Dropdown>
                                                :
                                                <p className="text-moregray">No Action</p>}
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </>
                    )
                }
            })
        ], []
    )

    useEffect(() => {
        getComplain(params.event_id);
    }, [])

    const handleFinishComplain = async () => {
        const FD = new FormData();

        FD.append('complaint_id', detailComplain.id);

        const reqFinishComplain = await finishComplain(FD);

        if (reqFinishComplain.status === 200) {
            toast.success('Success finished this complain');
        }
    }

    const handleDeleteComplain = async () => {
        const reqDeleteComplain = await deleteComplain(detailComplain.id);

        if (reqDeleteComplain.status === 200) {
            toast.success('Success deleted complain');
        }
    }

    const handleLoadPage = async page => {
        getComplain(params.event_id, page);
    }

    return (
        <>
            <main className="px-5">
                <div className="app-shadow mt-5 p-5">
                    <TableComponent
                        data={complain}
                        columns={complainColumns}
                        enableClickRow={true}
                        actionClickRow={(data) => {
                            setModal('modal_detail_complain')
                            setDetailComplain(data.original);
                        }}
                        currentPage={currentPage}
                        totalPage={totalPages}
                        showPagination={true}
                        actionLoadPage={handleLoadPage}
                    />
                </div>
            </main>
            <ModalBanner
                choosedModal={'modal_detail_complain'}
                title={'Detail Complain'}
                textButton={null}
                actionClearForm={() => { }}
                useModalFooter={false}
            >
                <FormDetailComplain
                    dataComplain={detailComplain}
                />
            </ModalBanner>
            <ModalBanner
                choosedModal={'modal_assign_complain'}
                title={'Assign Complain'}
                textButton={'Submit'}
                actionClearForm={() => { }}
                idBtnSubmit={'btn_assign_member_to_complain'}
            >
                <FormAssignComplain
                    dataComplain={detailComplain}
                />
            </ModalBanner>
            <Popup
                action={handleFinishComplain}
                icon={<ExlamationMark color='#F77C32' height={35} width={35} />}
                type="finish_complain"
                textBtn="Proceed"
            >
                Are you sure want to finish complain <strong>#{detailComplain.title}</strong>
            </Popup>
            <Popup
                action={handleDeleteComplain}
                icon={<Trash color='#B91010' height={35} width={35} />}
                type="delete_complain"
                textBtn="Delete"
            >
                Are you sure want to delete complain <strong>#{detailComplain.title}</strong>
            </Popup>
        </>
    );
}

export default EventComplain;