import React from 'react'

export default function Pencil({ ...props }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
            <path d="M1.08197 11.8793L0.026415 16.4349C-0.00999813 16.6014 -0.00874986 16.774 0.0300685 16.94C0.0688869 17.106 0.144295 17.2612 0.250783 17.3943C0.357272 17.5274 0.492152 17.635 0.645569 17.7093C0.798985 17.7837 0.967064 17.8228 1.13753 17.8238C1.21695 17.8318 1.29699 17.8318 1.37642 17.8238L5.95975 16.7682L14.7597 8.00156L9.84864 3.10156L1.08197 11.8793Z" />
            <path d="M17.5259 3.61255L14.2482 0.334771C14.0327 0.120362 13.741 0 13.437 0C13.1331 0 12.8414 0.120362 12.6259 0.334771L10.8037 2.15699L15.7093 7.06255L17.5315 5.24033C17.6381 5.13315 17.7226 5.00599 17.78 4.86614C17.8375 4.72629 17.8668 4.57648 17.8663 4.42529C17.8657 4.27409 17.8354 4.12449 17.777 3.98503C17.7186 3.84557 17.6333 3.719 17.5259 3.61255V3.61255Z" />
        </svg>
    )
}

Pencil.defaultProps = {
    className: "fill-default"
}