function Textarea({ value, action, isRequired }) {
    return (
        <>
            <textarea
                className={`mt-2 px-3 py-2 block rounded-lg border border-appgray bg-semigray w-full focus:bg-[#FFFDFA] focus:border-none focus:ring-1 focus:ring-default focus:outline-none mb-2 h-24 ${value !== null ? `valid-textarea` : ``}`}
                onChange={(e) => action(e.target.value)}
                required={isRequired}
            >
                {value}
            </textarea>
        </>
    );
}

export default Textarea;