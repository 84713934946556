function UploadIcon() {
    return (
        <>
            <svg
                className="cursor-pointer"
                width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 21C2.20435 21 1.44129 20.6963 0.87868 20.1557C0.31607 19.6152 0 18.8821 0 18.1176V13.9999C5.08119e-09 13.6723 0.135459 13.3581 0.376577 13.1265C0.617695 12.8948 0.944722 12.7646 1.28571 12.7646C1.62671 12.7646 1.95373 12.8948 2.19485 13.1265C2.43597 13.3581 2.57143 13.6723 2.57143 13.9999V18.1176C2.57143 18.3449 2.76343 18.5294 3 18.5294H21C21.1137 18.5294 21.2227 18.486 21.303 18.4088C21.3834 18.3315 21.4286 18.2268 21.4286 18.1176V13.9999C21.4286 13.6723 21.564 13.3581 21.8051 13.1265C22.0463 12.8948 22.3733 12.7646 22.7143 12.7646C23.0553 12.7646 23.3823 12.8948 23.6234 13.1265C23.8645 13.3581 24 13.6723 24 13.9999V18.1176C24 18.8821 23.6839 19.6152 23.1213 20.1557C22.5587 20.6963 21.7957 21 21 21H3Z" fill="#B5B5B5" />
                <path d="M18.4799 5.71491C18.5992 5.82954 18.6939 5.96564 18.7584 6.11542C18.823 6.2652 18.8562 6.42573 18.8562 6.58785C18.8562 6.74997 18.823 6.9105 18.7584 7.06028C18.6939 7.21006 18.5992 7.34616 18.4799 7.46079C18.3606 7.57543 18.2189 7.66636 18.063 7.7284C17.9071 7.79045 17.7401 7.82238 17.5713 7.82238C17.4026 7.82238 17.2355 7.79045 17.0796 7.7284C16.9237 7.66636 16.7821 7.57543 16.6628 7.46079L13.2856 4.21772V13.5879C13.2856 13.9155 13.1502 14.2297 12.909 14.4614C12.6679 14.693 12.3409 14.8232 11.9999 14.8232C11.6589 14.8232 11.3319 14.693 11.0908 14.4614C10.8496 14.2297 10.7142 13.9155 10.7142 13.5879V4.21772L7.33704 7.46079C7.21773 7.57543 7.07608 7.66636 6.92018 7.7284C6.76429 7.79045 6.59721 7.82238 6.42847 7.82238C6.25973 7.82238 6.09265 7.79045 5.93675 7.7284C5.78086 7.66636 5.63921 7.57543 5.5199 7.46079C5.40058 7.34616 5.30594 7.21006 5.24136 7.06028C5.17679 6.9105 5.14355 6.74997 5.14355 6.58785C5.14355 6.42573 5.17679 6.2652 5.24136 6.11542C5.30594 5.96564 5.40058 5.82954 5.5199 5.71491L11.0913 0.361949C11.2106 0.247208 11.3522 0.156181 11.5081 0.0940743C11.664 0.0319677 11.8311 0 11.9999 0C12.1687 0 12.3358 0.0319677 12.4917 0.0940743C12.6476 0.156181 12.7892 0.247208 12.9085 0.361949L18.4799 5.71491Z" fill="#B5B5B5"
                />
            </svg>
        </>
    );
}

export default UploadIcon;