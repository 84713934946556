import { useQuery } from "@tanstack/react-query";
import PermissionTable from "components/moleculs/Table/PermissionTable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "utils/api";

const AdminPermissionPage = () => {
    const { member_id } = useParams();
    const [permissions, setPermissions] = useState([]);
    const [username, setUsername] = useState('');

    const queryGetPermissionAdmin = useQuery({
        queryFn: () => {
            return API.get(`permission?type=member&user_id=${member_id}`);
        },
        queryKey: ['permission-member'],
        select(data) {
            return data.data
        }
    })

    useEffect(() => {
        if (queryGetPermissionAdmin.data !== undefined) {
            const data = queryGetPermissionAdmin.data;
            setPermissions(data.data);
            setUsername(data.user_name);
        }
    }, [queryGetPermissionAdmin.data])

    return (
        <main className="bg-background min-h-screen p-5">
            <div className=" app-shadow card-body px-5">
                <div className="mb-5 text-lg font-semibold">
                    <h1>List Permission ({username})</h1>
                </div>
                <PermissionTable data={permissions} setPermissions={setPermissions} userId={member_id} />
            </div>
        </main>
    )
}

export default AdminPermissionPage;
