import Input from "components/Input";
import Popup from "components/Modal/Popup";
import Textarea from "components/Textarea";
import InputWithLabel from "components/atoms/InputWithLabel";
import BoothContext from "context/BoothContext";
import ModalContext from "context/ModalContext";
import { useContext } from "react";
import { toast } from 'react-toastify';

function BoothForm({ type }) {
    const { boothId, boothNumber, boothPrice, description, setBoothNumber, setBoothPrice, setDescription, createBooth, boothImage, setBoothImage, clearBoothForm, editBooth, categoryBooth } = useContext(BoothContext);
    const { setPopup, setModal } = useContext(ModalContext);


    const onSubmit = e => {
        e.preventDefault();

        setPopup('create_booth')
    }

    const proceedEditBooth = async () => {
        const FD = new FormData();

        FD.append('booth_id', boothId);
        FD.append('number', boothNumber);
        FD.append('price', boothPrice);
        FD.append('description', description);

        if (boothImage instanceof File) {
            FD.append('image', boothImage);
        }

        const reqEditBooth = await editBooth(FD)

        if (reqEditBooth === 'OK') {
            setModal(undefined);
            setPopup(undefined);
            clearBoothForm();
            toast.success('Success edit booth');
        }
    }

    const proceedAddBooth = async () => {
        const FD = new FormData();

        const activeBooth = categoryBooth.find(cb => cb.is_active === true);

        FD.append('category_booth_id', activeBooth.id);
        FD.append('number', boothNumber);
        FD.append('image', boothImage);
        FD.append('description', description);
        FD.append('price', boothPrice);

        const reqAddBooth = await createBooth(FD);

        if (reqAddBooth === 'OK') {
            setPopup(undefined);
            setModal(undefined);
            clearBoothForm();
            toast.success('Success add booth');
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <label htmlFor="">Booth Number</label>
                <Input
                    type={'number'}
                    action={(e) => setBoothNumber(e.replace(/[^\d]/g, ''))}
                    isRequired={true}
                    value={boothNumber}
                />
                <label htmlFor="">Price</label>
                <Input
                    type={'text'}
                    action={(e) => setBoothPrice(e.replace(/[^\d]/g, ''))}
                    isRequired={true}
                    value={boothPrice}
                    isCurrency={true}
                />
                <label htmlFor="">Description</label>
                <Textarea
                    action={setDescription}
                    isRequired={true}
                    value={description}
                />
                <label htmlFor="">Image</label>
                <Input
                    type={'file'}
                    action={setBoothImage}
                    isRequired={type === 'add' ? true : false}
                    value={boothImage}
                    id={'booth_image'}
                />
                <button type="submit" hidden id="add_booth"></button>
            </form>
            <Popup
                action={type === 'add' ? proceedAddBooth : proceedEditBooth}
                icon={null}
                type="create_booth"
                textBtn="Submit"
            >
                {
                    type === 'add' ? 'Are you sure you want to proceed create booth?' : 'Are you sure you want to proceed edit booth?'
                }
            </Popup>
        </>
    );
}

export default BoothForm;