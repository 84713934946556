import icon from '../../assets/images/checklist.svg'

function Checklist({color, width, height}) {
  return <>
    <svg width={ width } height={ height } viewBox="0 0 78 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M76.1884 2.46063C77.2727 3.54534 77.8819 5.01633 77.8819 6.55012C77.8819 8.0839 77.2727 9.5549 76.1884 10.6396L32.8332 53.9948C32.2602 54.5679 31.58 55.0225 30.8313 55.3326C30.0827 55.6428 29.2803 55.8024 28.4699 55.8024C27.6595 55.8024 26.8571 55.6428 26.1085 55.3326C25.3598 55.0225 24.6796 54.5679 24.1066 53.9948L2.56592 32.4579C2.01347 31.9244 1.57281 31.2861 1.26966 30.5804C0.966508 29.8747 0.806941 29.1157 0.800267 28.3476C0.793593 27.5796 0.939946 26.8179 1.23079 26.1071C1.52162 25.3962 1.95113 24.7504 2.49423 24.2073C3.03733 23.6642 3.68316 23.2347 4.39403 22.9438C5.10489 22.653 5.86657 22.5066 6.6346 22.5133C7.40263 22.52 8.16165 22.6795 8.86735 22.9827C9.57306 23.2858 10.2113 23.7265 10.7449 24.279L28.468 42.002L68.0055 2.46063C68.5427 1.92311 69.1805 1.49671 69.8825 1.20579C70.5846 0.914874 71.337 0.765137 72.0969 0.765137C72.8568 0.765137 73.6093 0.914874 74.3113 1.20579C75.0134 1.49671 75.6512 1.92311 76.1884 2.46063Z" fill={color} />
    </svg>
  </>
}

export default Checklist;