import LeftAngle from "../components/icons/LeftAngle";
import LogoVinco from '../assets/images/vincoo-logo.svg';
import Input from "../components/Input";
import { useReducer, useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { Modal } from "flowbite-react";
import { ModalReducer } from "../reducer/ModalReducer";
import Checklist from "../components/icons/Checklist";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";
import InputPassword from "components/InputPassword";
import { regexPassword } from "utils/regex";

function ResetPassowrd() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isModalOpen, dispatch] = useReducer(ModalReducer);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async e => {
        setIsLoading(true);
        e.preventDefault();
        const formData = new FormData();

        formData.append('password', password);
        formData.append('password_confirmation', confirmPassword);
        formData.append('remember_token', token);

        return API.post(`reset-password`, formData)
            .then(ress => {
                if (ress.status === 200) {
                    setIsLoading(false);
                    return navigate('/');
                }
            }).catch(err => {
                handleError(err.response);
            })
    }

    const validatePassword = (password) => {
        return regexPassword.test(password)
    };

    return (
        <>
            <main className="flex justify-center items-center h-screen">
                <div className="w-1/3 p-10">
                    <img src={LogoVinco} alt="Logo Vinco" className="mx-auto" />
                    <div className="text-left">
                        {/* <h6 className="text-center">Reset your password</h6> */}
                        <form onSubmit={handleForgotPassword}>
                            <div>
                                <label htmlFor="">Password</label>
                                <InputPassword
                                    className={password !== '' ? validatePassword(password) ? 'success-form' : 'error-form' : ''}
                                    showPassword={showPassword}
                                    isRequired={true}
                                    action={setPassword}
                                    value={password}
                                />
                            </div>
                            <div>
                                <label htmlFor="">Confirm Password</label>
                                <InputPassword
                                    className={confirmPassword !== '' ? confirmPassword === password ? 'success-form' : 'error-form' : ''}
                                    showPassword={showConfirmPassword}
                                    isRequired={true}
                                    action={setConfirmPassword}
                                    value={confirmPassword}
                                />
                            </div>
                            <div className="text-center">
                                {
                                    isLoading ? <button className="btn btn-cancel w-2/3 mt-16" disabled={true}>Loading ...</button> : <button className="btn w-2/3 mt-16">Reset Password</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <Modal show={isModalOpen === 'delete_event'} size="lg" popup onClose={() => dispatch({ type: 'close_modal' })}>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <div className="w-16 h-16 flex justify-center items-center mx-auto rounded-full bg-badge-success">
                            <Checklist
                                width={45}
                                height={45}
                                color="#10B986"
                            />
                        </div>
                        <h3 className="my-5 text-lg font-normal text-black dark:text-gray-400">
                            Link has been send to your email
                        </h3>
                        <div className="flex justify-center gap-4">
                            {/* <button className="btn w-20" onClick={() => handleSubmitForgotPassword}>Oke</button> */}
                            <Link className="btn w-20" to={'/'}>Oke</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ResetPassowrd;