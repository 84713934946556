import { showToast } from "../helpers/ShowSweetAlert";

export function ModalReducer(state, action){
  if(action.type === 'open_modal'){
    return state = 'default';
  } else if (action.type === 'modal_edit_event') {
      return (state = 'modal_edit_event');
  } else if (action.type === 'banner') {
      return (state = 'banner');
  } else if (action.type === 'close_modal') {
      return (state = undefined);
  } else if (action.type === 'delete_event') {
      return (state = 'delete_event');
  } else if (action.type === 'duplicate_event') {
      return (state = 'duplicate_event');
  } else if (action.type === 'reset_event') {
      return (state = 'reset_event');
  } else if (action.type === 'edit_member') {
      return (state = 'edit_member');
  } else {
      showToast('error', 'Unknown action type: ' + action.type);
  }
}