import { useContext, useReducer } from "react";
import { ModalReducer } from "reducer/ModalReducer";
import { Modal } from "flowbite-react";
import { useEvent } from "context/EventContext";
import BannerImage from "../BannerImage";
import ilustration from "../../assets/images/ilustration.svg";
import API from "utils/api";
import ModalContext from "context/ModalContext";

function ModalBanner({ choosedModal, title, children, textButton, actionClearForm, idBtnSubmit, rejectButton = null, actionreject = null, useModalFooter = true }) {
    const { setModal, modal } = useContext(ModalContext);

    return (
        <>
            <Modal
                position={'top-center'}
                show={modal === choosedModal}
                onClose={() => {
                    setModal(undefined)
                    actionClearForm();
                }}
                size={'lg'}
            >
                <div className="border-b-[1px] border-appgray">
                    <div className="px-6 py-5 flex justify-between items-center">
                        <h1 className="text-appblack text-xl font-semibold text-center" style={{ width: '-webkit-fill-available' }}>{title}</h1>
                        <button className=" text-4xl border-[1px] border-moregray w-[32px] h-[32px] flex items-center justify-center text-moregray rounded-full" onClick={() => setModal(undefined)}>
                            <p className="-mt-[4px]">
                                &times;
                            </p>
                        </button>
                    </div>
                </div>
                <Modal.Body>
                    {
                        children
                    }
                </Modal.Body>
                {
                    useModalFooter &&
                    <Modal.Footer>
                        <div className="text-center w-full space-x-3">
                            {
                                textButton !== null ? <button className="btn w-24" onClick={() => { document.getElementById(idBtnSubmit).click() }}>{textButton}</button> : ''
                            }
                            {
                                rejectButton !== null ?
                                    <button
                                        onClick={() => {
                                            actionreject();
                                        }}
                                        className="bg-[#B91010] text-white rounded-full py-1 w-24"
                                    >{rejectButton}</button>
                                    :
                                    <button
                                        onClick={() => {
                                            setModal(undefined);
                                            actionClearForm();
                                        }}
                                        className="btn-cancel w-24"
                                    >Cancel</button>
                            }
                        </div>
                    </Modal.Footer>
                }
            </Modal></>
    );
}

export default ModalBanner;