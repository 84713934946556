import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NewMemberContext from "context/NewMemberContext";
import ThreeDots from "components/icons/ThreeDots";
import MemberList from "components/MemberList";
import ModalBanner from "components/Modal/ModalBanner";
import ModalContext from "context/ModalContext";
import { Dropdown } from "flowbite-react";
import InviteOrKickMember from "components/form/InviteOrKickMember";
import Popup from "components/Modal/Popup";
import { MainsStack } from "routes";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function EventMembers() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const params = useParams();
    const { getMemberByEvent, membersEvent, selectedMember, setSelectedMember, unassignMember, memberId, setMemberId } = useContext(NewMemberContext);
    const { setModal, setPopup } = useContext(ModalContext);

    useState(() => {
        getMemberByEvent(params.event_id);
    }, [])

    const columnHelper = createColumnHelper();

    const memberColumns = useMemo(
        () => [
            columnHelper.accessor('fullname', {
                header: 'Member Name',
            }),
            columnHelper.accessor('email', {
                header: 'Email',
            }),
            columnHelper.accessor('phone_number', {
                header: 'Phone Number',
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: (info) => {
                    return <div>
                        {
                            profile?.status !== 'member' ?
                                <>
                                    {
                                        checkPermission(profile?.permissions, 'set permissions member') ||
                                            checkPermission(profile?.permissions, 'unassign member') ?
                                            <Dropdown
                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                            >
                                                {
                                                    profile?.permissions !== undefined ?
                                                        <>
                                                            {
                                                                checkPermission(profile?.permissions, 'set permissions member') &&
                                                                <Dropdown.Item>
                                                                    <Link to={MainsStack.memberPermissions.replace(':event_id', params.event_id).replace(':member_id', info.row.original.id)}>Set Permission</Link>
                                                                </Dropdown.Item>
                                                            }
                                                            {
                                                                checkPermission(profile?.permissions, 'unassign member') &&
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setMemberId(info.row.original.id);
                                                                        setSelectedMember(info.row.original.email);
                                                                        setPopup('unassign_member')
                                                                    }}
                                                                >
                                                                    Unassign
                                                                </Dropdown.Item>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </Dropdown>
                                            :
                                            <p className="text-moregray">No Action</p>
                                    }
                                </>
                                :
                                <>
                                    {
                                        checkPermission(profile?.permissions, 'unassign member') ?
                                            <Dropdown
                                                renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                            >
                                                {
                                                    profile?.permissions !== undefined ?
                                                        <>
                                                            {
                                                                checkPermission(profile?.permissions, 'unassign member') &&
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setMemberId(info.row.original.id);
                                                                        setSelectedMember(info.row.original.email);
                                                                        setPopup('unassign_member')
                                                                    }}
                                                                >
                                                                    Unassign
                                                                </Dropdown.Item>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </Dropdown>
                                            :
                                            <p className="text-moregray">No Action</p>
                                    }
                                </>
                        }
                    </div>
                },
            }),
        ],
        []);

    return (
        <main className="px-5 mt-5">
            {
                profile?.permissions !== undefined ?
                    <>
                        {
                            checkPermission(profile?.permissions, 'assign member') &&
                            <div className="w-full">
                                <button className="btn float-right mb-5" onClick={() => setModal('modal_assign_member')}>Assign Member</button>
                            </div>
                        }
                    </>
                    :
                    <></>
            }
            <MemberList
                dataMember={membersEvent}
                memberColumns={memberColumns}
                showMemberList={false}
                showPage={true}
                showTitle={false}
            />
            <ModalBanner
                title={'Assign Member'}
                choosedModal={'modal_assign_member'}
                textButton={'Save'}
                actionClearForm={() => { }}
                idBtnSubmit={'invite_member'}
            >
                <InviteOrKickMember />
            </ModalBanner>
            <Popup
                action={() => {
                    const FD = new FormData();
                    FD.append('user_id', memberId);
                    FD.append('event_id', params.event_id);

                    unassignMember(FD);
                }}
                icon={null}
                type="unassign_member"
                textBtn="Proceed"
            >
                Are you sure want to unassign member <strong>#{selectedMember}</strong>?
            </Popup>
        </main>
    );
}

export default EventMembers;