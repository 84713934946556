import { handleError } from 'helpers/HandleRequestApi';
import React, { useContext, useReducer, useState } from 'react';
import ComplainReducer from 'reducer/ComplainReducer';
import {
    ASSIGN_MEMBER_TO_COMPLAIN,
    DELETE_COMPLAIN,
    FINISH_COMPLAIN,
    GET_COMPLAIN,
    GET_DETAIL_COMPLAIN,
} from 'types/Complaintype';
import API from 'utils/api';
import ComplainContext from './ComplainContext';

const ComplainState = ({ children }) => {
    const initialState = {
        complain: [],
        currentPage: 1,
        totalPages: 1,
        detailComplain: {},
    };
    const [state, dispatch] = useReducer(ComplainReducer, initialState);

    const getComplain = async (eventId, page = 1) => {
        return API.get(`complaint/admin/${eventId}?page=${page}`)
            .then(ress => {
                dispatch({
                    type: GET_COMPLAIN,
                    payload: ress.data,
                });
                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const getDetailComplain = async complainId => {
        return API.get(`complain/admin/detail/${complainId}`)
            .then(ress => {
                dispatch({
                    type: GET_DETAIL_COMPLAIN,
                    payload: ress.data,
                });
                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response.data;
            });
    };

    const assignMemberToComplain = async data => {
        return API.post(`complaint/assign`, data)
            .then(ress => {
                dispatch({
                    type: ASSIGN_MEMBER_TO_COMPLAIN,
                    payload: ress.data.data,
                });
                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err.response;
            });
    };

    const finishComplain = async data => {
        return API.post(`complaint/finish`, data)
            .then(ress => {
                dispatch({
                    type: FINISH_COMPLAIN,
                    payload: ress.data.data,
                });

                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err;
            });
    };

    const deleteComplain = async complainId => {
        return API.delete(`complaint/${complainId}`)
            .then(ress => {
                dispatch({
                    type: DELETE_COMPLAIN,
                    payload: ress.data,
                });
                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
                return err;
            });
    };

    const { complain, currentPage, totalPages, detailComplain } = state;

    return (
        <ComplainContext.Provider
            value={{
                currentPage,
                totalPages,
                complain,
                detailComplain,
                getComplain,
                getDetailComplain,
                assignMemberToComplain,
                finishComplain,
                deleteComplain,
            }}
        >
            {children}
        </ComplainContext.Provider>
    );
};

export default ComplainState;
