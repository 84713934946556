import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Spinner from 'components/icons/Spinner'
import CardFormRegister from 'components/moleculs/Card/CardFormRegister'
import { handleError } from 'helpers/HandleRequestApi'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainsStack } from 'routes'
import { getQuestionFromService, setAnswerQuestionFromService } from 'services/form'
import { pusherRefreshTokenService, refreshTokenService } from 'services/user'
import Swal from 'sweetalert2'
import { setCredentialRequest } from 'utils/api'

export default function FormRegistrationBooth() {
    let { access_token, email, event_id, refresh_token } = useParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const retryRef = useRef(0)
    const [questions, setQuestions] = useState([])

    // const staticQuestion = { choices: [], field_type: "essay", question: 'Brand Name', id: 'brand-name', essay_answer: '' }
    const questionFormQuery = useQuery({
        queryFn: () => getQuestionFromService({ event_id }),
        queryKey: ['question-form', event_id, access_token],
        select: (data) => {
            return data.data?.data
        },
    })


    const invalidateQuery = () => {
        queryClient.invalidateQueries({ queryKey: ['question-form', event_id, access_token] })
        queryClient.removeQueries()
        setQuestions([])
    }


    useEffect(() => {
        if (questionFormQuery.isError && questionFormQuery.error?.response?.status === 401) {
            reloginMutate.mutate()
        }
    }, [questionFormQuery])

    useLayoutEffect(() => {
        setCredentialRequest({ token: access_token })
    }, [access_token])

    useEffect(() => {
        document.head.innerHTML += `<meta name="viewport" content="width=device-width, initial-scale=1" />`
        return () => {
            const metaViewport = document.querySelector('meta[name="viewport"]');
            if (metaViewport) {
                metaViewport.parentNode.removeChild(metaViewport);
            }
        }
    }, [])

    useEffect(() => {
        if (questionFormQuery.data !== undefined && questionFormQuery.data?.questions !== undefined) {
            setQuestions(questionFormQuery.data.questions)
        }
    }, [questionFormQuery.data])

    // let user_id = '416fcc57-070f-48bf-9895-d833fae06c07'

    // useLayoutEffect(() => {
    //     var channel = pusherInstance.subscribe(`token-${user_id}`);
    //     channel.bind("set-refresh-token", (data) => {
    //         console.log('data', JSON.parse(data))
    //     });

    //     var channel2 = pusherInstance.subscribe(`form-${user_id}`)
    //     channel2.bind(`form`, (data) => {
    //         console.log(data, 'form')
    //     })
    //     channel2.bind('form-refresh-token', (data) => {
    //         console.log(data, 'form-refresh-token')
    //     })
    // }, [])

    const reloginMutate = useMutation({
        mutationFn: async () => {
            const response = await refreshTokenService({ email, refresh_token, access_token })
            return response.data
        },
        onSuccess(data, variables, context) {
            access_token = data.access_token
            refresh_token = data.refresh_token
            setCredentialRequest({ token: access_token })
            navigate(MainsStack.formRegistrationBooth.replace(':access_token', access_token).replace(':email', email).replace(':refresh_token', refresh_token).replace(':event_id', event_id), { replace: true })
            pusherRefreshToken.mutate(data)
        },
        onError(error, variables, context) {
            Swal.fire({
                icon: 'error',
                text: 'Please relogin your app',
                timer: 3000
            })
        },
    })

    const pusherRefreshToken = useMutation({
        mutationFn: (data) => pusherRefreshTokenService(data),
        onSuccess: (data) => { },
        onError: (error) => { }
    })

    const answerMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            formData.append('form_event_id', questionFormQuery.data?.id)
            questions
                .filter((q) => q.field_type === "file" ? q.file_answer?.name !== undefined : q)
                .map((q, index) => {
                    formData.append(`answer[${index}][form_question_id]`, q.id)
                    if (q.field_type === "essay") {
                        formData.append(`answer[${index}][essay_answer]`, q.essay_answer)
                    } else if (q.field_type === "file" && q.file_answer?.name !== undefined) {
                        formData.append(`answer[${index}][file_answer]`, q.file_answer)
                    } else if (q.field_type === "choice") {
                        formData.append(`answer[${index}][choice_answer]`, q.choices.find((item) => item.is_selected === 1).id)
                    } else if (q.field_type === "date") {
                        formData.append(`answer[${index}][date_answer]`, q.date_answer)
                    }
                })
            const response = await setAnswerQuestionFromService({ data: formData })
            return response.data
        },
        onSuccess(data) {
            retryRef.current = 0
            Swal.fire({
                icon: 'success',
                text: 'Form Submited',
                timer: 3000
            }).then((ress) => {
                if (ress.isDismissed || ress.isConfirmed) {
                    invalidateQuery()
                }
            })

        },
        retry: 1,
        retryDelay: 15000,
        onError(error, variables, context) {
            retryRef.current += 1
            handleError(error.response)
            if (error?.response.status === 401 && retryRef.current === 1) {
                reloginMutate.mutate()
            }
        }
    })

    // const pusherSubmitForm = useMutation({
    //     mutationFn: async () => {
    //         const response = await pusherSubmitFormService()
    //         return response.data
    //     },
    //     onSuccess(data) {
    //         Swal.fire({
    //             icon: 'success',
    //             text: 'Form Submited',
    //             timer: 3000
    //         })
    //     },
    //     onError(error) {
    //         handleError(error.response)
    //     }
    // })

    const handleSubmit = (e) => {
        e.preventDefault()
        // pusherSubmitForm.mutate()
        answerMutation.mutate()

    }

    // useEffect(() => {
    //     if (questionFormQuery.status === "success" && questions.length === 0) {
    //         answerMutation.mutate()
    //     }
    // }, [questions, questionFormQuery.status])


    return (
        <div className=' min-h-screen bg-background py-[32px] px-5'>
            {/* <button className=' bg-green-500 rounded-full py-1 px-10' onClick={() => reloginMutate.mutate()}>Refresh Token</button> */}
            <p className=' text-center py-[32px] text-2xl font-semibold tracking-[0.72px] text-appblack'>{questionFormQuery.data?.title}</p>
            {
                questionFormQuery.status === "loading" ?
                    <div className=' flex justify-center items-center'>
                        <Spinner className={`!w-10 !h-10`} />
                    </div>
                    : questionFormQuery.status === "success" ?
                        questions.length > 0 ?
                            <form className=' flex flex-col gap-6' onSubmit={handleSubmit}>
                                {
                                    questions.map((question) => {
                                        return <CardFormRegister
                                            typeInput={question.field_type}
                                            isRequired={true}
                                            question={question}
                                            setQuestions={setQuestions}
                                            key={question.id}
                                            relogin={() => reloginMutate.mutate()}
                                            access_token={access_token}
                                        />
                                    })
                                }
                                <div className=' flex justify-center mt-[50px]'>
                                    <button disabled={answerMutation.isLoading} className=' bg-default text-white h-[39px] w-[191px] rounded-full font-semibold'>Submit</button>
                                </div>
                            </form>
                            : <p>Empty</p>
                        : questionFormQuery.status === "error" && questionFormQuery.error?.response?.status === 404 ?
                            <p>Form Event Not Found.</p>
                            : <div>Internal Server Error</div>
            }
        </div >
    )
}