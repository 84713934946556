function Reset() {
  return ( 
    <>
      <svg width="40" height="40" viewBox="0 0 68 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3236 1.03036C18.9833 1.69031 19.3539 2.58526 19.3539 3.51842C19.3539 4.45157 18.9833 5.34653 18.3236 6.00647L12.0137 12.3163H39.5899C45.1581 12.3163 50.6013 13.9675 55.2311 17.061C59.8608 20.1546 63.4693 24.5515 65.6002 29.6959C67.7311 34.8402 68.2886 40.5009 67.2023 45.9621C66.116 51.4233 63.4346 56.4398 59.4973 60.3771C55.56 64.3144 50.5436 66.9958 45.0823 68.0821C39.6211 69.1684 33.9604 68.6108 28.8161 66.48C23.6717 64.3491 19.2748 60.7406 16.1812 56.1108C13.0877 51.481 11.4365 46.0379 11.4365 40.4697C11.4365 39.5363 11.8073 38.6412 12.4673 37.9813C13.1273 37.3213 14.0224 36.9505 14.9557 36.9505C15.8891 36.9505 16.7842 37.3213 17.4441 37.9813C18.1041 38.6412 18.4749 39.5363 18.4749 40.4697C18.4749 44.6458 19.7133 48.7282 22.0334 52.2006C24.3536 55.6729 27.6513 58.3793 31.5095 59.9774C35.3678 61.5756 39.6133 61.9937 43.7092 61.179C47.8051 60.3643 51.5675 58.3532 54.5205 55.4003C57.4735 52.4473 59.4845 48.6849 60.2992 44.589C61.1139 40.4931 60.6958 36.2476 59.0976 32.3893C57.4995 28.5311 54.7931 25.2333 51.3208 22.9132C47.8484 20.5931 43.766 19.3547 39.5899 19.3547H12.0137L18.3236 25.6645C18.9646 26.3283 19.3193 27.2172 19.3113 28.1399C19.3033 29.0626 18.9332 29.9453 18.2807 30.5978C17.6282 31.2503 16.7456 31.6204 15.8228 31.6284C14.9001 31.6364 14.0112 31.2817 13.3475 30.6406L1.03036 18.3236C0.370622 17.6636 0 16.7687 0 15.8355C0 14.9023 0.370622 14.0074 1.03036 13.3475L13.3475 1.03036C14.0074 0.370622 14.9023 0 15.8355 0C16.7687 0 17.6636 0.370622 18.3236 1.03036Z" fill="#F77C32"/>
      </svg>
    </>
  );
}

export default Reset;