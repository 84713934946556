import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MainsStack } from 'routes';
import { getProfileService, refreshTokenService } from 'services/user';
import { useAuthStore } from 'store/auth';
import { shallow } from 'zustand/shallow';
import { setCredentialRequest } from '../../utils/api';
import ModalContext from 'context/ModalContext';

export default function WrapperAuth() {
    const { setModal, setPopup } = useContext(ModalContext);
    const navigate = useNavigate()
    const { auth, logout, updateAuth, updateProfile, profile } = useAuthStore((state) => ({ auth: state.auth, logout: state.logout, updateAuth: state.updateAuth, updateProfile: state.updateProfile }), shallow)
    const [syncCredential, setSyncCredential] = useState(true)
    const refTimeOut = useRef(null)
    const { pathname } = useLocation()

    const profileQuery = useQuery({
        queryFn: getProfileService,
        enabled: auth.access_token !== '' && syncCredential === false,
        queryKey: ['profile'],
        select: (data) => {
            return data.data?.data
        }
    })

    const handleCloseModal = () => {
        setModal(undefined);
        setPopup(undefined);
    }

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                handleCloseModal();
            }
        });

        if (profileQuery.data !== undefined) {
            localStorage.setItem('profile', JSON.stringify(profileQuery.data.id));
            updateProfile(profileQuery.data)
        }

        return () => {
            window.removeEventListener('keydown', handleCloseModal);
        }
    }, [profileQuery.data])

    const reloginMutate = useMutation({
        mutationFn: () => {
            return refreshTokenService({ email: auth.email, refresh_token: auth.refresh_token })
        },
        onSuccess({ data }, variables, context) {
            var responsedata = data
            var expires = new Date();
            var now = new Date().getTime()
            expires.setMinutes(expires.getMinutes() + parseInt(responsedata.expires_in.toString()));
            responsedata.expires_in = expires.getTime();
            updateAuth({ ...responsedata, email: auth.email })

            const dateRange = expires.getTime() - now
            refTimeOut.current = setTimeout(() => {
                reloginMutate.mutate()
            }, dateRange);

        }, onError(error, variables, context) {
            logout()
        },
    })

    const checkAuth = useCallback(async () => {
        const now = new Date().getTime()
        const date = new Date(auth.expires_in)
        const exp = new Date(date.getTime() - 600000)

        if (now > exp) {
            setCredentialRequest({ token: auth.access_token }).then(() => {
                reloginMutate.mutate()
            })
        } else {
            const dateRange = exp - now
            // var inter = setInterval(() => {
            //     const r = new Date().getTime()
            //     const date = new Date(auth.expires_in)
            //     const exp = new Date(date.getTime() - 600000)
            //     var count = (exp - r) / 60000
            //     if (count < 2) {
            //         clearInterval(inter)
            //     }
            //     console.log(count)
            // }, 1000);

            refTimeOut.current = setTimeout(() => {
                reloginMutate.mutate()
            }, dateRange);

            setCredentialRequest({ token: auth.access_token }).then(() => {
                if (pathname === MainsStack.login) {
                    navigate(MainsStack.admin, { replace: true })
                } else {
                    navigate(pathname, { replace: true })
                }
                setSyncCredential(false)
            })
        }
    }, [auth,])

    useEffect(() => {
        if (auth.access_token !== '') {
            if (refTimeOut.current) {
                clearTimeout(refTimeOut.current)
            }
            checkAuth()
            return () => {
                if (refTimeOut.current) {
                    clearTimeout(refTimeOut.current)
                }
            }
        }
    }, [auth, refTimeOut, checkAuth])

    useEffect(() => {
        if (auth.access_token === '') {
            setCredentialRequest({ token: null }).then(() => {
                navigate(MainsStack.login, { replace: true })
                setSyncCredential(false)
            })
        }
    }, [auth])

    // return <Link to={MainsStack.formRegistrationBooth.replace(':access_token', auth.access_token).replace(':email', auth.email).replace(':refresh_token', auth.refresh_token).replace(':event_id', '402b69ca-1eb4-4c7b-867e-e7b10bb9605f')}>ass</Link>
    return (
        <>
            {syncCredential ? <p>Sync Credential</p> : <Outlet />}
        </>
    )
}
