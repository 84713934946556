import VendorContext from './VendorContext';
import {
    GET_VENDOR_BY_EVENT,
    GET_VENDOR_BY_BOOTH,
    ACCEPT_VENDOR,
    REJECT_VENDOR,
    PROPOSE_VENDOR,
    SET_PRIORITY_VENDOR,
    RESET_PRIORITY,
    GET_DETAIL_VENDOR,
} from 'types/VendorType';
import VendorReducer from 'reducer/VendorReducer';
import API from 'utils/api';
import { useReducer, useState } from 'react';
import { handleError } from 'helpers/HandleRequestApi';

const VendorState = ({ children }) => {
    const InitialState = {
        vendor: [],
        totalPage: 1,
        currentPage: 1,
        vendorDetail: null,
        isLoading: true,
    };

    const [state, dispatch] = useReducer(VendorReducer, InitialState);
    const [vendorName, setVendorName] = useState(null);
    const [vendorId, setVendorId] = useState(null);

    const getVendorByEvent = async (eventId, page = 1) => {
        return API.get(`event-vendor/${eventId}?page=${page}`)
            .then(ress => {
                dispatch({
                    type: GET_VENDOR_BY_EVENT,
                    payload: ress.data,
                });
            })
            .catch(err => {
                handleError(err.response);
                return 'Not OK';
            });
    };

    const getVendorByBooth = async (boothId, filter = false) => {
        return API.get(`/booth-vendor/${boothId}`)
            .then(ress => {
                if (filter) {
                    dispatch({
                        type: GET_VENDOR_BY_BOOTH,
                        payload: ress.data.data.filter(
                            item => item.status !== 'reject'
                        ),
                    });
                } else {
                    dispatch({
                        type: GET_VENDOR_BY_BOOTH,
                        payload: ress.data.data,
                    });
                }

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const setPriorityVendor = async data => {
        return API.post(`priority`, data)
            .then(ress => {
                dispatch({
                    type: SET_PRIORITY_VENDOR,
                    payload: data.get('vendor_id'),
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const acceptVendor = async data => {
        return API.post(`booth-acception`, data)
            .then(ress => {
                dispatch({
                    type: ACCEPT_VENDOR,
                    payload: ress.data,
                });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const rejectVendor = async data => {
        return API.post(`booth-rejection`, data)
            .then(res => {
                dispatch({
                    type: REJECT_VENDOR,
                    payload: res.data.data,
                });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const proposeBoothAction = async data => {
        return API.post(`booth-propose`, data)
            .then(res => {
                dispatch({
                    type: PROPOSE_VENDOR,
                    payload: res.data,
                });
                return res.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const resetPriority = async boothId => {
        return API.post(`priority/reset`, boothId)
            .then(ress => {
                dispatch({
                    type: RESET_PRIORITY,
                    payload: null,
                });
                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const getDetailVendor = async (vendorId, eventId = '') => {
        return API.get(`detail-vendor/${vendorId}?event_id=${eventId}`)
            .then(ress => {
                dispatch({
                    type: GET_DETAIL_VENDOR,
                    payload: ress.data.data,
                });

                return ress.data;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const { vendor, vendorDetail, isLoading, totalPage, currentPage } = state;

    return (
        <VendorContext.Provider
            value={{
                totalPage,
                currentPage,
                vendor,
                vendorDetail,
                vendorName,
                vendorId,
                isLoading,
                setVendorName,
                setVendorId,
                getVendorByEvent,
                getVendorByBooth,
                acceptVendor,
                rejectVendor,
                proposeBoothAction,
                setPriorityVendor,
                resetPriority,
                getDetailVendor,
            }}
        >
            {children}
        </VendorContext.Provider>
    );
};

export default VendorState;
