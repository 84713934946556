

function Trash(props) {
  return (
    <svg viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24.8 12.4H37.2C37.2 10.7557 36.5468 9.17866 35.3841 8.01594C34.2213 6.85321 32.6443 6.2 31 6.2C29.3557 6.2 27.7787 6.85321 26.6159 8.01594C25.4532 9.17866 24.8 10.7557 24.8 12.4ZM18.6 12.4C18.6 9.11131 19.9064 5.95733 22.2319 3.63188C24.5573 1.30642 27.7113 0 31 0C34.2887 0 37.4427 1.30642 39.7681 3.63188C42.0936 5.95733 43.4 9.11131 43.4 12.4H58.9C59.7222 12.4 60.5107 12.7266 61.092 13.308C61.6734 13.8893 62 14.6778 62 15.5C62 16.3222 61.6734 17.1107 61.092 17.692C60.5107 18.2734 59.7222 18.6 58.9 18.6H56.1658L53.4192 50.654C53.1551 53.7492 51.7389 56.6326 49.4507 58.7337C47.1625 60.8347 44.1691 62.0004 41.0626 62H20.9374C17.8309 62.0004 14.8375 60.8347 12.5493 58.7337C10.2611 56.6326 8.84485 53.7492 8.5808 50.654L5.8342 18.6H3.1C2.27783 18.6 1.48933 18.2734 0.907969 17.692C0.326606 17.1107 0 16.3222 0 15.5C0 14.6778 0.326606 13.8893 0.907969 13.308C1.48933 12.7266 2.27783 12.4 3.1 12.4H18.6ZM40.3 31C40.3 30.1778 39.9734 29.3893 39.392 28.808C38.8107 28.2266 38.0222 27.9 37.2 27.9C36.3778 27.9 35.5893 28.2266 35.008 28.808C34.4266 29.3893 34.1 30.1778 34.1 31V43.4C34.1 44.2222 34.4266 45.0107 35.008 45.592C35.5893 46.1734 36.3778 46.5 37.2 46.5C38.0222 46.5 38.8107 46.1734 39.392 45.592C39.9734 45.0107 40.3 44.2222 40.3 43.4V31ZM24.8 27.9C23.9778 27.9 23.1893 28.2266 22.608 28.808C22.0266 29.3893 21.7 30.1778 21.7 31V43.4C21.7 44.2222 22.0266 45.0107 22.608 45.592C23.1893 46.1734 23.9778 46.5 24.8 46.5C25.6222 46.5 26.4107 46.1734 26.992 45.592C27.5734 45.0107 27.9 44.2222 27.9 43.4V31C27.9 30.1778 27.5734 29.3893 26.992 28.808C26.4107 28.2266 25.6222 27.9 24.8 27.9Z" />
    </svg>
  )
}

Trash.defaultProps = {
  className: 'fill-app-red'
}
export default Trash;