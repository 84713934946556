import { MainsStack } from "routes";
import TableComponent from "./Table";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import NewMemberContext from "context/NewMemberContext";

function MemberList({ dataMember, memberColumns, pageSize = 15, showMemberList = true, showPage = false, showTitle = true }) {
  const { totalPages, currentPage, getMemberByEvent } = useContext(NewMemberContext);
  const { event_id } = useParams();

  const handleLoadPage = async page => {
    getMemberByEvent(event_id, page)
  }

  return (
    <section className="app-shadow w-full card flex flex-col mb-5">
      <div className="card-header flex justify-between items-center">
        {
          showTitle && <h1>Member List</h1>
        }
        {
          showMemberList && <Link className="btn-outline" to={MainsStack.memberList}>Member List</Link>
        }
      </div>
      <div className="px-5">
        <TableComponent
          data={dataMember}
          columns={memberColumns}
          totalPage={totalPages}
          currentPage={currentPage}
          showPagination={showPage}
          actionLoadPage={handleLoadPage}
          pageSize={pageSize}
        />
      </div>
    </section>
  );
}

export default MemberList;