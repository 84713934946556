import { useState, useEffect, useMemo, useContext } from "react";
import InputWithLable from 'components/atoms/InputWithLabel'
import { useMutation, useQuery } from "@tanstack/react-query";
import API from "utils/api";
import { handleError } from "helpers/HandleRequestApi";
import { createColumnHelper } from "@tanstack/react-table";
import { Badge, Modal } from "flowbite-react";
import { Dropdown } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import TableComponent from "components/Table";
import ModalContext from "context/ModalContext";
import TextAreaWithLabel from "components/atoms/TextAreaWithLabel";
import Input from "components/Input";
import { toast } from "react-toastify";
import Popup from "components/Modal/Popup";
import Trash from "components/icons/Trash";
import Xicon from "components/icons/Xicon";
import ModalBanner from "components/Modal/ModalBanner";
import FormDetailNotif from "components/form/FormDetailNotif";
import Card from "components/organism/event/settings/Card";
import Header from "components/Header";

function CustomNotification({ event_id }) {
    const DefaultValueNotif = {
        titleNotif: '',
        bodyNotif: '',
        forAllVendor: true,
        listVendor: [],
        publish_at: ''
    };

    const { modal, setModal, setPopup } = useContext(ModalContext);
    const [actiontype, setActionType] = useState('Add');
    const [dataNotif, setDataNotif] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [image, setImage] = useState(null);
    const [publishForAll, setPublishForAll] = useState(true);
    const [vendorId, setVendorId] = useState(null);
    const [selectedNotif, setSelectedNotif] = useState(null);
    const [notifId, setNotifId] = useState(null);
    const [formData, setFormData] = useState(DefaultValueNotif)
    const [detailNotif, setDetailNotif] = useState(null);
    const columnHelper = createColumnHelper();

    const notifColumns = useMemo(
        () => [
            columnHelper.accessor('title', {
                header: 'Title Notification',
            }),
            columnHelper.accessor('body', {
                header: 'Body Notification',
                cell: info => {
                    if (info.row.original.body.length > 20) {
                        return info.row.original.body.substring(0, 20) + `...`;
                    }

                    return info.row.original.body;
                }
            }),
            columnHelper.accessor('target', {
                header: 'Target Vendor',
                cell: info => {
                    if (info.row.original.target !== null) {
                        return (
                            <h1>{info.row.original.target.fullname}</h1>
                        )
                    }

                    return (
                        <h1>All Vendor</h1>
                    )
                }
            }),
            columnHelper.accessor('status', {
                header: 'Status',
                cell: info => {
                    if (info.row.original.status === 'cancelled') {
                        return (
                            <Badge
                                className="rounded-full px-2 w-fit text-[#B91010] bg-badge-error"
                            >
                                cancelled
                            </Badge>
                        )
                    } else if (info.row.original.status === 'pending') {
                        return (
                            <Badge
                                className="rounded-full px-2 w-fit text-default bg-default/[.15]"
                            >
                                Pending
                            </Badge>
                        )
                    } else {
                        return (
                            <Badge
                                className="rounded-full px-2 w-fit text-[#10B986] bg-badge-success"
                            >
                                Published
                            </Badge>
                        )
                    }
                }
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: info => {
                    return (
                        <>
                            <div className="text-center">
                                <div className="text-center">
                                    <Dropdown
                                        renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                    >
                                        {
                                            info.row.original.status === 'pending' &&
                                            <>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setFormData({
                                                            titleNotif: info.row.original.title,
                                                            bodyNotif: info.row.original.body,
                                                            forAllVendor: true,
                                                            listVendor: [],
                                                            publish_at: info.row.original.publish_at,
                                                        })
                                                        setNotifId(info.row.original.id)
                                                        setActionType('Edit')
                                                        setModal('modal_add_notif');
                                                        setPublishForAll(info.row.original.target === null ? true : false)
                                                        setVendorId(info.row.original.target?.id)
                                                    }
                                                    }
                                                >
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setPopup('cancel_notif')
                                                        setNotifId(info.row.original.id);
                                                        setSelectedNotif(info.row.original.title);
                                                    }}
                                                >
                                                    Cancel
                                                </Dropdown.Item>
                                            </>
                                        }
                                        <Dropdown.Item
                                            onClick={() => {
                                                setPopup('delete_notif')
                                                setNotifId(info.row.original.id);
                                                setSelectedNotif(info.row.original.title);
                                            }}
                                        >
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </div>
                        </>
                    )
                }
            })
        ], []
    )

    const getDataNotif = async (page) => {
        return API.get(`custom/global/notification`)
            .then(ress => {
                return ress.data
            }).catch(err => handleError(err.response))
    }

    const updateMutation = useMutation({
        mutationFn: () => {
            const FD = new FormData();

            FD.append('event_id', event_id);
            FD.append('title', formData.titleNotif);
            FD.append('body', formData.bodyNotif);
            FD.append('publish_at', formData.publish_at.replace('T', ' '));
            FD.append('custom_notification_id', notifId)

            if (image !== null || image !== undefined) {
                FD.append('image', image);
            }

            if (vendorId !== null) {
                FD.append('vendor_id', vendorId);
            }

            return API.post(`update/custom/global/notification`, FD)
        },
        onSuccess: data => {
            setDataNotif(oldData => {
                return oldData.map(item => {
                    if (item.id === data.data.data.id) {
                        item = data.data.data
                    }

                    return item;
                })
            })

            toast.success('Success Edit notification');
            setModal(undefined);
            setFormData(DefaultValueNotif);
        },
        onError: (error) => {
            handleError(error.response)
        }
    })

    const cancelMutation = useMutation({
        mutationFn: () => {
            const FD = new FormData();

            FD.append('custom_notification_id', notifId)

            return API.post(`cancel/custom/global/notification`, FD);
        },
        onSuccess: data => {
            setDataNotif(oldData => {
                return oldData.map(item => {
                    if (item.id === data.data.data.id) {
                        item = data.data.data;
                    }

                    return item;
                })
            })

            toast.success('Success cancel notification')
        },
        onError: err => {
            handleError(err.response);
        }
    })

    const deleteMutation = useMutation({
        mutationFn: () => {
            return API.delete(`custom/global/notification/${notifId}`);
        },
        onSuccess: data => {
            setDataNotif(oldData => {
                return oldData.filter(notif => notif.id !== notifId);
            })

            toast.success('Success delete notification')
        },
        onError: err => {
            handleError(err.response);
        }
    })

    const addMutation = useMutation({
        mutationFn: () => {
            const FD = new FormData();

            FD.append('title', formData.titleNotif);
            FD.append('body', formData.bodyNotif);
            FD.append('publish_at', formData.publish_at.replace('T', ' '));

            if (image !== null) {
                FD.append('image', image);
            }

            if (!publishForAll && vendorId !== null) {
                FD.append('vendor_id', vendorId);
            }

            return API.post(`custom/global/notification`, FD);
        },
        onSuccess: (data) => {
            setDataNotif(oldData => {
                return [...oldData, data.data.data];
            })

            reqGetCustomNotif.refetch();
            toast.success("Success create new notification")
            setModal(undefined);
            setFormData(DefaultValueNotif);
        },
        onError: (err) => {
            handleError(err.response);
        }
    })

    const reqGetCustomNotif = useQuery({
        queryKey: ['notif', currentPage],
        queryFn: () => {
            return getDataNotif(currentPage)
        },
        select(data) {
            return data
        }
    })

    useEffect(() => {
        if (reqGetCustomNotif.data !== undefined) {
            setDataNotif(reqGetCustomNotif.data?.data.items.map((notif) => ({ ...notif })) ?? [])
            setTotalPage(reqGetCustomNotif.data?.meta.total_page);
            setCurrentPage(reqGetCustomNotif.data?.meta.current_page.slice(
                reqGetCustomNotif.data?.meta.current_page.indexOf('page=') + 5
            ))
        }
    }, [reqGetCustomNotif.data])

    return (
        <>
            <main className="bg-background min-h-screen">
                <Header showGoback={true} className="py-5 px-14" />
                <section className="px-14 py-5">
                    <Card>
                        <div className="flex justify-end mb-3">
                            <button className="btn" onClick={() => {
                                setActionType('Add')
                                setModal('modal_add_notif')
                            }}>Add Notif</button>
                        </div>
                        <TableComponent
                            data={dataNotif}
                            columns={notifColumns}
                            showPagination={true}
                            currentPage={currentPage}
                            totalPage={totalPage}
                            actionLoadPage={setCurrentPage}
                            enableClickRow={true}
                            actionClickRow={(data) => {
                                setModal('modal_detail_notif')
                                setDetailNotif(data.original);
                            }}
                        />
                    </Card>
                </section>
            </main>
            <Modal show={modal === 'modal_add_notif'} onClose={() => setModal(undefined)} size={'lg'} position={'top-center'}>
                <div className="border-b-[1px] border-appgray">
                    <div className="px-6 py-5 flex justify-between items-center">
                        <h1 className="text-appblack text-xl font-semibold text-center" style={{ width: '-webkit-fill-available' }}>{actiontype} Notif</h1>
                        <button className=" text-4xl border-[1px] border-moregray w-[32px] h-[32px] flex items-center justify-center text-moregray rounded-full" onClick={() => setModal(undefined)}>
                            <p className="-mt-[4px]">
                                &times;
                            </p>
                        </button>
                    </div>
                </div>
                <Modal.Body>
                    {/* <div className="columns-2 mb-3">
                        <div className="flex items-center">
                            <input
                                className="mr-3"
                                type="radio"
                                id="all"
                                checked={publishForAll}
                                onClick={() => setPublishForAll(true)}
                                required={true}
                            />
                            <label className="cursor-pointer" htmlFor="all">All Vendor</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                className="mr-3"
                                type="radio"
                                id="selected"
                                checked={!publishForAll}
                                onClick={() => {
                                    setPublishForAll(false)
                                }}
                                required={true}
                            />
                            <label className="cursor-pointer" htmlFor="selected">Selected Vendor</label>
                        </div>
                    </div> */}
                    <InputWithLable
                        required
                        label="Title"
                        value={formData.titleNotif}
                        onChange={(e) => setFormData(p => ({ ...p, titleNotif: e.target.value }))}
                        classInput={` !text-base !font-medium !tracking-[0.48px] !pt-[13px] !pb-[13px] !mb-3 !px-5 valid:!empty-form ${formData.titleNotif === '' ? 'valid:!empty-form' : 'valid:!valid-form'}`}
                    />
                    <TextAreaWithLabel label="Body"
                        value={formData.bodyNotif}
                        required={true}
                        onChange={(e) => setFormData(p => ({ ...p, bodyNotif: e.target.value }))}
                        classInput={` py-6 !px-5 !mb-3 ${formData.bodyNotif === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                    />
                    <label htmlFor="image_notif" className="!mb-2">Image</label>
                    <Input
                        type={'file'}
                        action={setImage}
                        isMultiple={false}
                        value={image}
                        id={'image_notif'}
                        isRequired={true}
                    />
                    <InputWithLable
                        type={'datetime-local'}
                        label={'Publish at'}
                        required
                        value={formData.publish_at}
                        onChange={(e) => setFormData(p => ({ ...p, publish_at: e.target.value }))}
                        classInput={` !text-base !font-medium !tracking-[0.48px] !pt-[13px] !pb-[13px] !mb-3 !px-5 valid:!empty-form ${formData.publish_at === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                    />
                </Modal.Body>
                <Modal.Footer className="flex items-center justify-center">
                    {
                        actiontype === 'Add' ?
                            <>
                                {
                                    addMutation.isLoading ?
                                        <button className="btn cursor-not-allowed">Loading ...</button>
                                        :
                                        <button className="btn" onClick={() => addMutation.mutate()}>Add Notif</button>
                                }
                            </>
                            :
                            <>
                                {
                                    updateMutation.isLoading ?
                                        <button className="btn cursor-not-allowed">Loading ...</button>
                                        :
                                        <button className="btn" onClick={() => updateMutation.mutate()}>Edit Notif</button>
                                }
                            </>
                    }
                    <button className="btn-cancel" onClick={() => setModal(undefined)}>Cancel</button>
                </Modal.Footer>
            </Modal>
            <ModalBanner
                choosedModal={'modal_detail_notif'}
                title={'Detail Notif'}
                textButton={null}
                actionClearForm={() => { }}
                useModalFooter={false}
            >
                <FormDetailNotif
                    data={detailNotif}
                />
            </ModalBanner>
            <Popup
                action={() => {
                    cancelMutation.mutate();
                }}
                type="cancel_notif"
                textBtn="Proceed"
                icon={<Xicon width={35} height={35} color={'#B91010'} />}
            >
                Are you sure you want to cancel notif <strong>#{selectedNotif}</strong>?
            </Popup>
            <Popup
                action={() => {
                    deleteMutation.mutate();
                }}
                type="delete_notif"
                textBtn="Proceed"
                icon={<Trash width={35} height={35} />}
            >
                Are you sure you want to delete notif <strong>#{selectedNotif}</strong>?
            </Popup>
        </>
    );
}

export default CustomNotification;