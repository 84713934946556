function Copy() {
  return ( 
    <>
      <svg width="40" height="40" viewBox="0 0 65 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.625 0C43.3103 0 45.8857 1.06674 47.7845 2.96554C49.6833 4.86435 50.75 7.43968 50.75 10.125V50.625C50.75 53.3103 49.6833 55.8857 47.7845 57.7845C45.8857 59.6833 43.3103 60.75 40.625 60.75H10.25C7.56468 60.75 4.98935 59.6833 3.09055 57.7845C1.19174 55.8857 0.125 53.3103 0.125 50.625V22.3459C0.125572 19.6608 1.19269 17.0859 3.09163 15.1875L15.3125 2.96663C17.2109 1.06769 19.7858 0.000573454 22.4709 0H40.625Z" fill="#F77C32"/>
        <path d="M65 23.375C65 20.6897 63.9333 18.1143 62.0345 16.2155C60.1357 14.3167 57.5603 13.25 54.875 13.25V63.875H14.375C14.375 66.5603 15.4417 69.1357 17.3405 71.0345C19.2394 72.9333 21.8147 74 24.5 74H54.875C57.5603 74 60.1357 72.9333 62.0345 71.0345C63.9333 69.1357 65 66.5603 65 63.875V23.375Z" fill="#F77C32"/>
      </svg>

    </>
  );
}

export default Copy;