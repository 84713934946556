import React, { useState } from 'react'

export default function InputWithLabel({ classLabel, className, label, classInput, ...props }) {
    return (
        <div className={`flex flex-col gap-2 text-appblack ${className ?? ''}`}>
            <label htmlFor={label} className={`text-base tracking-[0.48px] ${classLabel}`}>{label}</label>
            {
                props.type === "password" ? <InputPassword label={label} classInput={classInput}  {...props} /> :
                    <input id={label} placeholder={`Enter ${label}`} type="text" className={` form-control !mb-0 bg-semigray  text-xl !px-4 !pt-[11px] !pb-[10px] font-medium !tracking-[0.6px] !h-[45px] disabled:!ring-moregray disabled:!bg-appgray disabled:!text-moregray  ${classInput}`} {...props} />
            }

        </div>
    )
}

function InputPassword({ classInput, label, type, ...props }) {
    const [showPassword, setShowPassword] = useState(false)
    return <div className={`relative flex gap-1 h-[45px] rounded-full empty-form overflow-hidden ${classInput}`}>
        <input id='password' type={showPassword === true ? "text" : "password"} className='!bg-transparent w-full border-0 text-xl !px-4 !pt-[11px] !pb-[10px] font-medium !tracking-[0.6px] !outline-none focus:ring-0 focus:border-0' {...props} />
        <button type='button' className=' mr-6 bg-transparent outline-none' onClick={() => setShowPassword(!showPassword)}>
            {
                !showPassword ?
                    <svg
                        width="19" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 8.25C9.90326 8.25 9.33097 8.48705 8.90901 8.90901C8.48705 9.33097 8.25 9.90326 8.25 10.5C8.25 11.0967 8.48705 11.669 8.90901 12.091C9.33097 12.5129 9.90326 12.75 10.5 12.75C11.0967 12.75 11.669 12.5129 12.091 12.091C12.5129 11.669 12.75 11.0967 12.75 10.5C12.75 9.90326 12.5129 9.33097 12.091 8.90901C11.669 8.48705 11.0967 8.25 10.5 8.25Z" fill="#231F20" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 4C7.882 4 5.528 5.051 3.832 6.353C2.982 7.005 2.285 7.729 1.796 8.433C1.316 9.125 1 9.851 1 10.5C1 11.149 1.317 11.875 1.796 12.566C2.286 13.271 2.982 13.995 3.832 14.646C5.528 15.95 7.882 17 10.5 17C13.118 17 15.472 15.949 17.168 14.647C18.018 13.995 18.715 13.271 19.203 12.567C19.683 11.875 20 11.149 20 10.5C20 9.851 19.683 9.125 19.203 8.434C18.715 7.729 18.018 7.005 17.168 6.354C15.472 5.05 13.118 4 10.5 4ZM6.75 10.5C6.75 9.50544 7.14509 8.55161 7.84835 7.84835C8.55161 7.14509 9.50544 6.75 10.5 6.75C11.4946 6.75 12.4484 7.14509 13.1517 7.84835C13.8549 8.55161 14.25 9.50544 14.25 10.5C14.25 11.4946 13.8549 12.4484 13.1517 13.1517C12.4484 13.8549 11.4946 14.25 10.5 14.25C9.50544 14.25 8.55161 13.8549 7.84835 13.1517C7.14509 12.4484 6.75 11.4946 6.75 10.5Z" fill="#231F20" />
                        <path d="M2 2L19 20" stroke="#231F20" strokeWidth="3" strokeLinecap="round" />
                    </svg>
                    : <svg
                        width="18" height="20" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 0C6.882 0 4.528 1.051 2.832 2.353C1.982 3.005 1.285 3.729 0.796 4.433C0.316 5.125 0 5.851 0 6.5C0 7.149 0.317 7.875 0.796 8.566C1.286 9.271 1.982 9.995 2.832 10.646C4.528 11.95 6.882 13 9.5 13C12.118 13 14.472 11.949 16.168 10.647C17.018 9.995 17.715 9.271 18.203 8.567C18.683 7.875 19 7.149 19 6.5C19 5.851 18.683 5.125 18.203 4.434C17.715 3.729 17.018 3.005 16.168 2.354C14.472 1.05 12.118 0 9.5 0ZM5.75 6.5C5.75 5.50544 6.14509 4.55161 6.84835 3.84835C7.55161 3.14509 8.50544 2.75 9.5 2.75C10.4946 2.75 11.4484 3.14509 12.1517 3.84835C12.8549 4.55161 13.25 5.50544 13.25 6.5C13.25 7.49456 12.8549 8.44839 12.1517 9.15165C11.4484 9.85491 10.4946 10.25 9.5 10.25C8.50544 10.25 7.55161 9.85491 6.84835 9.15165C6.14509 8.44839 5.75 7.49456 5.75 6.5Z" fill="#231F20" />
                    </svg>
            }
        </button>
    </div>
}