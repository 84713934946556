import { Outlet, useParams } from "react-router-dom";
import SideBar from "components/SideBar";
import Header from "components/Header";
import { useQuery } from "@tanstack/react-query";
import { getEventDetailService } from "services/event";
import { useContext, useEffect } from "react";
import useEventStore from "store/event";
import { shallow } from "zustand/shallow";
import ModalContext from "context/ModalContext";

function EventDetailLayout() {
    const { setModal } = useContext(ModalContext);
    const params = useParams();
    const { updateEventDetail } = useEventStore((state) => ({ updateEventDetail: state.updateEventDetail }), shallow)

    const eventDetailQuery = useQuery({
        queryFn: () => getEventDetailService(params.event_id),
        queryKey: ['event-detail', params.event_id],
        select: (data) => {
            return data.data
        }
    })

    const handleCloseModal = () => {
        setModal(undefined);
    }

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                handleCloseModal();
            }
        });

        if (eventDetailQuery.data !== undefined && eventDetailQuery.data?.data !== undefined) {
            updateEventDetail(eventDetailQuery.data?.data)
        }

        return () => {
            window.removeEventListener('keydown', handleCloseModal);
        }
    }, [eventDetailQuery, updateEventDetail])

    return (
        <>
            <div className="flex w-full min-h-screen min-w-[1440px]">
                <SideBar />
                <main className="w-full pl-72">
                    <Header
                        className="px-5 py-5"
                        eventName={eventDetailQuery.data?.data.name}
                    />
                    <Outlet />
                </main>
            </div>
        </>
    );
}

export default EventDetailLayout;