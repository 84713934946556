function Xicon({ color, width, height }) {
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.42822 2.42773L11.571 11.5705" stroke={color} strokeWidth="3" strokeLinecap="round" />
                <path d="M11.571 2.42773L2.42827 11.5705" stroke={color} strokeWidth="3" strokeLinecap="round" />
            </svg>
        </>
    );
}

export default Xicon;