import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ButtonPill from 'components/atoms/ButtonPill'
import InputWithLable from 'components/atoms/InputWithLabel'
import SelectInput from 'components/atoms/SelectInputLabel'
import { handleError } from 'helpers/HandleRequestApi'
import { useEffect, useMemo, useState } from 'react'
import { getBankListService, updateBankService } from 'services/settings'
import { useAuthStore } from 'store/auth'
import useEventStore from 'store/event'
import Swal from 'sweetalert2'
import { checkPermission } from 'utils/checkPermission'
import { shallow } from 'zustand/shallow'
import Card from './Card'


export default function BankAccount({ event_id }) {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const { eventDetail } = useEventStore((state) => ({ eventDetail: state.eventDetail }), shallow)
    const queryClient = useQueryClient()

    const [formValue, setFormValue] = useState({
        bankName: '',
        accountHolder: '',
        bankNumber: ''
    })

    const bankQuery = useQuery({
        queryFn: getBankListService,
        queryKey: ['list-bank'],
        select: (data) => {
            return data.data
        }
    })

    const Listbank = useMemo(() => {
        return bankQuery.data?.data.map((item) => ({ ...item, value: item.id, label: item.name })) ?? []
    }, [bankQuery.data])

    useEffect(() => {
        if (eventDetail.id !== '' && Listbank.length) {
            setFormValue({
                bankName: Listbank.find((bank) => bank.name === eventDetail.bank_name)?.id ?? '',
                accountHolder: eventDetail.bank_owner_name,
                bankNumber: eventDetail.bank_number
            })
        }
    }, [eventDetail, Listbank])

    const handleSubmit = (e) => {
        e.preventDefault()
        updateMutation.mutate()
    }


    const updateMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData()
            formData.append('event_id', event_id)
            formData.append('bank_name_id', formValue.bankName)
            formData.append('bank_number', formValue.bankNumber)
            formData.append('bank_owner_name', formValue.accountHolder)

            const response = await updateBankService(formData)
            return response.data
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(['event-detail', event_id])
            Swal.fire({
                icon: 'success',
                text: 'Update bank success',
                timer: 3000
            })
        },
        onError: (err) => {
            handleError(err.response)
        }
    })
    return (
        <Card >
            <form className=' flex gap-4 flex-col' onSubmit={handleSubmit}>
                {/* <Select
                    className=' !outline-none'
                    styles={{

                    }}
                    classNames={{
                        container: (state) => '!outline-none foucs:!border-0 focus:!ring-0',
                        control: (state) => '!outline-none !rounded-full !h-[45px] foucs:!border-0 focus:!ring-0',
                        valueContainer: (state) => '!px-5 !outline-none fouces:border-0',
                        input: (state) => '!outline-none'
                    }}
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={Listbank}
                /> */}

                <SelectInput
                    required
                    label="Bank Name"
                    options={Listbank}
                    value={formValue.bankName}
                    onChange={(e) => setFormValue(p => ({ ...p, bankName: e.target.value }))}
                    classInput={` !text-base !font-medium !tracking-[0.48px] !p-0 !px-5 valid:!empty-form ${formValue.bankName === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                />
                <InputWithLable
                    required
                    label="Account Holder"
                    value={formValue.accountHolder}
                    onChange={(e) => setFormValue(p => ({ ...p, accountHolder: e.target.value }))}
                    classInput={` !text-base !font-medium !tracking-[0.48px] !pt-[13px] !pb-[13px] !px-5 valid:!empty-form ${formValue.accountHolder === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                />

                <InputWithLable
                    label="Bank Number"
                    type="text"
                    required
                    value={formValue.bankNumber}
                    onChange={(e) => setFormValue(p => ({ ...p, bankNumber: e.target.value.replace(/[^\d]/g, '') }))}
                    classInput={` !text-base !font-medium !tracking-[0.48px] !pt-[13px] !pb-[13px] !px-5 valid:!empty-form ${formValue.bankNumber === '' ? 'valid:!empty-form' : 'valid:valid-form'}`}
                />
                {
                    profile?.permissions !== undefined ?
                        <>
                            {
                                checkPermission(profile?.permissions, 'edit bank event') &&
                                <div className=' flex justify-center mt-10 mb-3'>
                                    <ButtonPill label="Update Bank" className=" w-[196px]" />
                                </div>
                            }
                        </>
                        :
                        <></>

                }
            </form>
        </Card>
    )
}
