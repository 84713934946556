import {
    ADD_EVENT,
    EDIT_EVENT,
    GET_EVENT,
    GET_EVENT_HISTORY,
    DELETE_EVENT,
    DUPLICATE_EVENT,
    RESET_EVENT,
    STERIL_EVENT,
} from '../types/EventTypes';

export default (state, { type, payload }) => {
    switch (type) {
        case STERIL_EVENT:
            return {
                ...state,
            };
        case GET_EVENT:
            return {
                ...state,
                loading: false,
                events: [...state.events, ...payload],
            };
        case GET_EVENT_HISTORY:
            return {
                ...state,
                loading: false,
                historyEvents: [...state.historyEvents, ...payload],
            };
        case ADD_EVENT:
            return {
                ...state,
                events: [payload.data, ...state.events],
            };
        case EDIT_EVENT:
            return {
                ...state,
                events: state.events.map(event => {
                    if (event.id === payload.data.id) {
                        return payload.data;
                    }

                    return event;
                }),
            };
        case DELETE_EVENT:
            return {
                ...state,
                events: state.events.filter(event => event.id !== payload),
            };
        case RESET_EVENT:
            return {
                ...state,
                events: [payload.data, ...state.events],
            };
        case DUPLICATE_EVENT:
            return {
                ...state,
                events: [payload.data, ...state.events],
            };
    }
};
