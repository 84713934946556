import React from 'react'
import { MONTHS } from 'helpers/Month';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatValueMoney } from 'utils/normalize';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


export const getMinChart = (data) => {
    var minValue = Math.min(...data);
    var min = Math.pow(10, Math.floor(Math.log10(minValue)) - 1);

    return minValue - min;
}

export const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Chart'
        },
    },
    interaction: {
        intersect: false,
    },
    scales: {
        x: {
            grid: {
                borderDash: [8, 4],
                color: "#348632"
            }
        },
        y: {
            display: true,
            min: 0,
            border: {
                dash: [10, 10],
            },
            grid: {
                offset: true,
            },
            ticks: {
                callback: function (value, index, ticks) {
                    return formatValueMoney(value)
                }
            }
        }
    }
};




export default function Expanses({ data }) {
    const labels = data.map((item) => {
        const dateLabel = new Date(item.purchase_month)
        return MONTHS[dateLabel.getMonth()] + ' ' + dateLabel.getFullYear();
    });

    const dataLine = {
        labels,
        datasets: [
            {
                cubicInterpolationMode: 'monotone',
                fill: "start",
                label: ' Expenses',
                data: data.map((item) => parseInt(item.expense)),
                borderColor: "#C72828",
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const chartArea = context.chart.chartArea;
                    const gradient = ctx.createLinearGradient(0, chartArea?.top ?? 0, 0, chartArea?.bottom ?? 200);
                    gradient.addColorStop(0, "rgba(199, 40, 40, 0.70)");
                    gradient.addColorStop(1, "rgba(199, 40, 40, 0.00)");
                    return gradient;
                },
            },
        ],
    };

    return (
        <Line options={options} data={dataLine} width={'100%'} />
    )
}
