
import Header from 'components/Header'
import SettingItem from 'components/atoms/SettingItem'
import PrivacyPolicy from 'components/organism/event/settings/PrivacyPolicy'
import TermAndConditions from 'components/organism/event/settings/TermAndConditions'
import React, { useEffect, useState } from 'react'
import { useAuthStore } from 'store/auth'
import { checkPermission } from 'utils/checkPermission'
import { shallow } from 'zustand/shallow'

export default function Settings() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const [menuActive, setMenuActive] = useState(0)
    const ListMenu = [
        { label: 'Terms & Conditions', index: 0 },
        { label: 'Privacy Policy', index: 1 },
    ]

    const content = [
        <TermAndConditions type={ListMenu[0].label} key={0} />,
        <PrivacyPolicy type={ListMenu[1].label} key={1} />,
        <p>Permission Denied</p>
    ]

    useEffect(() => {
        if (!checkPermission(profile?.permissions, 'edit global privacy policy') &&
            !checkPermission(profile?.permissions, 'edit global tnc')) {
            setMenuActive(2)
        }
    }, [])
    return (
        <>
            <Header className="py-5 px-14" showGoback={true} />
            <div className=" px-[56px] mt-10 flex gap-5">
                {
                    menuActive === 2 ?
                        <p>Permission Denied</p>
                        : <>
                            <div className=" flex min-w-[230px] flex-col gap-[10px]">
                                {
                                    ListMenu.map((item) => {
                                        return <SettingItem label={item.label} isActive={item.index === menuActive} key={item} onClick={() => setMenuActive(item.index)} />
                                    })
                                }
                            </div>
                            <div className=" grow" >
                                {content[menuActive]}
                            </div>
                        </>
                }

            </div>
        </>
    )
}
