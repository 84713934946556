import React, { useReducer, useState } from 'react';
import NewMemberContext from './NewMemberContext';
import MemberReducer from 'reducer/MemberReducer';
import {
    GET_MEMBERS,
    ADD_MEMBER,
    EDIT_MEMBER,
    DELETE_MEMBER,
    ACTIVATE_MEMBER,
    GET_MEMBER_BY_EVENT,
    ASSIGN_MEMBER_TO_EVENT,
    UNASSIGN_MEMBER_FROM_EVENT,
    GET_MEMBER_WITHOUT_PAGING,
} from 'types/MemberTypes';
import API from 'utils/api';
import { handleError } from 'helpers/HandleRequestApi';

const MemberState = ({ children }) => {
    const initialState = {
        members: [],
        currentPage: 1,
        totalPages: 1,
        membersEvent: [],
        loading: true,
    };

    const [memberId, setMemberId] = useState(null);
    const [nonMember, setNonMember] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [type, setType] = useState('admin');
    const [fullname, setFullname] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [state, dispatch] = useReducer(MemberReducer, initialState);

    const getMembers = async (page = 1) => {
        return API.get('/member?page=' + page)
            .then(ress => {
                dispatch({ type: GET_MEMBERS, payload: ress.data });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const getMemberWithoutPage = async id => {
        return API.get(`/list-member-event/${id}`)
            .then(ress => {
                dispatch({
                    type: GET_MEMBER_WITHOUT_PAGING,
                    payload: ress.data.data,
                });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const getMemberByEvent = async (id, page = 1, email = '') => {
        return API.get(`/member-event/${id}?page=${page}&email=${email}`)
            .then(ress => {
                dispatch({
                    type: GET_MEMBER_BY_EVENT,
                    payload: ress.data,
                });

                return ress;
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const createMember = async (data, url) => {
        return API.post(url, data)
            .then(ress => {
                dispatch({ type: ADD_MEMBER, payload: ress.data });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
                return 'Not OK';
            });
    };

    const cleanUpMemberForm = () => {
        setEmail(null);
        setMemberId(null);
        setFullname(null);
        setType('admin');
        setPhone(null);
        setPassword('');
        setConfirmPassword('');
    };

    const editMember = async (data, url) => {
        return API.post(url, data)
            .then(ress => {
                dispatch({ type: EDIT_MEMBER, payload: ress.data });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
                return 'Not OK';
            });
    };

    const deleteMember = async () => {
        return API.delete(`member/delete/${memberId}`)
            .then(ress => {
                dispatch({ type: DELETE_MEMBER, payload: memberId });
                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err;
            });
    };

    const activatedMember = async (url, data) => {
        return API.post(url, data)
            .then(ress => {
                dispatch({ type: ACTIVATE_MEMBER, payload: ress.data });
                return ress;
            })
            .catch(err => {
                handleError(err.response);
                return err;
            });
    };

    const assignMember = async data => {
        return API.post(`member-invite`, data)
            .then(ress => {
                dispatch({ type: ASSIGN_MEMBER_TO_EVENT, payload: ress.data });
                return 'OK';
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const getNonMemberEvent = async id => {
        return API.get(`member-list/${id}`)
            .then(ress => {
                setNonMember(ress.data.data);
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const unassignMember = async data => {
        return API.post(`member-kick`, data)
            .then(ress => {
                dispatch({
                    type: UNASSIGN_MEMBER_FROM_EVENT,
                    payload: data.get('user_id'),
                });
            })
            .catch(err => {
                handleError(err.response);
            });
    };

    const { members, currentPage, totalPages, membersEvent, loading } = state;

    return (
        <NewMemberContext.Provider
            value={{
                currentPage,
                totalPages,
                type,
                membersEvent,
                memberId,
                selectedMember,
                fullname,
                email,
                phone,
                password,
                confirmPassword,
                members,
                loading,
                showPassword,
                showConfirmPassword,
                nonMember,
                getNonMemberEvent,
                setType,
                setMemberId,
                setSelectedMember,
                setFullname,
                setEmail,
                setPhone,
                getMembers,
                setPassword,
                setConfirmPassword,
                createMember,
                cleanUpMemberForm,
                setShowPassword,
                setShowConfirmPassword,
                editMember,
                deleteMember,
                activatedMember,
                getMemberByEvent,
                assignMember,
                unassignMember,
                getMemberWithoutPage,
            }}
        >
            {children}
        </NewMemberContext.Provider>
    );
};

export default MemberState;
