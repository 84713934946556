function Notif() {
    return (
        <>
            <svg width="20" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_101_22)">
                    <path d="M255.871 4.52271e-06C226.416 4.52271e-06 202.538 23.878 202.538 53.333C202.588 55.892 202.824 58.445 203.242 60.97C204.011 66.255 208.569 70.156 213.909 70.101C214.927 70.1 215.939 69.956 216.917 69.674C242.405 62.133 269.531 62.133 295.018 69.674C298.049 70.424 301.255 69.842 303.829 68.074C306.458 66.276 308.214 63.46 308.672 60.309C309.055 57.995 309.269 55.657 309.312 53.312C309.3 23.857 285.412 -0.0119955 255.957 4.52271e-06C255.928 4.52271e-06 255.9 4.52271e-06 255.871 4.52271e-06Z" fill="#F57C00" />
                    <path d="M319.871 426.667H191.871C185.98 426.667 181.204 431.443 181.204 437.334C181.204 478.571 214.633 512.001 255.871 512.001C297.109 512.001 330.538 478.572 330.538 437.334C330.538 431.442 325.763 426.667 319.871 426.667Z" fill="#FFA000" />
                    <path d="M454.719 388.459C451.348 381.014 447.103 372.694 442.41 363.52C413.543 314.622 397.106 259.392 394.538 202.667C394.538 118.123 349.738 56.086 280.447 44.63C240.013 38.131 198.798 50.146 168.191 77.355C134.31 106.931 115.513 150.166 116.991 195.115C116.108 254.541 99.693 312.701 69.375 363.819C64.788 372.864 60.607 381.099 57.279 388.438C47.665 409.641 57.059 434.624 78.263 444.239C83.793 446.747 89.799 448.03 95.871 448.001H416.212C439.493 448.071 458.423 429.254 458.492 405.973C458.51 399.934 457.23 393.962 454.739 388.46L454.719 388.459Z" fill="#FFC107" />
                    <path d="M113.642 405.333C107.751 405.333 102.975 400.557 102.976 394.666C102.976 393.007 103.363 391.371 104.106 389.888L107.541 383.147C139.403 328.138 157.366 266.188 159.872 202.667C159.872 148.544 185.024 85.334 255.872 85.334C261.763 85.334 266.539 90.11 266.539 96.001C266.539 101.892 261.763 106.668 255.872 106.668C186.581 106.668 181.205 180.14 181.205 202.668C178.824 269.584 160.049 334.891 126.528 392.855L123.2 399.447C121.388 403.061 117.687 405.341 113.642 405.333Z" fill="#FFD54F" />
                </g>
                <defs>
                    <clipPath id="clip0_101_22">
                        <rect width="512" height="512" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    );
}

export default Notif;