import {
    GET_INVENTORY,
    ADD_INVENTORY,
    EDIT_INVENTORY,
    DELETE_INVENTORY,
} from 'types/InventoryTypes';

export default (state, { type, payload }) => {
    switch (type) {
        case GET_INVENTORY:
            return {
                ...state,
                inventory: payload.data.items,
                totalPage: payload.meta.total_page,
                currentPage: payload.meta.current_page.slice(
                    payload.meta.current_page.indexOf('page=') + 5
                ),
            };
        case ADD_INVENTORY:
            return {
                ...state,
                inventory: [payload, ...state.inventory],
            };
        case EDIT_INVENTORY:
            return {
                ...state,
                inventory: state.inventory.map(item => {
                    if (item.id === payload.id) {
                        return payload;
                    }

                    return item;
                }),
            };
        case DELETE_INVENTORY:
            return {
                ...state,
                inventory: state.inventory.filter(item => item.id !== payload),
            };
    }
};
