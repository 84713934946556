import { useMutation, useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Popup from "components/Modal/Popup";
import TableComponent from "components/Table";
import Checklist from "components/icons/Checklist";
import ThreeDots from "components/icons/ThreeDots";
import ModalContext from "context/ModalContext";
import { Badge, Dropdown } from "flowbite-react";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import { handleError } from "helpers/HandleRequestApi";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MainsStack } from "routes";
import { getSubmittedFormService } from "services/form";
import { activateTenantService, downloadFormTenantService, togleFormService } from "services/tenant";

function FormSubmitted() {
    const [tenant, setTenant] = useState({})
    const [message, setMessage] = useState('approve')
    const [bookingBoothId, setBokingBoothId] = useState('')
    const { event_id } = useParams();
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [submittedFormData, setSubmittedFormData] = useState([])
    const { setModal, setPopup, modal } = useContext(ModalContext);
    const getSubmittedFormQuery = useQuery({
        queryKey: ['submitted-form', page],
        queryFn: () => {
            return getSubmittedFormService(event_id, page);
        }
    })

    const handleLoadPage = page => {
        setPage(page)
    }

    const handleApproveTenant = useMutation({
        mutationFn: () => {
            const FD = new FormData();
            FD.append('booking_booth_id', bookingBoothId)

            return togleFormService(FD)
        },
        onError: (error, variables, context) => {
            handleError(error)
        },
        onSuccess: (data, variables, context) => {
            getSubmittedFormQuery.refetch()
            toast.success(`Successfully ${message} tenant ${tenant.fullname}`)
        },
    })

    const columnHelper = createColumnHelper();

    const submittedFormColumn = useMemo(
        () => [
            columnHelper.accessor('user', {
                header: 'Name',
                cell: info => {
                    return info.row.original.user.fullname
                }
            }),
            columnHelper.accessor('user', {
                header: 'Email',
                cell: info => {
                    return info.row.original.user.email
                }
            }),
            columnHelper.accessor('brand_name_form', {
                header: 'Brand Name',
                cell: info => {
                    return (
                        <p className="capitalize">{info.renderValue()}</p>
                    )
                }
            }),
            columnHelper.accessor('is_approve', {
                header: 'Status',
                cell: (info) => {
                    const view = info.renderValue() === 1 ?
                        <Badge
                            className="rounded-full px-2 w-fit text-[#10B986] bg-badge-success"
                        >
                            Approved
                        </Badge>
                        :
                        <Badge
                            className="rounded-full px-2 w-fit text-[#B91010] bg-badge-error"
                        >
                            Not Approved
                        </Badge>
                        ;

                    return view;
                }
            }),
            columnHelper.accessor('action', {
                header: 'Action',
                cell: (info) => {
                    return (
                        <Dropdown
                            renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                        >
                            <Dropdown.Item
                                onClick={() => {
                                    setTenant(info.row.original.user)
                                    setMessage(info.row.original.is_approve ? 'reset' : 'approve')
                                    setBokingBoothId(info.row.original.booking_booth_id)
                                    setPopup('approve_tenant')
                                }}
                            >
                                {info.row.original.is_approve ? 'Reset' : 'Approve'} Tenant
                            </Dropdown.Item>
                            <Dropdown.Item onClick={async () => {
                                const fileName = `${info.row.original.user.brand_name}_${info.row.original.brand_name_form}.xlsx`
                                const response = await downloadFormTenantService(info.row.original.booking_booth_id)
                                var downloadLink = document.createElement('a');
                                downloadLink.target = '_blank';
                                downloadLink.download = fileName.replace(/ /g, "_")

                                // convert downloaded data to a Blob
                                var blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                                // create an object URL from the Blob
                                var URL = window.URL || window.webkitURL;
                                var downloadUrl = URL.createObjectURL(blob);

                                // set object URL as the anchor's href
                                downloadLink.href = downloadUrl;

                                // window.open(downloadLink)

                                // return

                                // append the anchor to document body
                                document.body.append(downloadLink);

                                // fire a click event on the anchor
                                downloadLink.click();

                                // cleanup: remove element and revoke object URL
                                document.body.removeChild(downloadLink);
                                URL.revokeObjectURL(downloadUrl);
                            }
                            }>
                                Download Form Answer
                            </Dropdown.Item>
                        </Dropdown>
                    )
                }
            }),
        ],
        []);

    useEffect(() => {
        if (getSubmittedFormQuery.data !== undefined) {
            setSubmittedFormData(getSubmittedFormQuery.data.data.items)
            setTotalPage(getSubmittedFormQuery.data.meta.total_page)
        }
    }, [getSubmittedFormQuery])

    return (
        <>
            <main className="px-5 mt-5">
                <div className="app-shadow py-5 px-5">
                    <TableComponent
                        data={submittedFormData}
                        columns={submittedFormColumn}
                        // enableClickRow={true}
                        // actionClickRow={(data) => { handleDetailVendor(data) }}
                        totalPage={totalPage}
                        currentPage={page}
                        showPagination={true}
                        actionLoadPage={handleLoadPage}
                    />
                </div>
            </main>
            <Popup
                action={() => handleApproveTenant.mutate()}
                icon={null}
                type="approve_tenant"
                textBtn="Proceed"
            >
                Are you sure want to {message} tenant <strong>#{tenant.fullname}</strong> ?
            </Popup>
        </>
    );
}

export default FormSubmitted;