import { useMutation } from '@tanstack/react-query'
import ButtonPill from 'components/atoms/ButtonPill'
import InputWithLable from 'components/atoms/InputWithLabel'
import { handleError } from 'helpers/HandleRequestApi'
import React, { useState } from 'react'
import { updatePasswordService } from 'services/user'
import Swal from 'sweetalert2'
import { regexPassword } from 'utils/regex'

const initialPassword = {
    old_password: '',
    new_password: '',
    confirm_password: ''
}
export default function ChangePassword() {
    const [formPassword, setFormPassword] = useState(initialPassword)

    const validatePassword = (password) => {
        return regexPassword.test(password)
    };

    const updateMutation = useMutation({
        mutationFn: async () => {
            const formdata = new FormData()
            formdata.append('old_password', formPassword.old_password)
            formdata.append('new_password', formPassword.new_password)
            formdata.append('confirm_password', formPassword.confirm_password)
            const response = await updatePasswordService(formdata)
            return response.data
        },
        onSuccess(data) {
            Swal.fire({
                icon: 'success',
                text: 'Successfully update password',
                timer: 3000
            }).then((ress) => {
                if (ress.isDismissed || ress.isConfirmed) {
                    setFormPassword(initialPassword)
                }
            })
        },
        onError(error) {
            handleError(error.response);
            setFormPassword(initialPassword)
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        updateMutation.mutate()
    }

    return (
        <form className=' flex flex-col gap-[15px]' onSubmit={handleSubmit}>
            <InputWithLable
                onChange={(e) => setFormPassword(p => ({ ...p, old_password: e.target.value }))}
                value={formPassword.old_password}
                type="password"
                label="Old Password"
                classInput={`${formPassword.old_password !== '' ?
                    validatePassword(formPassword.old_password) ? 'success-form' : 'error-form'
                    : ''}`}
            />
            <InputWithLable
                onChange={(e) => setFormPassword(p => ({ ...p, new_password: e.target.value }))}
                value={formPassword.new_password}
                type="password"
                classInput={`${formPassword.new_password !== '' ?
                    validatePassword(formPassword.new_password) ? 'success-form' : 'error-form'
                    : ''}`}
                label="New Password"
            />
            <InputWithLable
                onChange={(e) => setFormPassword(p => ({ ...p, confirm_password: e.target.value }))}
                value={formPassword.confirm_password}
                classInput={`${formPassword.confirm_password !== '' ?
                    formPassword.new_password === formPassword.confirm_password ? 'success-form' : 'error-form'
                    : ''}`}
                type="password"
                label="Confirm New Password"
            />
            <div className=' flex justify-end mt-4'>
                <ButtonPill type="submit" label="Change Password" />
            </div>
        </form>
    )
}
