import {
    GET_COMPLAIN,
    GET_DETAIL_COMPLAIN,
    ASSIGN_MEMBER_TO_COMPLAIN,
    FINISH_COMPLAIN,
} from 'types/Complaintype';

export default (state, { type, payload }) => {
    switch (type) {
        case GET_COMPLAIN:
            return {
                ...state,
                complain: payload.data.items,
                totalPages: payload.meta.total_page,
                currentPage: payload.meta.current_page.slice(
                    payload.meta.current_page.indexOf('page=') + 5
                ),
            };
        case GET_DETAIL_COMPLAIN:
            return {};
        case ASSIGN_MEMBER_TO_COMPLAIN:
            return {
                ...state,
                complain: state.complain.map(complain => {
                    if (complain.id === payload.id) {
                        return payload;
                    }

                    return complain;
                }),
            };
        case FINISH_COMPLAIN:
            return {
                ...state,
                complain: state.complain.map(complain => {
                    if (complain.id === payload.id) {
                        return payload;
                    }

                    return complain;
                }),
            };
    }
};
