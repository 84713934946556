import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuthStore } from 'store/auth';
import { shallow } from 'zustand/shallow';
import image_placeholder from '../assets/images/image_placeholder.png'

export default function useGetSourceWithCredential({ path, token_param, type }) {
    const { token } = useAuthStore((state) => ({ token: state.auth.access_token }), shallow);
    const [src, setSrc] = useState(type === undefined ? image_placeholder : '')
    const fileQuery = useQuery({
        queryKey: [path],
        enabled: path !== '' && path !== undefined && (token !== '' || token_param !== undefined),
        queryFn: async () => {
            return axios({
                url: `${path}`,
                responseType: 'blob',
                method: "GET",
                headers: {
                    Authorization: 'Bearer ' + (token_param !== undefined ? token_param : token),
                }
            })

        },
    })

    useEffect(() => {
        if (fileQuery.data !== undefined) {
            let blob = new Blob(
                [fileQuery.data.data],
                { type: fileQuery.data.headers['content-type'] }
            )
            let image = URL.createObjectURL(blob)
            setSrc(image)
        }
    }, [fileQuery.data])

    let isLoading = fileQuery.isLoading;

    return {
        src,
        setSrc,
        isLoading,
    }
}
