import { useContext, useEffect, useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Badge, Dropdown } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import InventoryContext from "context/InventoryContext";
import TableComponent from "components/Table";
import ModalContext from "context/ModalContext";
import ModalBanner from "components/Modal/ModalBanner";
import InputWithLabel from "components/atoms/InputWithLabel";
import Popup from "components/Modal/Popup";
import { MONTHS } from "helpers/Month";
import { toast } from "react-toastify";
import Trash from "components/icons/Trash";
import PurchaseContext from "context/PurchaseContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { MainsStack } from "routes";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function EventEquipment() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const columnHelper = createColumnHelper();
    const { purchase, totalPage, currentPage, getPurchaseData, deletePurchase } = useContext(PurchaseContext);
    const { setModal, modal, setPopup } = useContext(ModalContext);
    const [products, setProducts] = useState([]);
    const [purchaseId, setPurchaseId] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const params = useParams();

    const purchaseColumns = useMemo(
        () => [
            columnHelper.accessor('invoice_number', {
                header: 'Invoice Number'
            }),
            columnHelper.accessor('invoice_date', {
                header: 'Invoice Date',
            }),
            columnHelper.accessor('supplier_name', {
                header: 'Supplier Name',
            }),
            columnHelper.accessor('supplier_phone', {
                header: 'Supplier Phone',
            }),
            columnHelper.accessor('created_at', {
                header: 'Added at',
                cell: info => {
                    if (info.row.original.created_at !== null) {
                        const createdAt = new Date(info.row.original.created_at);
                        return (
                            <div>{createdAt.getDate()} {MONTHS[createdAt.getMonth()]} {createdAt.getFullYear()}</div>
                        )
                    } else {
                        return (
                            <div>-</div>
                        )
                    }
                }
            }),
            columnHelper.accessor('canceled_at', {
                header: 'Status',
                cell: info => {
                    if (info.row.original.canceled_at !== null) {
                        return (
                            <Badge
                                className="rounded-full px-2 w-fit text-[#B91010] bg-badge-error"
                            >
                                Cancelled
                            </Badge>
                        )
                    } else {
                        return (
                            <Badge
                                className="rounded-full px-2 w-fit text-[#10B986] bg-badge-success"
                            >
                                Success
                            </Badge>
                        )
                    }
                }
            }),
            columnHelper.accessor('canceled_by', {
                header: 'Cancelled By',
                cell: info => {
                    return (
                        <>
                            {info.row.original.canceled_by !== null ? info.row.original.canceled_by.fullname : <p className="text-moregray">Not Set</p>}
                        </>
                    )
                }
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: info => {
                    return (
                        <>
                            <div>
                                {
                                    <Dropdown
                                        renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                    >
                                        <Dropdown.Item
                                            onClick={() => {
                                                handleSeeProduct(info.row.original);
                                            }}
                                        >
                                            See Product
                                        </Dropdown.Item>
                                        {

                                            info.row.original.canceled_at === null &&
                                            profile?.permissions !== undefined &&
                                            checkPermission(profile?.permissions, 'cancel stock in') &&
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setPurchaseId(info.row.original.id);
                                                    setInvoiceNumber(info.row.original.invoice_number);
                                                    setPopup('cancel_purchase')
                                                }}
                                            >
                                                Cancel Purchase
                                            </Dropdown.Item>
                                        }
                                    </Dropdown>
                                }
                            </div>
                        </>
                    )
                }
            })
        ], []
    )

    const handleSeeProduct = data => {
        setProducts(data.inventories);
        setModal('list_product')
    }

    useEffect(() => {
        getPurchaseData(params.event_id);
    }, [])

    const handleLoadPage = async page => {
        getPurchaseData(params.event_id, page);
    }

    return (
        <>
            <main className="py-5 px-5">
                {
                    profile?.permissions !== undefined &&
                    checkPermission(profile?.permissions, 'add stock in') &&
                    <div className="flex justify-end">
                        <Link className="btn btn-default" to={MainsStack.createPurchase.replace(':event_id', params.event_id)}>Create Stock In</Link>
                    </div>
                }
                <div className="app-shadow mt-5 p-5">
                    <TableComponent
                        data={purchase}
                        columns={purchaseColumns}
                        totalPage={totalPage}
                        actionLoadPage={handleLoadPage}
                        currentPage={currentPage}
                        showPagination={true}
                    />
                </div>
            </main>
            <ModalBanner
                choosedModal={'list_product'}
                title={`List Product`}
                textButton={null}
                actionClearForm={() => { }}
                useModalFooter={false}
            >
                <ul className="space-y-3">
                    {
                        products.map(product => {
                            return (
                                <li className="">
                                    <div className="card app-shadow flex justify-between px-5 items-center">
                                        <div>
                                            <h1 className="font-medium">{product.name}</h1>
                                            <p>Price : IDR. {product.pivot.price}</p>
                                            <p>Total Items : {product.pivot.qty > 1 ? product.pivot.qty + ' Pcs' : product.pivot.qty + ' Pc'}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </ModalBanner>
            <Popup
                action={async () => {
                    const reqDelete = await deletePurchase(purchaseId)
                    if (reqDelete.status === 200) {
                        toast.success('Success cancelled purchase.')
                    }
                }}
                type="cancel_purchase"
                textBtn="Proceed"
                icon={<Trash width={35} height={35} />}
            >
                Are you sure you want to cancel purchase <strong>#{invoiceNumber}</strong>?
            </Popup>
        </>
    );
}

export default EventEquipment;