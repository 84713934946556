import { useContext, useEffect, useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Dropdown } from "flowbite-react";
import ThreeDots from "components/icons/ThreeDots";
import InventoryContext from "context/InventoryContext";
import TableComponent from "components/Table";
import ModalContext from "context/ModalContext";
import ModalBanner from "components/Modal/ModalBanner";
import InputWithLabel from "components/atoms/InputWithLabel";
import Popup from "components/Modal/Popup";
import { MONTHS } from "helpers/Month";
import { toast } from "react-toastify";
import Trash from "components/icons/Trash";
import { useParams } from "react-router-dom";
import { useAuthStore } from "store/auth";
import { shallow } from "zustand/shallow";
import { checkPermission } from "utils/checkPermission";

function EventEquipment() {
    const { profile } = useAuthStore((state) => ({ profile: state.profile }), shallow)
    const columnHelper = createColumnHelper();
    const { inventory, currentPage, totalPage, getInventoryData, createInventory, inventoryName, inventoryId, setInventoryName, setInventoryId, editInventory, resetFormInventory, deleteInventory } = useContext(InventoryContext);
    const { setModal, setPopup } = useContext(ModalContext);
    const [statusAction, setStatusAction] = useState('add');
    const params = useParams();

    const inventoryColumns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: 'Product Name'
            }),
            columnHelper.accessor('stock', {
                header: 'Stock',
            }),
            columnHelper.accessor('created_by', {
                header: 'Added By',
                cell: info => {
                    return (
                        <div>{info.row.original.created_by.fullname}</div>
                    )
                }
            }),
            columnHelper.accessor('created_at', {
                header: 'Added Date',
                cell: info => {
                    if (info.row.original.created_at !== null) {
                        const createdAt = new Date(info.row.original.created_at);
                        return (
                            <div>{createdAt.getDate()} {MONTHS[createdAt.getMonth()]} {createdAt.getFullYear()}</div>
                        )
                    } else {
                        return (
                            <div>-</div>
                        )
                    }
                }
            }),
            columnHelper.accessor('status', {
                header: 'Action',
                cell: info => {
                    return (
                        <>
                            <div>
                                {
                                    profile?.permissions !== undefined &&
                                        (checkPermission(profile?.permissions, 'delete inventory') ||
                                            checkPermission(profile?.permissions, 'update inventory')) ?
                                        <Dropdown
                                            renderTrigger={() => <button className='flex justify-center'><ThreeDots /></button>}
                                        >
                                            {
                                                checkPermission(profile?.permissions, 'update inventory') &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setInventoryName(info.row.original.name);
                                                        setInventoryId(info.row.original.id);
                                                        setStatusAction('edit');
                                                        setModal('add_inventory');
                                                    }}
                                                >
                                                    Edit
                                                </Dropdown.Item>
                                            }
                                            {
                                                checkPermission(profile?.permissions, 'delete inventory') &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setInventoryName(info.row.original.name);
                                                        setInventoryId(info.row.original.id);
                                                        setPopup('delete_inventory_popup')
                                                    }}
                                                >
                                                    Delete
                                                </Dropdown.Item>
                                            }       
                                        </Dropdown>
                                        :
                                        <p className="text-moregray">No Action</p>
                                }
                            </div>
                        </>
                    )
                }
            })
        ], []
    )

    const handleLoadPage = async page => {
        getInventoryData(params.event_id, page);
    }

    useEffect(() => {
        getInventoryData(params.event_id);
    }, [])

    return (
        <>
            <main className="py-5 px-5">
                <div className="flex justify-end">
                    {
                        profile?.permissions !== undefined &&
                        checkPermission(profile?.permissions, 'create inventory') &&
                        <button className="btn btn-default ml-auto" onClick={() => {
                            setModal('add_inventory')
                            setStatusAction('add')
                        }}>Add Inventory</button>
                    }
                </div>
                <div className="app-shadow mt-5 p-5">
                    <TableComponent
                        data={inventory}
                        columns={inventoryColumns}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        showPagination={true}
                        actionLoadPage={handleLoadPage}
                    />
                </div>
            </main>
            <ModalBanner
                choosedModal={'add_inventory'}
                title={`${statusAction === 'add' ? `Add Inventory` : `Edit Inventory`}`}
                textButton="Submit"
                actionClearForm={() => resetFormInventory()}
                idBtnSubmit="btn_add_inventory"
            >
                <form onSubmit={event => {
                    event.preventDefault();
                    setPopup('add_inventory_popup');
                }}>
                    <InputWithLabel
                        label={'Inventory Name'}
                        onChange={event => setInventoryName(event.target.value)}
                        required={true}
                        value={inventoryName}
                    />
                    <button id="btn_add_inventory" hidden></button>
                </form>
            </ModalBanner>
            <Popup
                action={async () => {
                    const FD = new FormData();
                    FD.append('name', inventoryName);
                    FD.append('event_id', params.event_id);

                    if (statusAction === 'add') {
                        const reqAddInventory = await createInventory(FD);

                        if (reqAddInventory.status === 201) {
                            setModal(undefined);
                            toast.success('Success create new inventory')
                            resetFormInventory()
                        } else {
                            return;
                        }
                    } else {
                        const reqEditInventory = await editInventory(FD, inventoryId);

                        if (reqEditInventory.status === 200) {
                            setModal(undefined);
                            toast.success('Success edit inventory')
                            resetFormInventory()
                        } else {
                            return;
                        }
                    }
                }}
                type="add_inventory_popup"
                textBtn="Proceed"
                icon={null}
            >
                Are you sure you want to {statusAction} inventory?
            </Popup>
            <Popup
                action={async () => {
                    const reqDeleteInventory = await deleteInventory(inventoryId);

                    if (reqDeleteInventory.status === 200) {
                        setPopup(null);
                        toast.success('Success delete inventory');
                    } else {
                        return;
                    }

                }}
                type="delete_inventory_popup"
                textBtn="delete"
                icon={<Trash width={35} height={35} />}
            >
                Are you sure you want to delete inventory <strong>#{inventoryName}</strong>?
            </Popup>
        </>
    );
}

export default EventEquipment;