function Vendor() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                <path d="M8.75 15.75C8.75 15.75 7.5 15.75 7.5 14.5C7.5 13.25 8.75 9.5 13.75 9.5C18.75 9.5 20 13.25 20 14.5C20 15.75 18.75 15.75 18.75 15.75H8.75ZM13.75 8.25C14.7446 8.25 15.6984 7.85491 16.4017 7.15165C17.1049 6.44839 17.5 5.49456 17.5 4.5C17.5 3.50544 17.1049 2.55161 16.4017 1.84835C15.6984 1.14509 14.7446 0.75 13.75 0.75C12.7554 0.75 11.8016 1.14509 11.0983 1.84835C10.3951 2.55161 10 3.50544 10 4.5C10 5.49456 10.3951 6.44839 11.0983 7.15165C11.8016 7.85491 12.7554 8.25 13.75 8.25ZM6.52 15.75C6.33469 15.3598 6.24228 14.9319 6.25 14.5C6.25 12.8062 7.1 11.0625 8.67 9.85C7.88636 9.60854 7.06994 9.49047 6.25 9.5C1.25 9.5 0 13.25 0 14.5C0 15.75 1.25 15.75 1.25 15.75H6.52ZM5.625 8.25C6.4538 8.25 7.24866 7.92076 7.83471 7.33471C8.42076 6.74866 8.75 5.9538 8.75 5.125C8.75 4.2962 8.42076 3.50134 7.83471 2.91529C7.24866 2.32924 6.4538 2 5.625 2C4.7962 2 4.00134 2.32924 3.41529 2.91529C2.82924 3.50134 2.5 4.2962 2.5 5.125C2.5 5.9538 2.82924 6.74866 3.41529 7.33471C4.00134 7.92076 4.7962 8.25 5.625 8.25Z" />
            </svg>
        </>
    );
}

export default Vendor;